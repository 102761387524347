import _ from "lodash"
import "url-search-params-polyfill"
import moment from "moment-timezone"
// import tzMoment from "./tzMoment"

export default function ParamState(propsCb, properties, types) {
    if (propsCb === undefined) return
    var instance = this
    let URLSP = null
    let projectTimezone = null

    reset()

    instance.setProjectTimezone = setProjectTimezone

    function setProjectTimezone(timezone) {
        projectTimezone = timezone
    }


    function reset() {
        const props = propsCb()
        // const url = new URL(`http://test/${props.location.pathname}`)
        let { search, pathname } = props.location
        if (search == '') {
            const pathnameSplit = pathname.split("?")
            let pathSearch = pathnameSplit[1]
            if (pathSearch && pathSearch.length > 0){
                search = `?${pathSearch}`
            }
        }
        URLSP = new URLSearchParams(search)
    }

    function removeAll() {
        reset()
        for (i in properties) {
            URLSP.delete(i)
        }
        updateURL()
    }

    function remove(name) {
        reset()
        URLSP.delete(name)
        updateURL()
    }

    function updateURL() {
        const props = propsCb()
        const search = URLSP.toString()

        props.history.replace({
            search
        })
    }

    for (var i in properties) {
        // eslint-disable-next-line no-loop-func
        (function (i) {
            const setter = function (val) {
                if (val !== undefined && val !== null) {
                    reset()
                    URLSP.set(i, val)
                    updateURL()
                } else {
                    remove(i)
                }
            }

            instance.removeAll = removeAll
            // instance.remove = remove

            instance["get" + _.capitalize(i)] = function () {
                reset()
                let val = URLSP.get(i)
                if (val === null) {
                    val = properties[i]
                    if (val !== null) {
                        setter(val)
                    }
                }
                if (val === null) return null


                //return type conversion
                let valType = typeof properties[i]
                if(types&&types[i]) valType = types[i];
                // eslint-disable-next-line default-case
                switch (valType) {
                    case "number":
                        val = parseInt(val)
                        break
                    case "object":
                        let tz = projectTimezone
                        if (tz === null) {
                            tz = moment.tz.guess()
                        }

                        const date = moment(val)
                        if (!isNaN(date.unix())) {
                            val = date.toDate()
                        }
                        break
                }
                return val
            }

            instance["set" + _.capitalize(i)] = setter
        })(i)
    }
}
import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Loading from '../components/Loading';
import { Container, CardBody, Card, Row, Col, Spinner, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input,InputGroup,Form } from 'reactstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ParamState from "../utils/ParamState";
import { thousandSeperator as ts } from "../utils/Converter";
import _ from "lodash"
import { withRouter } from 'react-router-dom';
import "url-search-params-polyfill"
import overlayFactory from 'react-bootstrap-table2-overlay';
import checkFeatures from '../utils/checkFeatures';
import FeatureCheck from './FeatureCheck';

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

class GenericTable extends Component {

    constructor(props) {
        super(props)

        this.ps = new ParamState(() => this.props, {
            page: 1,
            orderby: null,
            order: null,
            show: 10,
            search:  null,
        }, {
            search:  this.props.searchType ? this.props.searchType : null,

        })

        this.state = {
            tableData: [],

            totalSize: 0,

            loading: false,
        }
        this.lastSearchTimeout = null
    }

    fetchData = async () => {
        this.setState({ loading: true })
        const { data } = this.props
        const order = this.ps.getOrder()
        const orderby = this.ps.getOrderby()
        const show = this.ps.getShow()
        const search = this.ps.getSearch()

        const from = (this.ps.getPage() - 1) * show
        const to = this.ps.getPage() * show

        const tableData = await this.props.dataCb(data, from, to, orderby, order, search)
        this.setState({ tableData, loading: false })
    }

    updateCount = async () => {
        const { data } = this.props
        const totalSize = await this.props.countCb(data, this.ps.getSearch())

        await this.setState({ totalSize })
    }

    componentDidMount = async () => {
        if (this.isRemote) {
            await this.updateCount()
        }
        if (this.isRemote) {
            await this.fetchData()
        }
    }

    componentWillUnmount = async () => {
        //TODO remove table specific url params
        this.ps.removeAll()
    }

    handleTableChange = async (type, { page, sizePerPage, filters, sortField, sortOrder, searchText, cellEdit }) => {
        if (type == "search") {
            this.ps.setSearch(searchText)

            await this.setState({ loading: true })
            if (this.ps.getSearch() !== searchText) return

            this.ps.setPage(1)

            await this.updateCount()

        } else {
            await this.setState({ loading: true })

            this.ps.setPage(page)
            this.ps.setOrder(sortOrder)
            this.ps.setOrderby(sortField)
            this.ps.setShow(sizePerPage)
            // this.ps.setSearch(searchText)


        }
        await this.fetchData()
        this.setState({ loading: false })
    }

    offlinePaginationFactory = () => {
        if (this.props.pagination !== undefined) {
            return paginationFactory({
                sizePerPage: this.props.sizePerPage ? this.props.sizePerPage : 5,
                sizePerPageList: [{
                    text: '5', value: 5
                }, {
                    text: '10', value: 10
                }, {
                    text: '25', value: 25
                },
                {
                    text: '50', value: 50
                }
                ]
            }
            )
        } else {
            return null
        }
    }

    actionFormatter = (cell, row) => {
        const { actions, actionStates } = this.props
        return (
            <UncontrolledDropdown direction="down">
                <DropdownToggle tag="text" style={{ cursor: 'pointer' }}>
                    <i className="mdi mdi-dots-vertical mt-0"></i>
                </DropdownToggle>
                <DropdownMenu>
                    {Object.keys(actions).map((key) => {
                        let disabled = false
                        if (actionStates) {
                            disabled = !actionStates[key](row)
                        }
                        return <DropdownItem disabled={disabled} onClick={async () => await actions[key](row)}>{key}</DropdownItem>
                    })}
                </DropdownMenu>
            </UncontrolledDropdown>

        )
    }

    actionButtonFormatter = (cell, row) => {
        const { actions, actionStates } = this.props
        return (

            // <UncontrolledDropdown direction="down">
            //     <DropdownToggle tag="text" style={{ cursor: 'pointer' }}>
            //         <i className="mdi mdi-dots-vertical mt-0"></i>
            //     </DropdownToggle>
            //     <DropdownMenu>
            <div>
                {Object.keys(actions).map(function (key) {
                    let disabled = false
                    if (actionStates) {
                        disabled = !actionStates[key](row)
                    }
                    let allowClick = true;
                    return <Button style={{ fontSize: 10, marginBottom: 5, whiteSpace: 'nowrap' }} disabled={disabled} onClick={async () => {
                        if (!allowClick) {
                            return false;
                        }
                        allowClick = false;
                        await actions[key](row)
                        setTimeout(function () {
                            allowClick = true;
                        }, 5000)

                    }}>{key}</Button>
                })}
            </div>
            //     </DropdownMenu>
            // </UncontrolledDropdown>

        )
    }

    generateCols = () => {
        const first = this.tableData[0]
        if (!first) return [{
            dataField: "nodata",
            text: "No data",
            sort: false

        }]
        let result = []
        const { columnOptions, rowEvents, actions, actionsStyle } = this.props

        for (const key in first) {

            if (columnOptions) {
                if (columnOptions.hasOwnProperty(key)) {
                    const opt = columnOptions[key]
                    const { hide, formatter, } = opt
                    const sort = opt.sort !== false
                    const text = opt.text ? opt.text : _.capitalize(key)
                    const colOpt = {
                        dataField: key,
                        text,
                        sort,
                        hidden: hide
                    }

                    if (hide) {
                        colOpt["style"] = { 'display': 'none' }
                        colOpt["headerStyle"] = (column, colIndex) => {
                            return { width: 0, 'display': 'none' }
                        }

                    }

                    if (formatter) {
                        colOpt.formatter = formatter
                    }
                    result.push(colOpt)

                    continue

                }
            }
            result.push({
                dataField: key,
                text: _.capitalize(key),
                sort: true // default
            })
        }


        if (rowEvents) {
            result.forEach((row) => {
                row.events = rowEvents
            })
        }

        if (actions && Object.keys(actions).length > 0) {
            let action = {
                sort: false,
                formatter: this.actionFormatter,
            }
            if (actionsStyle == "button") {
                action.formatter = this.actionButtonFormatter;
                action.text = "Action"
            }

            result.push(action)
        }

        return result

    }

    handleSearchChange= (e) => {
        const { target } = e;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
    
        this.setState({
          [name]: value,
        });
        
    }

    submitForm = async (e) => {
        if(e)e.preventDefault();
        let searchText = this.state.searchInput;

        this.ps.setSearch(searchText)

        await this.setState({ loading: true })
        if (this.ps.getSearch() !== searchText) return

        this.ps.setPage(1)

        await this.updateCount()

        await this.fetchData()
        this.setState({ loading: false })
    }

    get isRemote() {
        return this.props.dataCb !== undefined
    }

    get tableData() {
        const _tableData = this.isRemote ? this.state.tableData : this.props.tableData
        const { columnOptions } = this.props

        if (_tableData.length > 0) {
            for (const columnOption in columnOptions) {
                if (columnOptions.hasOwnProperty(columnOption)) {
                    const options = columnOptions[columnOption];
                    const { converter } = options
                    if (converter) {
                        _tableData.forEach(row => {
                            row[columnOption] = converter(row[columnOption])
                        })
                    }
                }
            }
        }

        return _tableData
    }

    render() {
        const { SearchBar, ClearSearchButton } = Search;
        const columns = this.generateCols()

        

        if (this.isRemote) {
            var page = this.ps.getPage()
            var search = this.ps.getSearch()
        }

        let {searchInput} = this.state;

        return (
            <ToolkitProvider
                // {...this.props}

                bootstrap4
                keyField="user_id"
                data={this.tableData}
                columns={columns}
                search={this.isRemote}
                hover={this.props.hover}
            // defaultSorted={this.props.defaultSorted}
            >
                {props => (
                    <React.Fragment>
                        <Row>
                            <Col>
                                {this.state.loading &&
                                    <Spinner
                                        className="text-primary m-2"
                                        size={"lg"}
                                        style={{ margin: "auto", height: 15, width: 15 }} />
                                }
                            </Col>
                            {this.isRemote &&
                                <Row className="text-right">
                                    <Col xs={10}>
                                        <Row>
                                            <Col xs={search ? 10 : 12}>
                                                <FeatureCheck features={["table_search"]} {...this.props} >
                                                    {/* <SearchBar
                                                        delay={4000}
                                                        placeholder={`Search ${search ? `(${search})` : ''}`}
                                                        className="float-right" {...props.searchProps}
                                                        clearSearchBtn={() => console.log('clear')}

                                                    /> */}
                                                 
                                                         <Form onSubmit={(e) => this.submitForm(e)}>

                                                     <InputGroup className="mb-3">
                                                        <Input
                                                        aria-describedby="form-search"
                                                        placeholder={`Search ${search ? `(${search})` : ''}`}
                                                        aria-label={`Search ${search ? `(${search})` : ''}`}
                                                        className="float-right"
                                                        name="searchInput"
                                                        value={searchInput}
                                                        onChange={(e) => {
                                                            this.handleSearchChange(e);
                                                          }}
                                                        />
                                                        <Button variant="outline-secondary" id="form-search">
                                                        Search
                                                        </Button>
                                                    </InputGroup>
                                                    </Form>

                                                </FeatureCheck>
                                            </Col>
                                            {search &&
                                                <Col xs={2} className="ml-0" onClick={() => {
                                                    setTimeout(() => {
                                                        
                                                        this.setState({searchInput: ""})
                                                        if (!this.state.loading) {
                                                            this.submitForm();
                                                        }
                                                    }, 1)
                                                }}>
                                                    <ClearSearchButton className="btn btn-danger dripicons-cross" text="" {...props.searchProps} >
                                                    <i className={`dripicons-cross`} />
                                                    </ClearSearchButton>
                                                </Col>
                                            }

                                        </Row>






                                    </Col>
                                </Row>
                            }

                        </Row>

                        <BootstrapTable
                            {...props.baseProps}
                            striped
                            remote={this.isRemote}
                            hover={this.props.hover}
                            bordered
                            onTableChange={this.handleTableChange}

                            // options={options}
                            defaultSorted={this.props.defaultSorted}
                            pagination={this.isRemote ? paginationFactory({
                                page,
                                showTotal: true,
                                totalSize: this.state.totalSize,
                                paginationTotalRenderer: (from, to, size) => (
                                    <span className="react-bootstrap-table-pagination-total ml-2 mt-1">
                                        Showing {ts(from)} to {ts(to)} of {ts(size)}
                                    </span>
                                ),
                                sizePerPage: this.ps.getShow(),
                                sizePerPageList: [{
                                    text: '10', value: 10
                                }, {
                                    text: '25', value: 25
                                }, {
                                    text: '50', value: 50
                                },
                                ],

                            }) : this.offlinePaginationFactory()}

                            wrapperClasses="table-responsive"
                        />
                    </React.Fragment>
                )
                }
            </ToolkitProvider>

        );
    }
}
export default withRouter(props => <GenericTable {...props} />)

// @flow
import React from 'react';
import Chart from 'react-apexcharts';

// simple bar chart
const BarChart = (props) => {
    const apexBarChartOpts = {
        chart: {
            height: props.height || 380,
            type: 'bar',
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                dataLabels: {
                    position: 'top',
                },
            },
        },
        dataLabels: {
            enabled: false,
            offsetX: -6,
            style: {
                fontSize: '12px',
                colors: ['#fff'],
            },
        },
        colors: props.colors || [ '#8492fa', '#38469b'],
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff'],
        },

        xaxis: {
            categories: props.categories,
        },
        legend: {
            offsetY: -10,
        },
        states: {
            hover: {
                filter: 'none',
            },
        },
        grid: {
            borderColor: '#f1f3fa',
        },
    };

    return (
        <>
            <h4 className="header-title mb-3">{props.title}</h4>
            <Chart options={apexBarChartOpts} series={props.stats} type="bar" className="apex-charts" height={props.height || 250} />
        </>
    );
};

export default BarChart;

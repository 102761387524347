
export const noaccess = {
    name: "Home",
    path: "/stats",
    icon: "dripicons-home"
}

export const overview = {
    name: "Overview",
    path: "/stats",
    icon: "dripicons-meter"
}

export const engagement = {
    name: "Engagement",
    path: "/stats/engagement",
    icon: "dripicons-gaming"
}

export const returning = {
    name: "Returning",
    path: "/stats/returning",
    icon: "dripicons-return"
}

export const voucher = {
    name: "Voucher",
    path: "/stats/voucher",
    icon: "dripicons-ticket"
}

export const changed = {
    name: "Prizes Changed",
    path: "/stats/changed",
    icon: "dripicons-swap"
}

// export const redeemed = {
//     name: "Redeemed",
//     path: "/redeemed",
//     icon: "dripicons-checkmark"
// }

export const users = {
    name: "Users",
    path: "/users",
    icon: "dripicons-user-group"
}

export const highscore = {
    name: "Highscore",
    path: "/highscore",
    icon: "dripicons-trophy"
}

export const channel = {
    name: "Channel",
    path: "/stats/channel",
    icon: "dripicons-link"
}

export const forms = {
    name: "Forms",
    path: "/stats/forms",
    icon: "dripicons-to-do"
}

export const adview = {
    name: "Adview",
    path: "/stats/adview",
    icon: "dripicons-preview"
}

export const age = {
    name: "Age",
    path: "/stats/age",
    icon: "dripicons-clock"
}

export const selectrandom = {
    name: "Select Random",
    path: "/stats/selectrandom",
    icon: "dripicons-retweet"
}
export const issueprize = {
    name: "Issue Prize",
    path: "/stats/issueprize",
    icon: "dripicons-card"
}

export const stores = {
    name: "Stores",
    path: "/stats/stores",
    icon: "dripicons-store"
}
export const userdata = {
    name: "User Data",
    path: "/stats/userdata",
    icon: "dripicons-user-id"
}

export const twofactor = {
    name: "Two factor",
    path: "/stats/twofactor",
    icon: "dripicons-message"
}
export const realtimemap = {
    name: "Realtime Map",
    path: "/stats/realtimemap",
    icon: "dripicons-map"
}


export default {
    noaccess,
    overview,
    engagement,
    returning,
    voucher,
    // redeemed,
    age,
    users,
    highscore,
    channel,
    adview,
    changed,
    selectrandom,
    issueprize,
    stores,
    userdata,
    twofactor,
    realtimemap
}
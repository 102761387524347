import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import BarChart from '../components/BarChart';
import FeatureCheck from '../components/FeatureCheck';
import Loading from '../components/Loading';
import StorePicker from '../components/StorePicker';
import Tab from '../components/Tab';
import GameReturningCountResolver from '../resolvers/GameReturningCountResolver';
import checkFeatures from '../utils/checkFeatures';
import ProjectDataPage from './ProjectDataPage';
import ReturningTable from './ReturningTable';

class Returning extends ProjectDataPage {
    constructor(props) {
        super("Returning", [
            GameReturningCountResolver
        ])

        this.state = Object.assign({ view: "Charts" }, this.state)

    }

    setView = (view) => {
        if (this.state.view !== view) {
            this.setState({ view })
        }
    }

    render() {
        const isLoading = this.props.loading || this.state.loading
        if(!isLoading && checkFeatures(this.props, ["game", "game_returning"])){
            if(!this.props.states.gameReturningCount){
                return (
                    <>
                        <div>Ups something went wrong</div>
                    </>
                )
            }

            var categories = this.props.states.gameReturningCount.map(r => `${r.day} day${r.day > 1 ? 's' : ''}`)
            var data = this.props.states.gameReturningCount.map(r => r.count)
        }

        return (
            <>
                <FeatureCheck features={["tab", "tab_returning"]} {...this.props} >
                    <Row style={{ width: "100%" }}>
                        {/* <Col xs={4}></Col> */}
                        <Col className="text-center mb-3" style={{ padding: 0 }}>
                            <Tab
                                setView={this.setView}
                                view={this.state.view}
                                tabs={[
                                    {
                                        title: "Charts",
                                        icon: "dripicons-pulse"
                                    },
                                    {
                                        title: "Table",
                                        icon: "dripicons-view-list"
                                    }
                                ]}
                                {...this.props}
                            />
                        </Col>
                        {/* <Col xs={4}></Col> */}

                    </Row>
                </FeatureCheck>
                {isLoading ? <Loading /> :
                    <>
                        <FeatureCheck features={["game", "game_returning"]} {...this.props} >
                            <Row style={{ width: "100%" }}>
                                <Col>
                                    {this.state.view === "Charts" && <>
                                        <Card>
                                            <CardBody>
                                                <BarChart
                                                    height={600}
                                                    categories={categories}
                                                    stats={[{ name: "Number of Users Returned", data }]}

                                                    title="Number of days users returned to game"
                                                />
                                            </CardBody>
                                        </Card>

                                    </>}
                                    {this.state.view === "Table" && <>
                                        <ReturningTable
                                            {...this.props}
                                        />
                                    </>}
                                </Col>
                            </Row>
                        </FeatureCheck>
                    </>
                }
            </>
        );
    }
}

export default withRouter(props => <Returning {...props} />);
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';
import BarChartBrite from '../components/BarChartBrite';
import Loading from '../components/Loading';
import ProjectDataPage from './ProjectDataPage';
import _ from "lodash"
import ChannelTable from './ChannelTable';
import FeatureCheck from '../components/FeatureCheck';
import { tableThousandSeperator, tzTableDateFormatter } from '../utils/Converter';
import TwoFactorForm from './TwoFactorForm';

class TwoFactor extends ProjectDataPage {
    constructor(props) {
        super("Request Two factor", [
        ])

    }
    render() {
        const supported = this.props.states.supported
        return (
            <>
                    <>
                        <FeatureCheck features={["user", "twofactorrequest"]} {...this.props} >
                            <TwoFactorForm supported={supported} {...this.props}/>
                        </FeatureCheck>
                        <br />


                    </>
            </>);
    }
}

export default withRouter(props => <TwoFactor {...props} />);
import FacadeConnection from "../FacadeConnection";


class SessionFacade extends FacadeConnection {
    getUserSessions = async (dataManager, id) => {
        return await this.get(`/project/session/user/${id}`, dataManager)
    }

    changeValidState = async (dataManager, id, state) => {
        const { authManager, projectManager } = dataManager
        const projectID = projectManager.project.id
        const { sessionID } = authManager
        const body = { valid: state }
        return await this.put(`/project/session/${id}`, body, sessionID, projectID)
    }
}

export default new SessionFacade()
import FacadeConnection from "../FacadeConnection";



class DataFacade extends FacadeConnection {

    constructor() {
        super()
    }

    getUserCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/user/count", dataManager) }
    }

 

    getUserPermissionCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/user/count/permission", dataManager) }
    }

    getUserAgeCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/user/count/age", dataManager) }
    }

    getUserExtraCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/user/count/extra", dataManager) }
    }

    getLiveUserCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/user/live", dataManager) }
    }

    getUserActiveCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/user/count/active", dataManager) }
    }

    getVouchersRedeemed = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/voucher/redeemedCount", dataManager) }
    }

    getVouchersActive = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/voucher/active", dataManager) }
    }

    getVouchersIssued = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/voucher/count", dataManager) }
    }

    getGameCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/game/count", dataManager) }
    }

    getCompletedGameCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/game/count/completed", dataManager) }
    }

    getGameReturningCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/game/count/returning", dataManager) }
    }

    getGameAvgPlaytime = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/game/count/avgPlaytime", dataManager) }
    }

    getGameWinCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/game/count/win", dataManager) }
    }

    getGameUniqueWinCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/game/count/uniqueWin", dataManager) }
    }

    getSessionCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/session/count", dataManager) }
    }

    getSessionReferrerCountTop = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/session/count/referrer?top=5", dataManager) }
    }

    getSessionDeviceCountTop = async (dataManager, name, field, top = 5) => {
        return { name, result: await this.get(`/projectData/session/count/device/${field}?top=${top}`, dataManager) }
    }

    getSessionReferrerCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/session/count/referrer", dataManager) }
    }

    getPrizeCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/prize/count", dataManager) }
    }

    getPrizeReshuffledCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/voucher/reshuffled", dataManager) }
    }

    getPrizeTypeCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/prize/count/type", dataManager) }
    }

    getAdView = async (dataManager, name) => {
        return { name, result: await this.get("/project/ad/count", dataManager) }
    }

    getPrizeChangedMaps = async (dataManager, name) => {
        return { name, result: await this.get("/project/changePrize/map", dataManager) }
    }

    getTwofactorSentCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/twofactor/count", dataManager) }
    }

    getTwofactorCompletedCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/twofactor/count/completed", dataManager) }
    }

    getTwofactorFailedCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/twofactor/count/failed", dataManager) }
    }

    getTwofactorNotRegisteredCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/twofactor/count/notRegistered", dataManager) }
    }

    getTwofactorNotUsedCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/twofactor/count/notUsed", dataManager) }
    }

    getForms = async (dataManager, name) => {
        return { name, result: await this.get("/project/form", dataManager) }
    }
    selectRandomSupport = async (dataManager, name) => {
        return { name, result: await this.get("/project/selectRandom/support", dataManager) }
    }
    selectIssuePrizeSupport = async (dataManager, name) => {
        return { name, result: await this.get("/project/issuePrize/support", dataManager) }
    }

    getStores = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/stores/list", dataManager) }
    }

    getQuestionData = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/user/question", dataManager) }
    }

    getCKExtraUserCount = async (dataManager, name) => {
        return { name, result: await this.get("/projectData/user/ckextracount", dataManager) }
    }
}

export default new DataFacade()
import React, { Component, Suspense } from "react"

import { Card, CardHeader, CardBody, Container, Row, Col, Dropdown, DropdownMenu, DropdownToggle, Collapse } from "reactstrap"
import { Link, Route, Router, Switch, Redirect } from 'react-router-dom';
import NotFound from "../pages/NotFound"
import Account from "./Account"

import Menu from "../components/Menu";
import ProjectData from "./ProjectData"
import Home from "./Home"
import MobileNav from "../components/MobileNav";
import Bar from "../components/Bar";
import Campaigns from "./Campaigns"
import Users from "./Users";
import Engagement from "./Engagement";
import ProjectRoute from "../components/ProjectRoute";
import Highscore from "./Highscore";
import IPBlacklist from "./IPBlacklist";
import { withAlert } from "react-alert";

const PROFILE_MENUS = [
    // {
    //     label: 'My Account',
    //     icon: 'dripicons-user',
    //     redirectTo: '/account',
    // },
];

class ContentContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMenuOpened: false,
            menuItems: [],
        }
    }

    updateMenuItems = () => {
        const menuItems = this.props.projectManager.getMenuItems()
        this.setState({ menuItems })
    }

    toggleMenu = (state = null) => {
        // console.log(this.state.isMenuOpened)
        this.setState({ isMenuOpened: state === null ? !this.state.isMenuOpened : state })
    }

    render() {
        const children = this.props.children || null;
        const { user } = this.props.auth

        var profileMenusToUse = Object.assign([], PROFILE_MENUS)

        if (user && user.type == "admin") {
            profileMenusToUse.push({
                label: 'Ip Blacklist',
                icon: 'dripicons-clipboard',
                redirectTo: '/ipBlacklist',
            })
        }

        return (
            <>
                <Container fluid>
                    <Row>
                        <Bar toggleMenu={this.toggleMenu} menuItems={profileMenusToUse} user={user.name} {...this.props} />
                    </Row>

                    <Switch>
                        <Route exact {...this.props} path="/account" direct={true}>
                            <Account />
                        </Route>

                        <Route exact {...this.props} path="/ipBlacklist" direct={true}>
                            <IPBlacklist {...this.props} />
                        </Route>

                        <Route exact {...this.props} path="/campaigns">
                            <Campaigns {...this.props} />
                        </Route>


                        <ProjectRoute {...this.props} path="/">
                            <>
                                <Row>
                                    <MobileNav
                                        menuItems={this.state.menuItems}
                                        isMenuOpened={this.state.isMenuOpened}
                                        toggleMenu={this.toggleMenu}
                                        updateMenuItems={this.updateMenuItems}
                                    />
                                </Row>

                                <Row className="d-sm-block d-xs-block d-md-none">
                                    <Col xs={12} sm={12} md={12} style={{ marginTop: 20 }} >
                                        <Menu
                                            menuItems={this.state.menuItems}
                                            updateMenuItems={this.updateMenuItems}
                                            {...this.props}
                                        />
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: 20 }} >
                                    <Col sm={3} md={4} lg={3} xl={2} style={{ height: "100%" }} className="d-none d-md-block">
                                        <Menu
                                            menuItems={this.state.menuItems}
                                            updateMenuItems={this.updateMenuItems}
                                            {...this.props} />
                                    </Col>

                                    <Col xs={12} sm={12} md={8} lg={9} xl={10}>
                                        <div style={{ height: "100%", minHeight: window.innerHeight }}>
                                            <Switch>
                                                <Route {...this.props} path="/users">
                                                    <Users {...this.props} />
                                                </Route>
                                                <Route {...this.props} path="/highscore">
                                                    <Highscore {...this.props} />
                                                </Route>
                                                <Route {...this.props} path="/stats">
                                                    <ProjectData {...this.props} />
                                                </Route>

                                                <Route {...this.props}>
                                                    <NotFound />
                                                </Route>
                                            </Switch>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        </ProjectRoute>

                    </Switch>


                </Container>

                <footer style={{ textAlign: "center", }}>
                    <hr />
                    <a>{new Date().getFullYear()} © BrandMobile</a>
                    <br /><br />
                    {/* TODO remove */}
                    {process.env.NODE_ENV !== "production" &&
                        <div class="alert alert-info">
                            <div class="d-none d-xl-block font-weight-bold">X-LARGE (xl)</div>
                            <div class="d-none d-lg-block d-xl-none font-weight-bold">LARGE (lg)</div>
                            <div class="d-none d-md-block d-lg-none font-weight-bold">MEDIUM (md)</div>
                            <div class="d-none d-sm-block d-md-none font-weight-bold">SMALL (sm)</div>
                            <div class="d-block d-sm-none alert font-weight-bold">X-SMALL (xs)</div>
                        </div>
                    }
                </footer>


            </>
        )
    }
}

export default withAlert()(ContentContainer)

const URI = process.env.REACT_APP_MAP_URL
const API_KEY = process.env.REACT_APP_MAP_API_KEY

class MapFacade {
    async getLocations(mapRef) {
        return (await fetch(`${URI}location.php?map=${mapRef}`,{
            headers:{
                "api-key": API_KEY
            }
        })).json()
    }
}

export default new MapFacade()
import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { withRouter, Route, Switch } from 'react-router-dom';
import HighscoreList from './HighscoreList';
import FeatureCheck from '../components/FeatureCheck';

export default withRouter(props => <Highscore {...props} />)


class Highscore extends Component {
    render() {
        return (
            <div>
                <Container fluid>
                    <Switch>
                        <Route exact path="/highscore">
                            <h4>Highscore</h4>
                            <br />
                            <FeatureCheck features={["user", "user_highscore"]} {...this.props} >
                                <HighscoreList {...this.props} />
                            </FeatureCheck>
                        </Route>
                    </Switch>
                </Container>
            </div>
        );
    }
}

import FacadeConnection from "../FacadeConnection";
import _ from "lodash";

class HighscoreFacade extends FacadeConnection {

    constructor() {
        super()
    }

    getHighscoreOnUserID = async (dataManager, userID) => {
        return this.get(`/project/highscore/${userID}`, dataManager)
    }

    getHighscores = async (dataManager, from, to, orderBy = null, order = null, search = null) => {
        const { authManager, projectManager } = dataManager
        const projectID = projectManager.project.id
        const { sessionID } = authManager

        const params = {
            from,
            to
        }

        if (orderBy !== null) params.orderBy = orderBy
        if (order !== null) params.order = _.capitalize(order)
        if (search !== null) params.search = search


        return await (await this._send("/project/highscore", "GET", params, sessionID, projectID)).json()
    }

    getHighscoreCount = async (dataManager, search = null) => {
        const { authManager, projectManager } = dataManager
        const projectID = projectManager.project.id
        const { sessionID } = authManager

        const params = {}
        if (search !== null) params.search = search

        return await (await this._send("/project/highscore/count", "GET", params, sessionID, projectID)).json()

    }

    changeBanStatusOnUserID = async (dataManager, userID, banState) => {
        const { authManager, projectManager } = dataManager
        const projectID = projectManager.project.id
        const { sessionID } = authManager

        return await this.put(`/project/highscore/${userID}/ban/${banState}`, null, sessionID, projectID)

    }
}

export default new HighscoreFacade()
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, CardFooter, Col, Container, Row } from 'reactstrap';
import BarChartBrite from '../components/BarChartBrite';
import Loading from '../components/Loading';
import Tab from '../components/Tab';
import SessionReferrerCountTopResolver from '../resolvers/SessionReferrerCountTopResolver';
import { thousandSeperator } from '../utils/Converter';
import ProjectDataPage from './ProjectDataPage';
import _ from "lodash"
import SessionCountResolver from '../resolvers/SessionCountResolver';
import ChannelTable from './ChannelTable';
import SessionReferrerCountResolver from '../resolvers/SessionReferrerCountResolver';
import FeatureCheck from '../components/FeatureCheck';


class Channel extends ProjectDataPage {
    constructor(props) {
        super("Channel", [
            SessionReferrerCountTopResolver,
            SessionReferrerCountResolver
        ])


    }


    render() {
        const referrerCounts = this.props.states.sessionReferrerTopCount


        function hashCode(str) { // java String#hashCode
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
               hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            return hash;
        } 
        
        function intToRGB(i){
            var c = (i & 0x00FFFFFF)
                .toString(16)
                .toUpperCase();
        
            return "00000".substring(0, 6 - c.length) + c;
        }

        if (referrerCounts) {

            this.props.states.sessionReferrerTopCount.map(({color, referrer}, i)=>{
                if(!color&&referrer!='Other'){
                    this.props.states.sessionReferrerTopCount[i].color = '#'+intToRGB(hashCode(referrer))
                }
            })
          



            var totalReferrer = referrerCounts.reduce((prev, cur) => prev + cur.count, 0)
            var donutData = referrerCounts.map(({ referrer, count, color }, i) => ({ name: referrer, quantity: count, percentage: _.round((count / totalReferrer) * 100, 2), color }))

        }



        if(this.props.states.sessionReferrerCount){
        this.props.states.sessionReferrerCount.map(({color, referrer}, i)=>{
            if(!color&&referrer!='Other'){
                this.props.states.sessionReferrerCount[i].color = '#'+intToRGB(hashCode(referrer))
            }
        })
    }


        return (
            <>
                {this.props.loading || this.state.loading ? <Loading /> :
                    <>
                        <FeatureCheck features={["session", "session_referrer"]} {...this.props} >
                                <Container fluid>
                                    <Row>
                                        <Col>
                                            <Card style={{ maxWidth: 745, margin: "auto",marginBottom: '40px' }}>
                                                <CardBody>
                                                    <BarChartBrite
                                                        title="Top Channels"
                                                        data={donutData}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Container>

                                <ChannelTable
                                    {...this.props}
                                />
                        </FeatureCheck>


                    </>
                }
            </>);
    }
}

export default withRouter(props => <Channel {...props} />);
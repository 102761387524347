import React, { Component } from 'react';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import GenericTable from '../components/GenericTable';
import { tableThousandSeperator } from '../utils/Converter';

class VoucherTable extends Component {
    render() {

        const data = this.props.prizeTypes.map(({ title,barcode, prizePool, max, count, redeemedCount, activeCount, redeemPercent ,redeemRate,changeCount, changePercent}) => {
            return {
                "Title": title,
                "Barcode": barcode,
                "Max": typeof max == 'undefined' ? 'No limit' : max,
                "Issued": count,
                "Redeemed": redeemedCount,
                "Active": activeCount,
                "ChangeCount": changeCount||0,
                "redeemedOfMax": redeemPercent,
                "redeemedRate": redeemRate,
                "ChangePercent": changePercent||0,

            }
        })
        return (
            <div>
                <Card>
                    <CardBody>
                        <GenericTable
                            {...this.props}
                            pagination
                            tableData={data}
                            sizePerPage={10}
                            columnOptions={{
                                "Title": {
                                    sort: false,
                                },
                                "Barcode": {
                                    sort: false,
                                },
                                "prizePool": {
                                    text: "Prize Pool",
                                    sort: false,
                                },

                                "Max": {
                                    sort: false,
                                    formatter: tableThousandSeperator
                                },
                                "Issued": {
                                    sort: false,
                                    formatter: tableThousandSeperator,
                                    hide: this.props.storeChosen
                                },
                                "Redeemed": {
                                    sort: false,
                                    formatter: tableThousandSeperator
                                },
                                "Active": {
                                    sort: false,
                                    formatter: tableThousandSeperator,
                                    hide: this.props.storeChosen
                                },
                                "ChangeCount": {
                                    text: "Change Count",
                                    sort: false,
                                    formatter: tableThousandSeperator,
                                    hide: this.props.changeCount
                                },
                                "redeemedOfMax": {
                                    text: "Redeemed of Max",
                                    sort: false,
                                    formatter: (cell) => `${cell}%`,
                                },
                                "redeemedRate": {
                                    text: "Redeemed of Issued",
                                    sort: false,
                                    formatter: (cell) => `${cell}%`,
                                },
                                "ChangePercent": {
                                    text: "Change Percent",
                                    sort: false,
                                    formatter: (cell) => `${cell}%`,
                                },

                            }}

                        />
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default VoucherTable;
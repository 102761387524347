import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Redirect, withRouter, Route, Switch } from 'react-router-dom';
import { makeTo } from '../utils/LinkUtils';
import UserList from './UserList';
import User from './User';
import FeatureCheck from '../components/FeatureCheck';

export default withRouter(props => <Users {...props} />)


class Users extends Component {
    render() {
        return (
            <div>
                <Container fluid>
                    <Switch>
                        <Route exact path="/users">
                            <h4>Users</h4>
                            <br />
                            <FeatureCheck features={["user"]} {...this.props} >
                                <UserList {...this.props} />
                            </FeatureCheck>
                        </Route>
                        <Route exact path="/users/:id">
                            <User {...this.props} />
                        </Route>
                    </Switch>


                </Container>
            </div>
        );
    }
}



import React, { Component } from 'react';
import UserFacade from '../facade/project/UserFacade';
import GenericTable from '../components/GenericTable';
import { CardBody, Card } from 'reactstrap';
import { Redirect, withRouter, Route, Switch } from 'react-router-dom';

import { makeTo } from '../utils/LinkUtils';
import { thousandSeperator, tzTableDateFormatter } from '../utils/Converter';
import HighscoreFacade from "../facade/project/HighscoreFacade"
import checkFeatures from '../utils/checkFeatures';

export default withRouter(props => <HighscoreList {...props} />)

class HighscoreList extends Component {

    state = {
        redirect: null,
    }

    handleClick = async (e, column, columnIndex, row, rowIndex) => {
        //making sure to unmount table so the lifecycle method in it gets fired
        this.setState({ redirect: makeTo(`/users/${row["user_id"]}`, this.props.location) })
    }

    render() {
        const timezone = this.props.data.projectManager.project.timezone
        const tableDateFormatter = tzTableDateFormatter(timezone)
        if (this.state.redirect) {
            return <Redirect push to={{
                pathname: this.state.redirect,
                state: { from: this.props.location }
            }} />
        }
        return (
            <Card>
                <CardBody>

                    {/* <Route /> */}
                    <GenericTable
                        {...this.props}
                        hover
                        dataCb={HighscoreFacade.getHighscores}
                        countCb={HighscoreFacade.getHighscoreCount}
                        rowEvents={{
                            onClick: this.handleClick
                        }}
                        columnOptions={{
                            rank_index: {
                                text: "#",
                            },
                            "user_id":{
                                text:"User ID",
                                hide: true
                            },
                            "last_updated":{
                                text: "Last Updated",
                                formatter: tableDateFormatter,
                            },
                            "points":{
                                formatter: thousandSeperator
                            },
                            "mobile":{
                                hide: !checkFeatures(this.props, ["show_login_info_user"])
                            },
                            "email":{
                                hide: !checkFeatures(this.props, ["show_login_info_user"])
                            },
                            "username":{
                                hide: !checkFeatures(this.props, ["user_highscore_username"])
                            }
                           

                        }}
                        defaultSorted={[{
                            dataField: 'rank_index',
                            order: 'asc'
                        }]}
                    />


                </CardBody>
            </Card>
        );
    }
}
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Home from './Home';
import Engagement from "./Engagement"
import DataFacade from '../facade/project/DataFacade';
import tzMoment from "../utils/tzMoment"
import DataManager from '../managers/DataManager';
import DatePickerBar from '../components/DatePickerBar';
import { Container } from 'reactstrap';
import Voucher from './Voucher';
import Returning from './Returning';
import Channel from './Channel';
import AdView from './AdView';
import checkFeatures from '../utils/checkFeatures';
import PrizesChanged from './PrizesChanged';
import Age from './Age';
import Forms from './Forms';
import SelectRandom from './SelectRandom';
import IssuePrize from './IssuePrize';
import Stores from './Stores';
import UserData from './UserData';
import TwoFactor from './TwoFactor';
import RealtimeMap from './Realtimemap';
import moment from 'moment';


const DATE_PICKER_BLACKLIST_TITLES = ["Returning", "Adview"]

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
class ProjectData extends Component {
    state = {
        loading: true,
        reload: false,
        title: "Loading...",
        showBar: true,

        resolvers: [],

        showTrend: true,

        states: {},

        weekChartStatsStrings: [],
        weekChartStats: null,

        fullChartStatsStrings: [],
        fullChartStats: null,

        disableDatePicker: false
    }



    componentDidMount = async () => {
        // await this.fetchData()
        const { projectManager } = this.props
        const { accesses } = projectManager
        if (accesses.length === 0) {
            this.setState({ showBar: false })
        }
    }

    componentWillUnmount = async () => {
        DataFacade.abort()
        this.props.data.clear()
    }

    update = async (bgLoading = false) => {
        if (!this.state.reload && !this.state.loading) {
            const state = { reload: true, loading: true, bgLoading }
            state.states = {}
            // state["weekChartStats"] = null
            // state["fullChartStats"] = null
            await this.setState(state)
            await this.fetchData()
        }

    }

    fetchData = async (loading = true) => {
        try {
            await this.setState({ loading })



            if (this.state.resolvers.length > 0) {
                await Promise.all([
                    this.fetchAllTime(),
                    this.fetchTrends(),
                    this.fetchLastWeek(),
                    this.fetchToday(),
                    this.fetchFull()
                ])

                await this.setState({ loading: false, reload: false, bgLoading: false })
            }
        } catch (e) {
            this.props.alert.error("Failed to load data!")
        }

    }

    fetchAllTime = async () => {
        const allTimeResolvers = this.getResolversOfType()
        if (allTimeResolvers.length > 0) {
            const data = this.loadDataCopy()

            if (!data.periodChanged()) {
                data.tempAll = true
            }

            const unpopulatedResolvers = this.getUnpopulatedResolvers(allTimeResolvers)
            const promises = this.convertResolversToPromises(unpopulatedResolvers, data)

            await this.promiseHandler(promises)

            data.clearTemps()
        }

    }


    fetchTrends = async () => {
        const trendResolvers = this.getResolversOfType("trend")
        if (trendResolvers.length > 0) {
            const data = this.loadDataCopy()

            data.tempEnd = tzMoment(data)(data.end).subtract(7, "days").toDate()

            const unpopulatedResolvers = this.getUnpopulatedResolvers(trendResolvers)
            const promises = this.convertResolversToPromises(unpopulatedResolvers, data)

            await this.promiseHandler(promises)

            data.clearTemps()
        }
    }

    fetchPeriod = async (resolvers, n, unit, inObject) => {
        if (resolvers.length > 0) {
            const data = this.loadDataCopy()
            let date = tzMoment(data)(data.end).add(1, "day").subtract(1, "ms").startOf("day").subtract(n, "day")

            data.tempStart = date.toDate()
            data.tempGranulize = unit
            const unpopulatedResolvers = this.getUnpopulatedResolvers(resolvers, inObject)
            const promises = this.convertResolversToPromises(unpopulatedResolvers, data)
            let results = await Promise.all(promises)

            if (this.state[inObject] && !this.state.reload) {
                var resultObj = Object.assign({}, this.state[inObject])
            } else {
                resultObj = {}
            }

            for (const res of results) {
                const { name, result } = res

                resultObj[name] = result
            }

            let dateStrings = []
            let curDate = date.clone()

            if (unit === "hour") {
                for (let i = 0; i < 24; i++) {
                    dateStrings.push(i)
                }
            } else {
                while (dateStrings.length < n) {
                    const dateString = curDate.format("DD.MM.YYYY")
                    dateStrings.push(dateString)
                    curDate.add(1, unit)
                }
            }



            let stateObj = {}
            stateObj[inObject] = resultObj
            await this.setState(stateObj)

            let inObjectStrings = {}
            inObjectStrings[`${inObject}Strings`] = dateStrings
            await this.setState(inObjectStrings)

            data.clearTemps()
        }
    }

    fetchLastWeek = async () => {
        const lastWeekResolvers = this.getResolversOfType("lastWeek")
        await this.fetchPeriod(lastWeekResolvers, 7, "day", "weekChartStats")
    }

    fetchFull = async () => {
        const data = this.loadDataCopy()
        const moment = tzMoment(data)
        const mStart = moment(data.start)
        const mEnd = moment(data.end).add(1, "day").subtract(1, "ms").startOf("day")

        var duration = moment.duration(mEnd.diff(mStart));
        var n = Math.floor(duration.asDays());
        const fullResolvers = this.getResolversOfType("full")

        let granularity = "day"
        if (data.fetchHour) {
            granularity = "hour"
        }

        await this.fetchPeriod(fullResolvers, n, granularity, "fullChartStats")
    }

    fetchToday = async () => {
        const todayResolvers = this.getResolversOfType("today")

        if (todayResolvers.length > 0) {
            const data = this.loadDataCopy()
            let dateEnd = tzMoment(data)(data.end)
            data.tempEnd = dateEnd.toDate()
            data.tempStart = dateEnd.subtract(1, "ms").startOf("day").toDate()

            const unpopulatedResolvers = this.getUnpopulatedResolvers(todayResolvers)
            const promises = this.convertResolversToPromises(unpopulatedResolvers, data)
            await this.promiseHandler(promises)

            data.clearTemps()
        }
    }

    loadDataCopy = () => {
        const { data } = this.props
        return Object.assign(new DataManager(), data)
    }

    convertResolversToPromises = (resolvers, dataManager) => {
        return resolvers.map((resolver) => {
            return new Promise(async (resolve, reject) => {
                try {
                    var result = await this.executeResolverCall(resolver, dataManager)
                } catch (e) {
                    //trying again
                    try {
                        await timeout(500)
                        result = await this.executeResolverCall(resolver, dataManager)
                    } catch (e) {
                        try {
                            await timeout(3000)
                            result = await this.executeResolverCall(resolver, dataManager)
                        } catch (e) {
                            reject(e)
                        }
                    }
                }
                resolve(result)
            })
        })
    }

    executeResolverCall = async (resolver, dataManager) => {
        dataManager.tempTablewise = resolver.tablewise
        const result = await resolver.resolver(dataManager, resolver.name)
        dataManager.tempTablewise = false
        return result
    }

    promiseHandler = async (promises) => {
        const result = await Promise.all(promises)
        result.forEach((res) => {
            if (res.result.status !== undefined && res.result.status !== 200) {
                res.result = null
            }

            const states = Object.assign({}, this.state.states)
            states[res.name] = res.result
            this.setState({ states })
        })
    }

    getTodayOfWeekStat = (name) => {
        const stats = this.state.weekChartStats[name]
        if (stats) {
            return stats[stats.length - 1]
        } else {
            return null
        }
    }

    setTitle = (newTitle) => {
        const disableDatePicker = DATE_PICKER_BLACKLIST_TITLES.indexOf(newTitle) !== -1

        this.setState({ title: newTitle, disableDatePicker })
    }

    setResolvers = async (resolvers) => {
        await this.setState({ resolvers })
        for (const { name, type } of resolvers) {
            let inObject = null
            if (type === "lastWeek") {
                inObject = "weekChartStats"
            }
            if (type === "full") {
                inObject = "fullChartStats"
            }
            const stateField = this.getStateField(name, inObject)
            if (stateField === undefined || stateField === null) {
                const newObject = {}
                if (inObject) {
                    if (this.state[inObject]) {
                        const inObjectCopy = Object.assign({}, this.state[inObject])
                        inObjectCopy[name] = null
                        newObject[inObject] = inObjectCopy
                        await this.setState(newObject)
                    }
                } else {
                    newObject[name] = null
                    await this.setState({ states: newObject })
                }
            }
        }
    }

    getResolversOfType = (typeWanted = null) => {
        return this.state.resolvers.filter(({ type }) => type === typeWanted)
    }

    getUnpopulatedResolvers = (resolvers, inObject = null) => {
        // return resolvers
        const res = resolvers.filter((resolver) => {
            const { requiredFeatures } = resolver
            if (requiredFeatures.length > 0) {
                const cleared = checkFeatures(this.props, requiredFeatures)
                if (!cleared) return false
            }

            if (this.state.reload) return true

            const stateField = this.getStateField(resolver.name, inObject)

            if (stateField === undefined || stateField === null) {
                return true
            } else {
                return false
            }
        })
        return res
    }

    getStateField = (name, inObject = null) => {
        if (inObject) {
            if (this.state[inObject] !== null) {
                return this.state[inObject][name]
            } else {
                return null
            }
        } else {
            return this.state.states[name]
        }
    }

    setShowBar = (showBar) => {
        this.setState({ showBar })
    }

    setLoadingState = async (loading) => {
        await this.setState({ loading })
    }

    setRedirect = async (redirect) => {
        this.setState({ redirect })
    }

    convertTime(date, timezoneFrom, timezoneTo) {
        const mm = moment.tz(date, timezoneFrom)
        const formattedDate = mm.format("YYYY-MM-DD HH:mm:ss")
        const timeTo = moment.tz(formattedDate, timezoneTo)
        const result = timeTo.toDate()
        return result
    }

    convertFrom(date) {
        const timezoneFrom = moment.tz.guess()
        const timezoneTo = this.props.projectManager.project.timezone
        return this.convertTime(date, timezoneFrom, timezoneTo)
    }

    pickDate = async (date) => {
        if(!this.props.data.fetchHour){
            const timezoneTo = this.props.projectManager.project.timezone
            const split = date.split(".")
            const day = split[0]
            const month = split[1]
            const year = split[2]
            date = `${year}-${month}-${day} 00:00:00`
            let mm = moment.tz(date, timezoneTo)
            // alert(`date: ${converted}`)
            // mm = mm.startOf("day")
            let start = mm.toDate()
            await timeout(1) //solving race issue
            mm.add(1, "day")
            let end = mm.toDate()
    
            // start = this.convertFrom(start)
            // end = this.convertFrom(end)
            this.props.data.tempStart = start
            this.props.data.tempEnd = end
            this.props.data.fetchHour = true

            await this.update()
        }
    }

    resetDate = async () => {
        this.props.data.tempStart = null
        this.props.data.tempEnd = null
        this.props.data.fetchHour = false
        await this.update()
    }


    render() {
        return (
            <>
                <Container fluid>
                    <DatePickerBar
                        {...this.props}
                        update={this.update}
                        title={this.state.title}
                        disableDatePicker={this.state.disableDatePicker}
                        showBar={this.state.showBar}
                        setRedirect={this.setRedirect}
                        redirect={this.state.redirect}
                        loading={this.state.loading}
                        resetDate={this.resetDate}

                    />

                    <Switch>
                        <Route exact {...this.props} path="/stats">
                            <Home {...this.props}
                                setRedirect={this.setRedirect}
                                setLoadingState={this.setLoadingState}
                                setResolvers={this.setResolvers}
                                fetchData={this.fetchData}
                                setTitle={this.setTitle}
                                update={this.update}
                                setShowBar={this.setShowBar}
                                getTodayOfWeekStat={this.getTodayOfWeekStat}
                                loading={this.state.loading}
                                bgLoading={this.state.bgLoading}
                                showTrend={this.state.showTrend}

                                weekChartStats={this.state.weekChartStats}
                                weekChartStatsStrings={this.state.weekChartStatsStrings}

                                //project data
                                states={this.state.states}
                            />
                        </Route>
                        <Route {...this.props} path="/stats/engagement">
                            <Engagement {...this.props}
                                setRedirect={this.setRedirect}

                                setLoadingState={this.setLoadingState}

                                setResolvers={this.setResolvers}
                                fetchData={this.fetchData}
                                setTitle={this.setTitle}
                                getTodayOfWeekStat={this.getTodayOfWeekStat}
                                loading={this.state.loading}
                                showTrend={this.state.showTrend}

                                fullChartStats={this.state.fullChartStats}
                                fullChartStatsStrings={this.state.fullChartStatsStrings}

                                states={this.state.states}
                                pickDate={this.pickDate}
                                resetDate={this.resetDate}
                            />
                        </Route>
                        <Route {...this.props} path="/stats/voucher*">
                            <Voucher {...this.props}
                                setRedirect={this.setRedirect}

                                setLoadingState={this.setLoadingState}
                                update={this.update}
                                setResolvers={this.setResolvers}
                                fetchData={this.fetchData}
                                setTitle={this.setTitle}
                                getTodayOfWeekStat={this.getTodayOfWeekStat}
                                loading={this.state.loading}
                                showTrend={this.state.showTrend}

                                fullChartStats={this.state.fullChartStats}
                                fullChartStatsStrings={this.state.fullChartStatsStrings}

                                states={this.state.states}

                            />
                        </Route>
                        <Route {...this.props} path="/stats/returning">
                            <Returning {...this.props}
                                setRedirect={this.setRedirect}
                                setLoadingState={this.setLoadingState}
                                update={this.update}
                                setResolvers={this.setResolvers}
                                fetchData={this.fetchData}
                                setTitle={this.setTitle}
                                getTodayOfWeekStat={this.getTodayOfWeekStat}
                                loading={this.state.loading}
                                showTrend={this.state.showTrend}

                                fullChartStats={this.state.fullChartStats}
                                fullChartStatsStrings={this.state.fullChartStatsStrings}

                                states={this.state.states}

                            />
                        </Route>
                        <Route {...this.props} path="/stats/age">
                            <Age {...this.props}
                                setRedirect={this.setRedirect}
                                setLoadingState={this.setLoadingState}
                                update={this.update}
                                setResolvers={this.setResolvers}
                                fetchData={this.fetchData}
                                setTitle={this.setTitle}
                                getTodayOfWeekStat={this.getTodayOfWeekStat}
                                loading={this.state.loading}
                                showTrend={this.state.showTrend}

                                fullChartStats={this.state.fullChartStats}
                                fullChartStatsStrings={this.state.fullChartStatsStrings}

                                states={this.state.states}

                            />
                        </Route>
                        <Route {...this.props} path="/stats/channel">
                            <Channel {...this.props}
                                setRedirect={this.setRedirect}
                                setLoadingState={this.setLoadingState}
                                update={this.update}
                                setResolvers={this.setResolvers}
                                fetchData={this.fetchData}
                                setTitle={this.setTitle}
                                getTodayOfWeekStat={this.getTodayOfWeekStat}
                                loading={this.state.loading}
                                showTrend={this.state.showTrend}

                                fullChartStats={this.state.fullChartStats}
                                fullChartStatsStrings={this.state.fullChartStatsStrings}

                                states={this.state.states}
                            />
                        </Route>

                        <Route {...this.props} path="/stats/forms">
                            <Forms {...this.props}
                                setRedirect={this.setRedirect}
                                setLoadingState={this.setLoadingState}
                                update={this.update}
                                setResolvers={this.setResolvers}
                                fetchData={this.fetchData}
                                setTitle={this.setTitle}
                                getTodayOfWeekStat={this.getTodayOfWeekStat}
                                loading={this.state.loading}
                                showTrend={this.state.showTrend}

                                fullChartStats={this.state.fullChartStats}
                                fullChartStatsStrings={this.state.fullChartStatsStrings}

                                states={this.state.states}
                            />
                        </Route>
                        <Route {...this.props} path="/stats/selectrandom">
                            <SelectRandom {...this.props}
                                setRedirect={this.setRedirect}
                                setLoadingState={this.setLoadingState}
                                update={this.update}
                                setResolvers={this.setResolvers}
                                fetchData={this.fetchData}
                                setTitle={this.setTitle}
                                getTodayOfWeekStat={this.getTodayOfWeekStat}
                                loading={this.state.loading}
                                showTrend={this.state.showTrend}

                                fullChartStats={this.state.fullChartStats}
                                fullChartStatsStrings={this.state.fullChartStatsStrings}

                                states={this.state.states}
                            />
                        </Route>
                        <Route {...this.props} path="/stats/issueprize">
                            <IssuePrize {...this.props}
                                setRedirect={this.setRedirect}
                                setLoadingState={this.setLoadingState}
                                update={this.update}
                                setResolvers={this.setResolvers}
                                fetchData={this.fetchData}
                                setTitle={this.setTitle}
                                getTodayOfWeekStat={this.getTodayOfWeekStat}
                                loading={this.state.loading}
                                showTrend={this.state.showTrend}

                                fullChartStats={this.state.fullChartStats}
                                fullChartStatsStrings={this.state.fullChartStatsStrings}

                                states={this.state.states}
                            />
                        </Route>
                        <Route {...this.props} path="/stats/stores">
                            <Stores {...this.props}
                                setRedirect={this.setRedirect}
                                setLoadingState={this.setLoadingState}
                                update={this.update}
                                setResolvers={this.setResolvers}
                                fetchData={this.fetchData}
                                setTitle={this.setTitle}
                                getTodayOfWeekStat={this.getTodayOfWeekStat}
                                loading={this.state.loading}
                                showTrend={this.state.showTrend}

                                fullChartStats={this.state.fullChartStats}
                                fullChartStatsStrings={this.state.fullChartStatsStrings}

                                states={this.state.states}
                            />
                        </Route>
                        <Route {...this.props} path="/stats/userdata">
                            <UserData {...this.props}
                                setRedirect={this.setRedirect}
                                setLoadingState={this.setLoadingState}
                                update={this.update}
                                setResolvers={this.setResolvers}
                                fetchData={this.fetchData}
                                setTitle={this.setTitle}
                                getTodayOfWeekStat={this.getTodayOfWeekStat}
                                loading={this.state.loading}
                                showTrend={this.state.showTrend}

                                fullChartStats={this.state.fullChartStats}
                                fullChartStatsStrings={this.state.fullChartStatsStrings}

                                states={this.state.states}
                            />
                        </Route>
                        <Route {...this.props} path="/stats/twofactor">
                            <TwoFactor {...this.props}
                                setRedirect={this.setRedirect}
                                setLoadingState={this.setLoadingState}
                                update={this.update}
                                setResolvers={this.setResolvers}
                                fetchData={this.fetchData}
                                setTitle={this.setTitle}
                                getTodayOfWeekStat={this.getTodayOfWeekStat}
                                loading={this.state.loading}
                                showTrend={this.state.showTrend}

                                fullChartStats={this.state.fullChartStats}
                                fullChartStatsStrings={this.state.fullChartStatsStrings}

                                states={this.state.states}
                            />
                        </Route>
                        <Route {...this.props} path="/stats/realtimemap">
                            <RealtimeMap {...this.props}
                                setRedirect={this.setRedirect}
                                setLoadingState={this.setLoadingState}
                                update={this.update}
                                setResolvers={this.setResolvers}
                                fetchData={this.fetchData}
                                setTitle={this.setTitle}
                                getTodayOfWeekStat={this.getTodayOfWeekStat}
                                loading={this.state.loading}
                                showTrend={this.state.showTrend}

                                fullChartStats={this.state.fullChartStats}
                                fullChartStatsStrings={this.state.fullChartStatsStrings}

                                states={this.state.states}
                            />
                        </Route>
                        <Route {...this.props} path="/stats/adview">
                            <AdView {...this.props}
                                setRedirect={this.setRedirect}
                                setLoadingState={this.setLoadingState}
                                update={this.update}
                                setResolvers={this.setResolvers}
                                fetchData={this.fetchData}
                                setTitle={this.setTitle}
                                getTodayOfWeekStat={this.getTodayOfWeekStat}
                                loading={this.state.loading}
                                showTrend={this.state.showTrend}

                                fullChartStats={this.state.fullChartStats}
                                fullChartStatsStrings={this.state.fullChartStatsStrings}

                                states={this.state.states}
                            />
                        </Route>

                        <Route {...this.props} path="/stats/changed">
                            <PrizesChanged {...this.props}
                                setRedirect={this.setRedirect}
                                setLoadingState={this.setLoadingState}
                                update={this.update}
                                setResolvers={this.setResolvers}
                                fetchData={this.fetchData}
                                setTitle={this.setTitle}
                                getTodayOfWeekStat={this.getTodayOfWeekStat}
                                loading={this.state.loading}
                                showTrend={this.state.showTrend}

                                fullChartStats={this.state.fullChartStats}
                                fullChartStatsStrings={this.state.fullChartStatsStrings}

                                states={this.state.states}
                            />
                        </Route>
                    </Switch>
                </Container>
            </>

        );
    }
}

export default withRouter(props => <ProjectData {...props} />);
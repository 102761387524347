import React, { Component, Suspense } from "react"

import { Card, CardHeader, CardBody, Container, Row, Col, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap"
import { Link, withRouter } from 'react-router-dom';
import { makeTo } from "../utils/LinkUtils";
export default withRouter(props => <ProfileDropdown {...props} />)

class ProfileDropdown extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dropdownOpen: false,
        };
    }

    toggleDropdown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    render() {
        const profilePic = this.props.profilePic || null;
        const { user } = this.props.auth
        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} >
                <DropdownToggle
                    data-toggle="dropdown"
                    tag="button"
                    className="btn btn-link nav-link dropdown-toggle nav-user arrow-none mr-0"
                    onClick={this.toggleDropdown}
                    aria-expanded={this.state.dropdownOpen}>
                    <span className="account-user-avatar">
                        <img src={profilePic} className="rounded-circle" alt="user" />
                    </span>
                    <span style={{ color: "#cbcbcb" }}>
                        <span className="account-user-name">{user.name}</span>
                        <span className="account-position">{user.title}</span>
                    </span>
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
                    <div onClick={this.toggleDropdown}>
                        <div className="dropdown-header noti-title">
                            <h6 className="text-overflow m-0" style={{overflow:'hidden',textOverflow:'ellipsis'}}>Welcome {user.name}!</h6>
                        </div>
                        {this.props.menuItems.map((item, i) => {
                            return (
                                <Link
                                    to={{
                                        pathname: item.redirectTo,
                                    }}
                                    
                                    className="dropdown-item notify-item"
                                    key={i + '-profile-menu'}>
                                    <i className={`${item.icon} mr-1`}></i>
                                    <span>{item.label}</span>
                                </Link>
                            );
                        })}
                        <Link
                            to={{
                                pathname:"/logout",
                                state: { from: this.props.location }
                            }}
                            className="dropdown-item notify-item"
                            key={'logout-profile-menu'}>
                            <i className={`mdi mdi-logout mr-1`}></i>
                            <span>Logout</span>
                        </Link>
                    </div>
                </DropdownMenu>
            </Dropdown>
        )
    }
}

import FacadeConnection from "./FacadeConnection";
class IPBlacklistFacade extends FacadeConnection {
    

    async getIps(dataManager) {
        return await this.get("/ipBlacklist", dataManager)
    }

    async addBan(dataManager, ip, expires) {
        try{
            const { authManager: { sessionID } } = dataManager
            const result = await this.post(`/ipBlacklist/${ip}`, { source: "dashboard", expires }, sessionID)
            return result
        } catch (e){
            throw e
        }
        
    }

    async toggleBanned(dataManager, ip, banned) {
        try{
            const { authManager: { sessionID } } = dataManager
            const result = await this.put(`/ipBlacklist/${ip}/banned/${banned}`, null, sessionID)
            return result
        } catch (e){
            throw e
        }
        
    }

}

export default new IPBlacklistFacade()
import React, { Component, sty } from 'react';
import DatePicker from "react-datepicker"
import { InputGroup, PopoverHeader, Card, UncontrolledPopover, PopoverBody, Row, Container, Col, Button } from "reactstrap"
import { epochToTimezoneString, thousandSeperator } from "../utils/Converter"
import { getEndDate } from "../utils/Dates"
import moment from "moment-timezone"
import FeatureCheck from './FeatureCheck';
import checkFeatures from '../utils/checkFeatures';
import _ from "lodash"
import { Redirect, withRouter } from 'react-router-dom';
import { makeTo } from '../utils/LinkUtils';

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

class DatePickerBar extends Component {

    constructor(props) {
        super(props)
        const { projectManager, data } = this.props
        const { start, end } = data
        const { project } = projectManager

        if (start !== null) {
            var startDate = start
        } else {
            var { startDate } = project
        }

        if (end !== null) {
            var endDate = end
        } else {
            var { endDate } = project
        }

        this.state = {
            startDate,
            endDate,
            selectedStartDate: startDate,
            selectedEndDate: endDate
        }

    }

    updatePeriod = async () => {
        const { data, projectManager } = this.props
        const { project } = projectManager
        const { selectedStartDate, selectedEndDate } = this.state
        this.setState({ startDate: selectedStartDate, endDate: selectedEndDate })
        this.toggle()
        if (selectedStartDate === project.startDate && selectedEndDate === project.endDate) {
            return
        }

        data.start = selectedStartDate
        await timeout(1) //solving race issue
        data.end = selectedEndDate

        await this.props.update()
    }

    cancel() {
        this.setState({
            selectedStartDate: this.state.startDate,
            selectedEndDate: this.state.endDate
        })
        this.toggle()
    }

    reset = async () => {
        const { project } = this.props.projectManager
        let { startDate } = project
        let endDate = getEndDate(project)

        this.setState({
            startDate,
            endDate,
            selectedStartDate: startDate,
            selectedEndDate: endDate,
            popoverOpen: false
        })
        this.props.data.start = null
        await timeout(1)//solving race issue
        this.props.data.end = null

        await this.props.update()
        await this.props.resetDate()
    }

    toggle = () => this.setState({ popoverOpen: !this.state.popoverOpen })

    convertTime(date, timezoneFrom, timezoneTo) {
        const mm = moment.tz(date, timezoneFrom)
        const formattedDate = mm.format("YYYY-MM-DD HH:mm:ss")
        const timeTo = moment.tz(formattedDate, timezoneTo)
        const result = timeTo.toDate()
        return result
    }

    convertTo(date) {
        const timezoneFrom = this.props.projectManager.project.timezone
        const timezoneTo = moment.tz.guess()
        return this.convertTime(date, timezoneFrom, timezoneTo)
    }

    convertFrom(date) {
        const timezoneFrom = moment.tz.guess()
        const timezoneTo = this.props.projectManager.project.timezone
        return this.convertTime(date, timezoneFrom, timezoneTo)
    }

    goBack = () => {
        const { location } = this.props
        const { state } = location
        if (state) {
            this.props.setRedirect(state.from)
        }
    }

    render() {
        if (this.props.redirect) {
            return <Redirect push to={this.props.redirect} />
        }
        const timezone = this.props.projectManager.project.timezone
        const epochToString = epochToTimezoneString(timezone)
        const { project } = this.props.projectManager
        const pStartDate = project.startDate
        const endDate = getEndDate(project)

        const convertedSelectedStartDate = this.convertTo(this.state.selectedStartDate)
        const convertedSelectedEndDate = this.convertTo(this.state.selectedEndDate)

        const datePickerFeature = checkFeatures(this.props, ["date_picker"])
        const datePickerDisabled = !datePickerFeature || this.props.disableDatePicker

        const { location } = this.props
        const { state } = location
        let showBack = false
        if (state) {
            showBack = true
        }

        return (
            this.props.showBar && <>
                <Row style={{ marginBottom: 10 }}>
                    {showBack ?
                        <>
                            <Col xs={6} sm={4}>
                                <div style={{ float: "left" }}>
                                    <Button style={{ float: "left" }} outline color="primary" className="btn-rounded mr-2" onClick={this.goBack}>
                                        <i className="dripicons-arrow-thin-left" />
                                    </Button>
                                    <h4 className="text-center text-truncate">{this.props.title}</h4>
                                </div>
                            </Col>
                        </> :
                        <Col>
                            <h4>{this.props.title}</h4>
                        </Col>
                    }
                    <Col xs={6} sm={8}>
                        <FeatureCheck features={["refresh"]} {...this.props} >
                            <button className="btn btn-primary ml-2" disabled={this.props.loading} style={{ float: "right" }} onClick={() => this.props.update()}>
                                <i className="mdi mdi-autorenew"></i>
                            </button>
                        </FeatureCheck>
                        <span id="date-picker" className={`btn btn-primary input-group-text bg-primary border-primary text-white ${datePickerDisabled && "disabled"}`}
                            style={{ height: 36, float: "right" }} >
                            <i className="mdi mdi-calendar-range font-13" style={{ margin: "auto" }}></i>
                        </span>

                        <p
                            className={`d-none d-sm-block ${this.props.data.periodChanged() && "text-warning"}`}
                            style={{ float: "right", marginRight: 15, marginTop: 7 }}>
                            {this.props.disableDatePicker || this.props.data.fetchHour ? <>
                                {this.props.data.fetchHour ? <p></p> : <p>Showing Full Period</p>}
                            </> :
                                <>
                                    {epochToString(this.state.startDate.getTime())} - {epochToString(this.state.endDate.getTime() - 1)}

                                </>}
                        </p>


                        <UncontrolledPopover
                            id="date-picker-popover"
                            target="date-picker"
                            trigger="legacy"
                            popperPlacement="bottom"
                            isOpen={this.state.popoverOpen}
                            toggle={this.toggle}
                            disabled={datePickerDisabled}
                        >
                            <PopoverHeader>
                                Select period
                    </PopoverHeader>
                            <PopoverBody>
                                <Container fluid>
                                    <Row style={{ marginTop: 10 }}>
                                        <Col md={4}>
                                            <h5>Start</h5>
                                        </Col>
                                        <Col md={8}>
                                            <DatePicker
                                                className="form-control form-control-light"
                                                selectsStart
                                                selected={convertedSelectedStartDate}
                                                startDate={convertedSelectedStartDate}
                                                endDate={convertedSelectedEndDate}
                                                onChange={date => this.setState({ selectedStartDate: this.convertFrom(date) })}
                                                style={{ marginLeft: 20 }}
                                                minDate={this.convertTo(pStartDate)}
                                                maxDate={moment(convertedSelectedEndDate).subtract(1, "ms").toDate()}
                                                showWeekNumbers
                                                popperPlacement="top-end"
                                                dateFormat="dd.MM.yyyy"

                                            />
                                        </Col>


                                    </Row>
                                    <br />
                                    <Row>
                                        <Col md={4}>
                                            <h5>End</h5>

                                        </Col>
                                        <Col md={8}>
                                            <DatePicker
                                                className="form-control form-control-light"
                                                selectsEnd
                                                selected={moment(convertedSelectedEndDate).subtract(1, "ms").toDate()}
                                                startDate={convertedSelectedStartDate}
                                                endDate={moment(convertedSelectedEndDate).subtract(1, "ms").toDate()}
                                                minDate={convertedSelectedStartDate}
                                                maxDate={moment(this.convertTo(endDate)).subtract(1, "ms").toDate()}
                                                onChange={date => this.setState({ selectedEndDate: moment(this.convertFrom(date)).endOf("day").add(1, "ms").toDate() })}
                                                showWeekNumbers
                                                popperPlacement="top-end"
                                                dateFormat="dd.MM.yyyy"
                                            />
                                        </Col>

                                    </Row>
                                    <Row style={{ marginTop: 30 }}>
                                        <Col xs={4} sm={4} md={4}>
                                            <Button className="btn btn-success" disabled={this.props.loading} onClick={() => {
                                                this.updatePeriod()
                                            }}>
                                                Apply
                                </Button>
                                        </Col>
                                        <Col xs={4} sm={4} md={4}>
                                            <Button className="btn btn-warning" disabled={this.props.loading} onClick={() => this.reset()}>
                                                Reset
                                </Button>

                                        </Col>

                                        <Col xs={4} sm={4} md={4}>
                                            <Button className="btn btn-light cancel-change-date"
                                                onClick={() => this.cancel()}>
                                                Cancel
                                    </Button>
                                        </Col>
                                    </Row>
                                </Container>


                            </PopoverBody>
                        </UncontrolledPopover>
                    </Col>

                </Row>

                {/* <br /> */}


            </>
        );
    }
}

export default withRouter(props => <DatePickerBar {...props} />);

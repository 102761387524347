import { getEndDate } from "../utils/Dates"
import ParamState from "../utils/ParamState"


function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default class DataManager {
    authManager
    projectManager

    fetchHour = false

    _tempStart = null
    _tempEnd = null
    _tempGranulize = null
    _tempTablewise = null

    _all = false
    _tempAll = false

    _userCount = null
    _oldUserCount = null

    constructor(authManager, projectManager, ps) {
        this.projectManager = projectManager
        this.authManager = authManager
        this.ps = ps
    }

    get start() {
        const psStart = this.ps.getStart()
        if (this._tempStart !== null) {
            return this._tempStart
        } else if (psStart !== null) {
            return psStart
        } else if (this.projectManager.project) {
            return this.projectManager.project.startDate
        } else {
            return null
        }
    }

    set start(val) {
        this.ps.setStart(val)
    }

    get end() {
        const psEnd = this.ps.getEnd()
        if (this._tempEnd !== null) {
            return this._tempEnd
        } else if (psEnd !== null) {
            return psEnd
        } else if (this.projectManager.project) {
            return getEndDate(this.projectManager.project)
        } else {
            return null
        }
    }

    set end(val) {
        this.ps.setEnd(val)
    }

    get tempAll() {
        return this._tempAll
    }

    set tempAll(val) {
        this._tempAll = val
    }

    get all() {
        if (this._tempAll == null) {
            return this._all
        } else {
            return this._tempAll
        }
    }

    get tempStart() {
        return this._tempStart
    }

    get tempEnd() {
        return this._tempEnd
    }

    set tempStart(val) {
        this._tempStart = val
    }

    set tempEnd(val) {
        this._tempEnd = val
    }

    get tempGranulize() {
        return this._tempGranulize
    }

    set tempGranulize(val) {
        this._tempGranulize = val
    }

    get tempTablewise() {
        return this._tempTablewise
    }

    set tempTablewise(val) {
        this._tempTablewise = val
    }

    get storeID() {
        return this.ps.getStoreid() || null
    }

    periodChanged() {
        return !(this.ps.getStart() == null && this.ps.getEnd() == null && this._all == false)
    }

    clearTemps() {
        this._tempAll = false
        this._tempEnd = null
        this._tempStart = null
        this._tempGranulize = null
        this._tempTablewise = null
    }

    async clear() {
        this.start = null
        await timeout(1) //solving race issue
        this.end = null
    }

    async runCB(cb) {
        return await cb(this)
    }


}
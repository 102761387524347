import Project from "./Project"

export default class Campaign {
    id
    name
    projects


    constructor(id, name, projects = null) {
        if (typeof id === "object") {
            const cDTO = id
            this.id = cDTO.campaignID
            this.name = cDTO.name
            this.projects = cDTO.projects.map(project => new Project(project))
        } else {
            this.id = id
            this.name = name
            this.projects = projects
        }

    }

    _getDate(field, comparator) {
        let result = null
        this.projects.forEach((project, i) => {
            const date = project[field]
            if (i == 0) {
                result = date
                return
            }

            if (comparator(date, result)) {
                result = date
            }

        })

        return result
    }

    getStart() {
        return this._getDate("startDate", (startDate, result) => startDate < result)
    }

    getEnd() {
        return this._getDate("endDate", (startDate, result) => startDate > result)
    }

    countStat(statField) {
        let result = 0
        for (const project of this.projects) {
            // console.log(project)
            result += project.projectStats[statField]
        }

        return result

    }

    countProjectsLive() {
        let result = 0
        for (const project of this.projects) {
            if (project.isLive()) {
                result += 1
            }
        }
        return result
    }
}
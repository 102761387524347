import FacadeConnection from "./FacadeConnection";
import Company from "../class/Company";

class CompanyFacade extends FacadeConnection {
    constructor() {
        super()
    }
    async getCompanies(sessionID) {
        const companies = await this.get("/companies", sessionID)
        return companies.map(company => new Company(company))
    }

    async getCompaniesByProjectID(sessionID, projectID) {
        const companies = await this.get(`/companies?projectID=${projectID}`, sessionID)
        return companies.map(company => new Company(company))
    }

}

export default new CompanyFacade()
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';
import BarChartBrite from '../components/BarChartBrite';
import Loading from '../components/Loading';
import ProjectDataPage from './ProjectDataPage';
import _ from "lodash"
import ChannelTable from './ChannelTable';
import FeatureCheck from '../components/FeatureCheck';
import SelectRandomResolver from '../resolvers/SelectRandomResolver';
import { tableThousandSeperator, tzTableDateFormatter } from '../utils/Converter';
import SelectRandomForm from './SelectRandomForm';

class SelectRandom extends ProjectDataPage {
    constructor(props) {
        super("Select random user", [
            SelectRandomResolver,
        ])

    }
   


    render() {
        const supported = this.props.states.supported

      

        return (
            <>
                {this.props.loading || this.state.loading ? <Loading /> :
                    <>
                        <FeatureCheck features={["user", "selectrandom"]} {...this.props} >
                        <SelectRandomForm supported={supported} {...this.props}/>
                        </FeatureCheck>
                        <br />


                    </>
                }
            </>);
    }
}

export default withRouter(props => <SelectRandom {...props} />);
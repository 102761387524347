import FacadeConnection from "../FacadeConnection";


class PermissionFacade extends FacadeConnection {
    async getUserPermissions(dataManager, userID) {
        return await this.get(`/project/permission/user/${userID}`, dataManager)
    }

    async revoke(dataManager, id){
        return await this.delete(`/project/permission/${id}`, dataManager)
    }
}

export default new PermissionFacade()
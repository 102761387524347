import React from "react"
import ProjectFacade from "../facade/ProjectFacade"
import {
    noaccess,
    overview,
    engagement,
    returning,
    voucher,
    users,
    userdata,
    highscore,
    channel,
    forms,
    adview,
    changed,
    age,
    selectrandom,
    issueprize,
    stores,
    realtimemap,
    twofactor
} from "../utils/menuItems"

const ANALYTICS_PAGES = [
    overview,
    engagement,
    voucher,
    returning,
    channel,
    adview,
    changed,
    age,
    realtimemap,
    stores,
]

const USERDATA_PAGES = [
    users,
    userdata,
    highscore,
    forms,
    issueprize,
    selectrandom,
    twofactor
]

export default class ProjectManager {
    project
    company
    campaign
    accesses
    paramState

    constructor(paramState) {
        this.project = null
        this.company = null
        this.campaign = null
        this.accesses = []
        this.paramState = paramState
    }

    chooseProject = (project, campaign, brand, accesses) => {
        this.project = project
        this.campaign = campaign
        this.company = brand
        this.accesses = accesses
        this.paramState.setProjectTimezone(project.timezone)
    }

    isProjectChosen = () => {
        return this.project !== null
    }

    getMenuItems = () => {
        let result = []

        let accesses = {}

        if (this.accesses !== undefined) {
            if (this.accesses.length == 0||(this.accesses.status&&this.accesses.status == '500')) {
                result.push(
                    noaccess
                )
                return result
            } else {
                for (const access of this.accesses) {
                    accesses[access.name] = true
                }
            }

        }

        if (accesses["analytics"]) {
            var analyticsPages = ANALYTICS_PAGES.map(({ name }) => name.toLowerCase().replace(' ',''))
        }

        if (accesses["userdata"]) {
            var userdataPages = USERDATA_PAGES.map(({ name }) => name.toLowerCase().replace(' ',''))
        }

        const { features } = this.project
        if (features) {
            const { pages } = features
            if (pages) {
                for (const page of pages) {
                    if (accesses["analytics"]) {
                        const index = analyticsPages.indexOf(page)
                        if (index !== -1) {
                            result.push(ANALYTICS_PAGES[index])
                        }
                    }

                    if (accesses["userdata"]) {
                        const index = userdataPages.indexOf(page)
                        if (index !== -1) {
                            result.push(USERDATA_PAGES[index])
                        }
                    }
                }
            }
        }


        if (result.length === 0) {
            result.push(
                noaccess
            )
        }

        //removing duplicates
        result = [...new Set(result)];

        return result

    }

}


import React, { Component } from 'react';


export default class ProjectDataPage extends Component {

    constructor(title, resolvers) {
        super()
        this.state = {
            resolvers,
            title,
            loading: true
        }
    }

    componentDidMount = async () => {
        await this.props.setRedirect(null)
        await this.props.setLoadingState(true)
        this.props.setTitle(this.state.title)
        await this.props.setResolvers(this.state.resolvers)
        await this.props.fetchData()
        try {
            if(this.componentDidMountExtended){
                await this.componentDidMountExtended()
            }
        } catch (e) { }
        await this.setState({ loading: false })

    }

    componentWillMount = async () => {
        // if(this.props.resetDate){
        //     await this.props.resetDate(true)
        // }
        await this.setState({ loading: true })
    }

}
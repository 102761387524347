import React, { Component } from "react"
import { Redirect, withRouter } from "react-router-dom"
import Campaign from "../class/Campaign"
import Project from "../class/Project"
import Company from "../class/Company"
import { CardBody, CardImg, Card, Container, Row, Col } from "reactstrap"
import ImageFacade from "../facade/ImageFacade"
import CampaignCard from "../components/CampaignCard"
import ProjectStatistics from "../class/ProjectStatistics"
import Loading from "../components/Loading"
import CompanyFacade from "../facade/CompanyFacade"
import ProjectFacade from "../facade/ProjectFacade"

export default withRouter(props => <Campaigns {...props} />)

class Campaigns extends Component {
    constructor(props) {
        super(props)
        let autoload = false

        const { state } = this.props.location
        if (state !== undefined) {
            autoload = state.autoload
        }

        this.state = {
            autoload,
            redirect: null,
            companies: null
        }
    }

    componentDidMount = async () => {
        const { auth } = this.props
        if (this.state.autoload) {
            //load project into memory
            const { sessionID } = auth
            const params = new URLSearchParams(this.props.location.state.from.search)
            var projectID = params.get("p")

            var companies = await CompanyFacade.getCompaniesByProjectID(sessionID, projectID)
            const company = companies[0]
            const campaign = company.campaigns[0]
            const project = campaign.projects[0]

            await this.projectSelected(project, campaign, company)
        } else {
            try {
                const companies = await CompanyFacade.getCompanies(this.props.auth.sessionID)


                for(let i = 0; i < companies.length; i++){
                    companies[i].campaigns = companies[i].campaigns.sort((f1,f2)=>{
                        return f1.getStart()>f2.getStart() ? -1 : 1

                    });
                }



                this.setState({
                    companies
                })
            } catch (e) {
                this.setState({
                    logout: true
                })
            }

        }
    }


    projectSelected = async (project, campaign, brand) => {
        const { sessionID } = this.props.auth

        const accesses = await ProjectFacade.getAccess(sessionID, project.id)
        this.props.projectManager.chooseProject(project, campaign, brand, accesses)

        if (this.state.autoload) {
            this.setState({ redirect: this.props.location.state.from })
        } else {
            this.setState({ redirect: `/stats?p=${project.id}` })
        }
    }




    render() {

        if (this.state.logout) {
            return <Redirect to={{
                pathname: "/logout"
            }} />
        }

        if (this.state.redirect) {
            return <Redirect push to={this.state.redirect} />
        }

        return (
            <>

                <div style={{ height: "100%", minHeight: window.innerHeight, padding: 15, paddingTop: 20 }}>
                    {!this.state.autoload && <h3>Campaigns</h3>}
                    {this.state.companies === null ? <Loading /> :
                        <>
                            <br />
                            {this.state.companies.length === 0 &&
                                <>
                                    <div className="text-center">
                                        <h3>You do not have access to any campaigns yet!</h3>
                                        <h6>Please request access from your super-user to proceed!</h6>
                                    </div>
                                </>
                            }
                            {

                                this.state.companies.map(({ id, name, campaigns }, index) => {

                                    return (
                                        <>
                                            {index !== 0 &&
                                                <>
                                                    <hr />
                                                    <br />
                                                </>

                                            }
                                            <div style={{ margin: "auto", maxWidth: 400, textAlign: "center" }}>
                                                <table style={{ display: "inline-block" }} >
                                                    <tr>
                                                        <td >
                                                            <img src={ImageFacade.companyImage(id)} alt="" height="42" className="shadow-sm" style={{ marginRight: 10 }} />

                                                        </td>
                                                        <td>
                                                            <h2 style={{ textAlign: "center" }}>{name}</h2>

                                                        </td>
                                                    </tr>
                                                </table>

                                            </div>
                                            <Row style={{ marginBottom: 60, marginTop: 40 }}>
                                                {
                                                    campaigns.map((campaign) => {
                                                        return (
                                                            <CampaignCard campaign={campaign} projectSelected={this.projectSelected} {...this.props} />
                                                        )
                                                    })
                                                }
                                            </Row>

                                        </>
                                    )
                                })
                            }

                        </>}

                </div>

            </>

        )
    }
}
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button,Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';
import BarChartBrite from '../components/BarChartBrite';
import Loading from '../components/Loading';
import ProjectDataPage from './ProjectDataPage';
import _ from "lodash"
import ChannelTable from './ChannelTable';
import FeatureCheck from '../components/FeatureCheck';
import FormsResolver from '../resolvers/FormsResolver';
import GenericTable from '../components/GenericTable';
import { tableThousandSeperator, tzTableDateFormatter } from '../utils/Converter';


class Form extends Component {
    state = {
        input: {
            search: ""
        }
    }

    handleKeyPressed = (e) => {
        let input = Object.assign({}, this.state.input)
        input[e.target.name] = e.target.value
        this.setState({ input })
    }

    componentDidMount () {
        const script = document.createElement("script");
    
        script.src = "https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.13.1/xlsx.full.min.js";
        script.async = true;
    
        document.body.appendChild(script);
    }

    render() {
        const tableDateFormatter = tzTableDateFormatter(this.props.data.projectManager.project.timezone)

        let data = this.props.formData

        
        let { search } = this.state.input
        if (search !== null) {
            data = data.filter(entry => {
                search = search.toLowerCase()
                const keys = Object.keys(entry)
                for (const key of keys) {
                    if (entry[key].toLowerCase().search(search) !== -1) return true
                }
                return false
            })
        }


        return (
            <>

                <Card>

                    <CardBody>
                        <FeatureCheck features={["search"]} {...this.props}>
                        <div className="app-search" style={{ maxWidth: (window.innerWidth * 0.58), float: "right" }}>
                                <form onSubmit={e => e.preventDefault()}>
                                    <div className="input-group" style={{ height: "48px", marginTop: -19 }}>
                                        <input type="text" className="form-control" placeholder="Search..."
                                            onChange={this.handleKeyPressed}
                                            name="search"
                                            id="search"
                                            value={this.state.input.search}
                                            style={{ height: "100%" }}
                                        />
                                        <span className="dripicons-search" style={{ marginTop: 3 }}></span>
                                    </div>
                                </form>
                            </div>
                        </FeatureCheck>
                        <Button disabled={this.props.loading} size="lg" color={"light"} onClick={() => {
                            let name = this.props.formSimpleName;

                            function convertToCSV(objArray) {
                                var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
                                var str = '';
                            
                                for (var i = 0; i < array.length; i++) {
                                    var line = '';
                                    for (var index in array[i]) {
                                        if (line != '') line += ','
                            
                                        line += '"'+array[i][index]+'"';
                                    }
                            
                                    str += line + '\r\n';
                                }
                            
                                return str;
                            }
                            function capitalizeFirstLetter(string) {
                                return string.charAt(0).toUpperCase() + string.slice(1);
                              }
                            function exportCSVFile(items, fileTitle) {

                                if(items[0]){

                                    let headers = {};
                                    for(let k in items[0]){
                                        headers[k] = capitalizeFirstLetter(k)
                                    }

                                        items.unshift(headers);
                                }


                            
                                // Convert Object to JSON
                                var jsonObject = JSON.stringify(items);
                            
                                var csv = convertToCSV(jsonObject);
                            
                                var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
                            
                                var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                                if (navigator.msSaveBlob) { // IE 10+
                                    navigator.msSaveBlob(blob, exportedFilenmae);
                                } else {
                                    var link = document.createElement("a");
                                    if (link.download !== undefined) { // feature detection
                                        // Browsers that support HTML5 download attribute
                                        var url = URL.createObjectURL(blob);
                                        link.setAttribute("href", url);
                                        link.setAttribute("download", exportedFilenmae);
                                        link.style.visibility = 'hidden';
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    }
                                }
                            }



                            exportCSVFile( data, name + ' - Download - '+new Date().toDateString());
                        }}>
                        <i className="dripicons-download" style={{ fontSize: 18,marginRight:5 }}></i> 
                        CSV
                    </Button>
                    <Button disabled={this.props.loading} size="lg" color={"light"} style={{marginLeft:10}} onClick={() => {
                            let name = this.props.formSimpleName;

                             var ws = window.XLSX.utils.json_to_sheet(data);
                             var wb =  window.XLSX.utils.book_new();
                             window.XLSX.utils.book_append_sheet(wb, ws, "People");
                             window.XLSX.writeFile(wb,name + ' - Download - '+new Date().toDateString()+'.xlsx');


                        }}>
                        <i className="dripicons-download" style={{ fontSize: 15,marginRight:5 }}></i> 
                        Excel
                    </Button>
                        <h3>{this.props.formSimpleName}</h3>


                        <br />
                        <GenericTable
                            {...this.props}
                            pagination
                            tableData={data}
                            sizePerPage={10}
                            columnOptions={{
                                "color": {
                                    hide: true
                                },
                                "filled": {
                                    formatter: tableDateFormatter
                                }
                            }}

                        />
                    </CardBody>
                </Card>

            </>);
    }
}

export default withRouter(props => <Form {...props} />);
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row, Button ,Spinner} from 'reactstrap';
import BarChartBrite from '../components/BarChartBrite';
import Loading from '../components/Loading';
import ProjectDataPage from './ProjectDataPage';
import _ from "lodash"
import ChannelTable from './ChannelTable';
import FeatureCheck from '../components/FeatureCheck';
import FormsResolver from '../resolvers/FormsResolver';
import GenericTable from '../components/GenericTable';
import { tableThousandSeperator, tzTableDateFormatter } from '../utils/Converter';
import IssuePrizeFacade from '../facade/project/IssuePrizeFacade'
import ParamState from "../utils/ParamState"

class IssuePrizeForm extends Component {
    state = {}

    prizeSelect = (e) => {
        this.setState({ prize: e.target.value });

    }
    messageSelect = (e) => {
        this.setState({ message: e.target.value });
    }
    numberSelect = (e) => {
        this.setState({ numbers: e.target.value });
    }

    sendPrizes = (e) => {
        const { data } = this.props

        if (!this.state.prize || !this.state.message || !this.state.numbers || this.state.loading) {

            if(!this.state.prize){
                this.props.alert.error("Prize type not selected!");
                return false;
            } else if(!this.state.message){
                this.props.alert.error("Message not selected!");
                return false;

            } else if(!this.state.numbers){
                this.props.alert.error("No users defined");
                return false;

            }


            return false;
        }

        this.setState({ loading: true, users:null});
        




        let numbers = this.state.numbers;//.replace(/[()\-\+m ]/g,'').match(/[0-9]{1,}/g)
        

        IssuePrizeFacade.issue(data, this.state.prize, this.state.message, JSON.stringify(numbers)).then((json) => {
        this.setState({ loading: false });



        document.getElementById('drop').value = "";
        document.getElementById('prize').value = "";
        document.getElementById('message').value = "";

            this.setState({ users: json, prize: null,message:null,numbers:null });
        }).catch(() => {
          this.setState({ loading: false });

        })



    }


    componentDidMount(){
        let input = document.getElementById('drop')
        let _ = this;
        input.ondrop = (e)=>{
            e.preventDefault();
            let dt = e.dataTransfer
            let files = dt.files
            const reader = new FileReader();

            reader.onload = function (event) {
                let numbers = event.target.result.match(/[0-9]{1,}/g);

                for(let i = 0; i < numbers.length;i++){
                    input.value += numbers[i]+',\n'
                }

                _.setState({ numbers: input.value });

            };


            for(let k = 0; k < files.length; k++){
                reader.readAsText(files[k])
            }


        }
      
    }

    render() {
        let supported = this.props.supported

        let optionsPrize = [];
        for(let k in supported.prize){
            let name =  supported.prize[k];
            optionsPrize.push(<option value={k}>{name}</option>);
        }


        let optionsMessages = [
            <option value="nomessage">No message</option>
        ];
        for(let k in supported.message){
            let name =  supported.message[k];
            optionsMessages.push(<option value={k}>{name}</option>);
        }

        const paramState = new ParamState(() => this.props, {
            m: null
        })

        if(paramState.getM()){
            this.state.numbers = paramState.getM()
        }
    
        

        return (
            <>
                <Card>
                    <CardBody>
                    <label>Select prize to issue</label>
                        <select class="custom-select" onChange={this.prizeSelect}  id="prize">
                            <option value="">Select prize</option>

                            {
                        optionsPrize
                            }
                        </select>
                        <br />
                        <br />

                        <label>Select message</label>
                        <select class="custom-select" onChange={this.messageSelect}  id="message">
                            <option value="">Select message</option>

                            {
                        optionsMessages
                            }
                        </select>
                        <br />
                        <br />
                        

                        <label>Numbers</label>
                        <textarea class="form-control" id="drop" placeholder="List numbers here" onChange={this.numberSelect}>
                            {paramState.getM()}

                        </textarea>
                        <br />
                        <br />
                        

                        <Button onClick={this.sendPrizes} >Issue prizes</Button>
                                {this.state.loading &&
                                    <Spinner
                                        className="text-primary m-2"
                                        size={"lg"}
                                        style={{ margin: "auto", height: 15, width: 15 }} />
                                }
                    </CardBody>
                </Card>
                {this.state.users&&this.state.users.length > 0 &&
                    <Card >
                        <CardBody>
                        <GenericTable
                            {...this.props}
                            pagination
                            tableData={this.state.users}
                            sizePerPage={10}
                            columnOptions={{
                                "game_id": {
                                    text: 'Game id'
                                },
                            }}

                        />
                        </CardBody>
                    </Card>
                }




            </>);
    }
}

export default withRouter(props => <IssuePrizeForm {...props} />);
import React, { Component } from 'react';
import { Card, CardTitle, CardBody, Row, Col, Button, Spinner, Modal, ModalHeader, ModalBody, Label, FormGroup, Badge, ModalFooter } from 'reactstrap';
import GenericTable from '../components/GenericTable';
import SessionFacade from '../facade/project/SessionFacade';
import { withRouter, Redirect,Link } from 'react-router-dom';
import Loading from '../components/Loading';
import _, { lte } from "lodash"
import { makeTo } from '../utils/LinkUtils';
import { tzTableDateFormatter, dateFormatter } from '../utils/Converter';
import FeatureCheck from '../components/FeatureCheck';
import ProjectDataPage from './ProjectDataPage';


import QuestionDataResolver from '../resolvers/QuestionDataResolver';

class UserData extends ProjectDataPage {

    constructor(props) {
        super("User Data", [
            QuestionDataResolver

        ])


    }
    render() {

        let { question } = this.props.states
        let questions =[];


        for(let k in question){

            let data = [];


            let total = 0;            

            for(let x in question[k]){
                data.push({
                    "option": x,
                    "count": question[k][x]
                })
                total+= question[k][x];
            }

            for(let i = 0; i < data.length;i++){
                data[i].percent = ((data[i].count/total)*100).toFixed(1);
            }


            questions.push({
                question: k,
                data
            })
        }

        return <>
            <FeatureCheck features={["user", "question"]} {...this.props} >

                {this.props.loading || this.state.loading ? <Loading /> :
                <>
            <Row>

                        {questions.map(({question,data}) => {
                            return <>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                        <Card >
                                            <CardBody>
                                                <div className="text-center">
                                                    <h4>{question}</h4>
                                                    <br/>
                                                    <GenericTable {...this.props}
                                                    tableData={data}
                                                    sort
                                                    columnOptions={{
                                                        "percent": {
                                    formatter: (cell) => `${cell}%`,

                                                        }
                                                    }}></GenericTable>
                                                    
                                                </div>
                                            </CardBody>
                                        </Card>
                                        </Col>
                            
                            
                            
                            
                            </>
                        })}
                        </Row>

                </>
                }

            </FeatureCheck>
        </>
    }
}


export default withRouter(props => <UserData {...props} />);

import React from 'react';

function AccessCheck(props) {
    const { projectManager } = props
    let { accesses } = projectManager
    accesses = accesses.map(({ name }) => name)
    let disabled = false

    if (props.accesses && accesses) {
        for (const accessToCheck of props.accesses) {
            if (accesses.indexOf(accessToCheck) === -1) {
                disabled = true
            }
        }
    } else {
        disabled = true
    }

    return (
        <>
            {disabled ? null : props.children}
        </>
    );
}

export default AccessCheck;
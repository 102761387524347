
class ImageFacade {

    campaignImage(campaign, id = false) {
        let imagePath = false;
        for(let i = 0, project; project = campaign.projects[i]; i++){
            if(project.url&&project.icon&&typeof project.icon == 'string'){
                imagePath = project.url + project.icon;
            } else if(project.url&&project.icon){
                imagePath = project.url + '/global/icon/apple-icon.png';
            }

            if(id && project.id == id){
                break;
            }




        }

        if(imagePath) return imagePath;
        return require(`../assets/images/dev/campaigns/bm.png`)
    }

    companyImage(id) {
        try {
            return require(`../assets/images/dev/companies/${id}.png`)
        } catch (e) {
            return null
        }
    }

    countryImage(country) {
        try {
            return require(`../assets/images/flags/${country}.png`)
        } catch (e) {
            return null
        }
    }

    userImage(email) {
        try {
            return require(`../assets/images/users/${email}.jpg`)
        } catch (e) {
            return require(`../assets/images/users/user.png`)
        }
    }

    resetView() {
        try {
            return require(`../assets/images/reset-view.png`)
        } catch (e) {
            return null
        }
    }

}

export default new ImageFacade()
// import moment from "moment-timezone"
import tzMoment from "../utils/tzMoment"

export const getEndDate = (project) => {
    let { endDate } = project
    const moment = tzMoment(null, project.timezone)
    endDate = moment(endDate)
    const now = moment()
    return now.diff(endDate) > 0 ? endDate.toDate() : now.toDate()
}



export default { getEndDate }
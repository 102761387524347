

import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, Container, Nav, NavItem, ButtonGroup, Button } from 'reactstrap';
import Loading from '../components/Loading';
import AdViewCountResolver from '../resolvers/AdViewCountResolver';
import ProjectDataPage from './ProjectDataPage';
import _ from "lodash"
import { thousandSeperator } from '../utils/Converter';
import Chart from 'react-apexcharts';
import { withRouter } from 'react-router-dom';
import FeatureCheck from '../components/FeatureCheck';
import jsVectorMap from 'jsvectormap'
import 'jsvectormap/dist/maps/world.js'
class RealtimeMap extends ProjectDataPage {

    constructor() {
        super("Realtime Map", [
            // AdViewCountResolver
        ])

        this.state = Object.assign({}, this.state)

    }


    render() {
        var mapElm = document.getElementById('live-map');
        if (!this.map && mapElm) {
            this.map = new jsVectorMap({
                selector: '#live-map',
                map: 'world',
            })


        }
        return (
            <>
                <Card >
                    <CardBody>
                        {/* <div style={{ paddingTop: '100%', position: 'relative' }} >
                            <div style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}> */}
                                <div id="live-map" style={{ height: "900px",width: "1200px" }}></div>

                            {/* </div>
                        </div> */}
                    </CardBody>
                </Card>
                {/* <Row style={{ width: "100%" }}>
                    <Col className="text-center ">

                        <div id="widgetIframe"><iframe width="100%" height="350" src="https://track.mgame.nu/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=UserCountryMap&actionToWidgetize=realtimeMap&idSite=3&period=day&date=yesterday&disableLink=1&widget=1&token_auth=7d1b9c35d205f2750f18dcf809a7dcde" scrolling="yes" frameborder="0" marginheight="0" marginwidth="0"></iframe></div>

                    </Col>
                </Row> */}
                {/* {this.props.loading || this.state.loading ? <Loading /> : <>
                    <FeatureCheck features={["ad", "ad_video"]} checkIfEmpty={videoAds}  {...this.props} >
                        <>
                            <Row style={{ width: "100%" }}>
                                <Col className="text-center ">
                                    <h3><i className={`dripicons-monitor widget-icon bg-info-lighten text-info`}></i> Video Ads</h3>

                                </Col>
                            </Row>
                            <br />
                            <Row style={{ width: "100%" }}>
                                {videoAds.map(({ name, total_views, seconds }) => {
                                    const secondNumbers = Object.keys(seconds).map(sec => Number(sec))
                                    var values = Object.values(seconds).map(sec => Number(sec))

                                    var totalSeconds = values.reduce((prev, cur, i) => prev + (secondNumbers[i] * cur), 0)

                                    const keys = Object.keys(seconds).map(sec => `${sec} second${sec == 1 ? "" : "s"}`)
                                    var options = {
                                        chart: {
                                            sparkline: {
                                                enabled: true,
                                            },
                                        },
                                        stroke: {
                                            width: 2,
                                            curve: 'smooth',
                                        },
                                        xaxis: {
                                            type: 'string',
                                            categories: keys,
                                            tooltip: {
                                                enabled: false,
                                            },
                                            axisBorder: {
                                                show: false,
                                            },
                                        },
                                        yaxis: {
                                            min: 0,
                                        },
                                        colors: ['#8492fa'],
                                    };
                                    var series = [{ name: "Views", data: values }]

                                    return (
                                        <>
                                            <Col sm={12} lg={6}>
                                                <Card>
                                                    <CardBody className="p-0">
                                                        <div className="p-2 text-center">
                                                            <h4><i className={`dripicons-monitor widget-icon bg-info-lighten text-info`} />  {_.capitalize(name)}</h4>
                                                            <br />
                                                            <h2>{thousandSeperator(total_views)} views</h2>
                                                        </div>
                                                        <h3 className="text-center">{_.floor(totalSeconds / 86400)} days {_.floor((totalSeconds % 86400) / 3600)} hours {_.floor((totalSeconds % 3600) / 60)} minutes</h3>
                                                        <Chart className="apex-charts" options={options} series={series} type={"area"} height={172} />
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </>

                                    )
                                })}
                            </Row>
                        </>
                    </FeatureCheck>
                    <FeatureCheck features={["ad", "ad_regular"]} checkIfEmpty={regularAds} {...this.props} >
                        <>
                            <br />
                            <Row style={{ width: "100%" }}>
                                <Col className="text-center ">
                                    <h3><i className={`dripicons-preview widget-icon bg-danger-lighten text-danger`}></i> Regular Ads</h3>

                                </Col>
                            </Row>
                            <br />
                            <Row style={{ width: "100%" }}>
                                {regularAds.map(({ name, total_views }) => {
                                    let type = name.indexOf('_click') != -1 ? 'clicks' : 'views';
                                    name = name.replace('_click','');
                                    let icon = type == 'views' ? 'preview': 'web';

                                    return (
                                        <>
                                            <Col sm={12} lg={6}>
                                                <Card>
                                                    <CardBody className="p-0">
                                                        <div className="p-2 text-center">
                                                            <h4 ><i className={'dripicons-'+icon+` widget-icon bg-danger-lighten text-danger`} />  {_.capitalize(name)}</h4>
                                                            <br />
                                                            <h2>{thousandSeperator(total_views)} {type}</h2>
                                                            <br />

                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </>

                                    )
                                })}
                            </Row>
                        </>
                    </FeatureCheck>

                </>
                } */}


            </>
        )
    }
}

export default withRouter(props => <RealtimeMap {...props} />);
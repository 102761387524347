import React, { Component } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap/lib';
import { Donut, Legend } from 'britecharts-react';

const ALTERNATIVE_COLORS = ['#0acf97', '#6c757d', '#fa5c7c', '#ffbc00', '#39afd1', '#E3E3E3']

class BarChartBrite extends Component {

    render() {
        var data = this.props.data
        const colors = data.map(({ color }, i) => color ? color : ALTERNATIVE_COLORS[i])

        return (
            <>
                <h4 className="header-title mb-1">{this.props.title}</h4>

                <Row>
                    <Col style={{marginLeft: window.innerWidth < 420 ? -40: 0}} >
                    
                        <Donut
                            data={data}
                            // height={300}
                            // width={200}
                            
                            externalRadius={window.innerWidth / (window.innerWidth / 120) }
                            internalRadius={window.innerWidth / (window.innerWidth / 80)}
                            colorSchema={colors}
                            isAnimated={false}
                            hasFixedHighlightedSlice={true}
                            

                        />

                    </Col>
                    <Col style={{marginLeft: window.innerWidth < 420 ? -40: 0}} >
                        <Legend
                            data={data}
                            height={200}
                            width={250}
                            numberFormat={''}
                            colorSchema={colors}
                            margin={{ top: 10, bottom: 10, left: 0, right: 30 }}
                        />

                    </Col>
                </Row>

            </>
        );
    }
}

export default BarChartBrite;
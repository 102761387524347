import React, { Component } from "react";
import { Card, CardHeader, CardBody, Container, Row, Col, Dropdown, DropdownMenu, DropdownToggle, CardImg, CardTitle, Button } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import MetisMenu from 'metismenujs/dist/metismenujs';
import ImageFacade from "../facade/ImageFacade"


import circlek from "../assets/images/small/circle-k.jpg"
import CampaignIcon from "./CampaignIcon";
import { makeTo } from "../utils/LinkUtils";

const BUTTON_NAME = "Change"
const BUTTON_LINK = "/campaigns"

class Menu extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.updateMenuItems()
    }

    render() {
        const { projectManager } = this.props
        // console.log(projectManager)
        const { project, campaign, company } = projectManager
        const { country } = project
        const { menuItems } = this.props
        return (
            <>
                <Row className="d-sm-block d-xs-block d-md-none">
                    <Col md={12}>
                        <Card style={{ width: "100%", marginBottom: 0, minHeight: 110, maxHeight: 110 }}>
                            <CardBody style={{ padding: 0, paddingRight: 10 }}>
                                <Row >
                                    <Col xs={4} sm={4} >
                                        <CampaignIcon {...this.props} />

                                    </Col>
                                    <Col xs={8} sm={8} style={{ paddingLeft: 0, paddingTop: 23, paddingBottom: 10, textAlign: "center" }}>
                                        <CardTitle tag="h5" >{campaign.name} - {project.name}</CardTitle>
                                        <Link to={BUTTON_LINK}>
                                            <Button className="btn-rounded btn" style={{ backgroundColor: "#38469b", margin: "auto", width: "100%", minWidth: 80, maxWidth: 150, }}>{BUTTON_NAME}</Button>
                                        </Link>

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>

                <div className="d-none d-md-block" >

                    {/* Navigation */}
                    <Card style={{ width: "100%", height: "100%", minHeight: window.innerHeight, marginBottom: 0, maxWidth: 300, minWidth: 160 }}>
                        <CampaignIcon style={{ margin: "auto" }} {...this.props} />
                        <CardTitle tag="h5" style={{ padding: 20, marginTop: 60, marginBottom: 0, textAlign: "center" }}>{campaign.name} - {project.name}</CardTitle>
                        <Link to={BUTTON_LINK} style={{ margin: "auto" }}>
                            <Button className="btn-rounded btn" style={{ backgroundColor: "#38469b", margin: "auto", width: "100%", minWidth: 120, maxWidth: 150, }}>{BUTTON_NAME}</Button>
                        </Link>

                        <CardBody style={{ padding: 0, marginTop: 20 }}>
                            {/* <hr /> */}
                            <ul className="metismenu side-nav" style={{ color: "#19191b" }}>
                                <li href="/" className="side-nav-title side-nav-item" style={{ color: "#19191b" }}>
                                    Navigation
                                </li>
                                {
                                    menuItems.map(({ name, path, icon }) => {
                                        const { pathname } = this.props.location
                                        // const selected = pathname.indexOf(path) !== -1
                                        const selected = pathname.split("?")[0] == path 
                                        const color = selected ? "#38469b" : "#19191b"
                                        return (
                                            <Link to={makeTo(path, this.props.location)}>
                                                <li className="side-nav-item" >
                                                    <a className="side-sub-nav-link side-nav-link mm-collapsed" style={{ paddingRight: 0, marginRight: 0 }}>
                                                        <i className={icon} style={{ color }}></i>
                                                        <span style={{ color }}>{name}</span>
                                                    </a>
                                                </li>
                                            </Link>
                                        )
                                    })
                                }


                            </ul>
                        </CardBody>
                    </Card>
                </div>
            </>
        )
    }
}

export default withRouter(props => <Menu {...props} />);

import React, { Component } from "react"
import { CardBody, CardImg, Card, Container, Row, Col, Badge, CardFooter, CardTitle, Progress } from "reactstrap"
import { epochToTimezoneString } from "../utils/Converter"
import ImageFacade from "../facade/ImageFacade"
import { withRouter } from "react-router-dom"

class ProjectCard extends Component {

    constructor(props) {
        super(props)
        this.projectRef = React.createRef()
    }



    render() {
        const { project } = this.props

        let { id, name, startDate, endDate, country } = project
        const now = new Date().getTime()

        const period = endDate - startDate
        const duration = now - startDate
        const percent = (duration / period) * 100

        const completed = percent >= 100

        const projectChosen = this.props.projectManager.project
        let isChosen = false

        if (projectChosen) {

            isChosen = projectChosen.id == id
        }

        if (isChosen) {
            var ref = React.createElement()
        }

        const epochToString = epochToTimezoneString(project.timezone)


        return (
            <Col xs={12} sm={6} md={4} style={{marginTop: 20}} >

                <Card ref={this.projectRef} id={project.id} className={`with-icon mb-1 bg-white rounded background-color standard-shadow ${isChosen && "border border-success"}`}
                    style={{ padding: 0 }}
                    onClick={() => this.props.projectSelected(project, this.props.campaign, this.props.brand)}>
                    <div
                        style={{
                            height: 50,
                            width: 50,
                            margin: "-20px auto 0",
                            borderRadius: "10px"
                        }}>
                        <img className="card-icon rounded-circle shadow mb-5 bg-white" style={{ width: "100%", border: "2px solid white" }} src={ImageFacade.countryImage(country)} />
                        
                    </div>
                    <CardBody style={{ paddingTop: 10 }}>

                        <h4 style={{ textAlign: "center" }}>{name}</h4>
                        <div style={{ textAlign: "center" }}>
                            <a style={{ left: 0, color: "#cbcbcb" }}>ID: {id}</a>
                            <br />
                            <hr />
                            <a style={{ color: "#cbcbcb", float: "left" }}>{epochToString(startDate)}</a>
                            <a style={{ color: "#cbcbcb", float: "right" }}>{epochToString(endDate.getTime() - 1)}</a>
                            <br /><br />
                            <Progress animated={!completed} color={completed ? "success" : "warning"} value={percent} >{percent >= 12 && (completed ? "Completed ✓" : "Live")}</Progress>

                        </div>
                    </CardBody>
                </Card>


            </Col>
        )
    }
}

export default withRouter(props => <ProjectCard {...props} />)
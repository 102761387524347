import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import BarChart from '../components/BarChart';
import FeatureCheck from '../components/FeatureCheck';
import Loading from '../components/Loading';
import Tab from '../components/Tab';
import UserAgeCountResolver from '../resolvers/UserAgeCountResolver';
import checkFeatures from '../utils/checkFeatures';
import AgeTable from './AgeTable';
import ProjectDataPage from './ProjectDataPage';

class Age extends ProjectDataPage {
    constructor(props) {
        super("Age", [
            UserAgeCountResolver
        ])


    }

    render() {
        const isLoading = this.props.loading || this.state.loading
        
        const { userAgeCount } = this.props.states
        
        if (!isLoading && checkFeatures(this.props, ["user", "user_age"])) {
            var categories = userAgeCount.map(r => r.age)
            var data = userAgeCount.map(r => r.count)
        }

        return (
            <>
                {isLoading ? <Loading /> :
                    <>
                        <FeatureCheck features={["user", "user_age"]} {...this.props} >
                            <Row style={{ width: "100%" }}>
                                <Col>
                                        <Card>
                                            <CardBody>
                                                <BarChart
                                                    height={600}
                                                    categories={categories}
                                                    stats={[{ name: "Number of users of age", data }]}
                                                    colors={["#38469b"]}
                                                    title="User Age"
                                                />
                                            </CardBody>
                                        </Card>
                                        <AgeTable
                                            {...this.props}
                                        />
                                </Col>
                            </Row>
                        </FeatureCheck>
                    </>
                }
            </>
        );
    }
}

export default withRouter(props => <Age {...props} />);
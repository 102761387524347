import Campaign from "./Campaign"

export default class Company {
    id
    name
    campaigns

    constructor(first, name, campaigns) {
        if (typeof first === "object") {
            const cDTO = first
            this.id = cDTO.companyID
            this.name = cDTO.name
            this.campaigns = cDTO.campaigns.map(campaign => new Campaign(campaign))
        } else {
            this.id = first
            this.name = name
            this.campaigns = campaigns
        }

    }
}
import React, { Component } from 'react';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import GenericTable from '../components/GenericTable';
import { tableThousandSeperator } from '../utils/Converter';

class ChannelTable extends Component {
    render() {

        const data = this.props.states.sessionReferrerCount

        return (
            <div>
                <Card>
                    <CardBody>
                        <GenericTable
                            {...this.props}
                            pagination
                            tableData={data}
                            sizePerPage={10}
                            columnOptions={{
                                "color": {
                                    formatter: (cell)=>{
                                        return <><div style={{backgroundColor:cell, width: 20, height: 20}}></div></>
                                    }
                                },
                                "count":{
                                    formatter: tableThousandSeperator
                                }
                            }}

                        />
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default ChannelTable;
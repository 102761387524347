import React, { Component } from 'react';
import { Card, CardTitle, CardBody, Row, Col, Button, Spinner, Modal, ModalHeader, ModalBody, Label, FormGroup, Badge, ModalFooter } from 'reactstrap';
import GenericTable from '../components/GenericTable';
import SessionFacade from '../facade/project/SessionFacade';
import { withRouter, Redirect, Link } from 'react-router-dom';
import VoucherFacade from '../facade/project/VoucherFacade';
import UserFacade from '../facade/project/UserFacade';
import MapFacade from '../facade/MapFacade';
import Loading from '../components/Loading';
import _, { lte } from "lodash"
import PermissionFacade from '../facade/project/PermissionFacade';
import { makeTo } from '../utils/LinkUtils';
import { tzTableDateFormatter, dateFormatter } from '../utils/Converter';
import HighscoreFacade from '../facade/project/HighscoreFacade';
import FeatureCheck from '../components/FeatureCheck';
import checkFeatures from '../utils/checkFeatures';
import AccessCheck from '../components/AccessCheck';
import smsStatusCodes from '../smsStatusCodes';



class User extends Component {
    state = {
        redirect: null,
        loading: true,
        sessions: [],
        vouchers: [],
        forms: [],
        game_session: [],
        user: null,
        permissions: [],
        highscore: null,
        isOpen: false,
        deleting: false,
        extra: null
    }

    async componentDidMount() {
        await this.fetchData()
    }

    fetchData = async () => {
        if (checkFeatures(this.props, ["user"])) {
            await this.fetchUser()
            if (checkFeatures(this.props, ["user_permission"])) {
                await this.fetchPermissions()
            }
            if (checkFeatures(this.props, ["user_voucher"])) {


                await this.fetchVouchers()
                await this.fetchForms()
            }


            if (checkFeatures(this.props, ["user_game_session"])) {
                await this.fetchGameSession()
            }
            if (checkFeatures(this.props, ["user_session"])) {
                await this.fetchSessions()
            }
        }
        await this.setState({ loading: false })
        if (checkFeatures(this.props, ["user", "ban_user", "user_highscore"])) {
            await this.fetchHighscore()
        }
    }

    fetchUser = async () => {
        const { data, match } = this.props
        const { id } = match.params

        const user = await UserFacade.getUser(data, id)
        await this.setState({ user })
    }

    fetchHighscore = async () => {
        const { data, match } = this.props
        const { id } = match.params

        const highscore = await HighscoreFacade.getHighscoreOnUserID(data, id)
        await this.setState({ highscore })
    }

    fetchPermissions = async () => {
        const { data, match } = this.props
        const { id } = match.params

        const permissions = await PermissionFacade.getUserPermissions(data, id)
        await this.setState({ permissions })
    }

    fetchSessions = async () => {
        const { data, match } = this.props
        const { id } = match.params

        const sessions = await SessionFacade.getUserSessions(data, id)
        await this.setState({ sessions })
    }

    fetchVouchers = async () => {
        const { data, match } = this.props
        let { id } = match.params
        id = id.split('?')[0];
        const mapRef = this.props.projectManager.project.mapRef
        let storeList = false;
        const vouchers = await VoucherFacade.getUserVouchers(data, id)


        for (let i = 0; i < vouchers.length; i++) {
            let voucher = vouchers[i];
            voucher.redeemed_time = voucher.redeemed;
            if (voucher.redeemed) {
                voucher.redeemed = true;

                if (!storeList) storeList = await MapFacade.getLocations(mapRef)

                for (let x = 0, location; location = storeList[x]; x++) {
                    if (location.id == voucher.map_id) {
                        delete voucher.map_id;
                        voucher.store = location.name;
                    }
                }

            }

        }


        const order = vouchers.map(({ created, expires, lastSent, prize, redeemed, redeemed_time, reference_id, sms_ref, statusCode, store, prizePool, changed_from, wallet, share }) => {
            return {
                reference_id,
                prize,
                created,
                expires,
                wallet,
                share,
                redeemed,
                redeemed_time,
                store,
                sms_ref,
                statusCode,
                lastSent,
                prizePool,
                changed_from,
            }

        })





        await this.setState({ vouchers: order })
    }

    fetchForms = async () => {
        const { data, match } = this.props
        let { id } = match.params
        id = id.split('?')[0];
        const mapRef = this.props.projectManager.project.mapRef
        let storeList = false;

        const vouchers = await VoucherFacade.getUserForms(data, id)

        if (vouchers.status && vouchers.status == 500) return false;

        for (let i = 0; i < vouchers.length; i++) {
            let voucher = vouchers[i];
            voucher.redeemed_time = voucher.redeemed;
            if (voucher.redeemed) {
                voucher.redeemed = true;
            }

        }

        const order = vouchers.map(({ created, expires, prize, redeemed, redeemed_time, reference_id, prizePool }) => {
            return {
                reference_id,
                prize,
                created,
                expires,
                redeemed,
                redeemed_time,
                prizePool,
            }

        })





        await this.setState({ forms: order })
    }

    fetchGameSession = async () => {
        const { data, match } = this.props
        let { id } = match.params
        id = id.split('?')[0];
        const mapRef = this.props.projectManager.project.mapRef
        let storeList = false;

        const games = await UserFacade.getGameSession(data, id)
        if (games.status && games.status == 500) return false;



        await this.setState({ game_session: games })
    }

    goBack = () => {
        const { location } = this.props
        const { state } = location
        if (state) {
            this.setState({ redirect: state.from })
        } else {
            this.setState({ redirect: makeTo("/users", location) })
        }
    }

    toggleBan = async () => {
        const { data } = this.props
        const { user, highscore } = this.state
        const newState = !highscore["banned"]

        const status = await HighscoreFacade.changeBanStatusOnUserID(data, user["user_id"], newState)
        if (typeof status === "boolean") {


            const highscoreCopy = Object.assign({}, this.state.highscore)
            highscoreCopy["banned"] = status
            if (status) {
                highscoreCopy["rank_index"] = null
            } else {
                this.fetchHighscore()
            }
            await this.setState({ highscore: highscoreCopy })

        } else {
            this.props.alert.error("Could not change highscore status of user!")
        }

    }

    toggleExtra = async () => {
        const { data } = this.props
        const { user } = this.state
        const newState = !user.extra_member

        const status = await UserFacade.changeExtraStatusOnUserID(data, user["user_id"], newState)
        if (typeof status === "boolean") {
            await this.fetchData()

        } else {
            this.props.alert.error("Could not change highscore status of user!")
        }

    }

    resetUsername = async () => {
        const { data } = this.props
        const { user } = this.state

        const status = await UserFacade.resetUsername(data, user["user_id"])
        console.log(status);
        if (typeof status === "boolean") {
            this.props.alert.success(`Successfully reset username!`)
        } else {
            this.props.alert.error("Could not reset username of user!")
        }

    }

    revoke = async (row) => {
        const { data } = this.props
        await PermissionFacade.revoke(data, row.id)
        await this.fetchPermissions()
        this.props.alert.success(`Successfully revoked permission!`)
    }

    resendVoucher = async (row) => {
        const { data } = this.props
        const response = await VoucherFacade.resend(data, row["sms_ref"], row["prizePool"])
        if (response.status === 200) {
            this.props.alert.success("Voucher was resent!")
        } else {
            this.props.alert.error("Something went wrong when resending the voucher!")
        }
    }
    resendVoucherIsDisabled = (row) => {
        return row.redeemed === null && row["sms_ref"] !== null
    }

    reactivateVoucher = async (row) => {
        const { data } = this.props
        const response = await VoucherFacade.reactivate(data, row["reference_id"], row["prizePool"])
        if (response.status === 200) {
            this.props.alert.success("Voucher was reactivated!")
        } else {
            this.props.alert.error("Something went wrong when reactivating the voucher!")
        }
        await this.fetchVouchers()
    }

    reactivateVoucherIsDisabled = (row) => {
        return true;
        return row.redeemed !== null
    }

    rewalletVoucher = async (row) => {
        const { data } = this.props
        const response = await VoucherFacade.rewallet(data, row["reference_id"], row["prizePool"])
        if (response.status === 200) {
            this.props.alert.success("Voucher was added to the wallet again!")
        } else {
            this.props.alert.error("Something went wrong when adding voucher to wallet!")
        }
        await this.fetchVouchers()
    }

    reshareVoucher = async (row) => {
        const { data } = this.props
        const response = await VoucherFacade.reshare(data, row["reference_id"], row["prizePool"])
        if (response.status === 200) {
            this.props.alert.success("Voucher can be shared again!")
        } else {
            this.props.alert.error("Something went wrong when resetting share!")
        }
        await this.fetchVouchers()
    }
    openVoucher = async (row) => {
        const { data } = this.props

        let url = this.props.projectManager.project.url + '?p=' + row["reference_id"];
        window.open(url)
    }

    rewalletVoucherIsDisabled = (row) => {
        return row.wallet !== true
    }

    reshareVoucherIsDisabled = (row) => {
        return row.share !== false
    }
    openVoucherIsDisabled = (row) => {
        return true
    }


    issuePrize = () => {

        const { data, match } = this.props
        const { id } = match.params
        this.setState({ redirect: makeTo('/stats/issueprize', { search: "?p=" + data.projectManager.project.id + "&m=" + this.state.user.user_id }) })

    }
    toggleValid = async (row) => {
        const { data } = this.props
        const newState = !row.valid
        await SessionFacade.changeValidState(data, row["session_id"], newState)
        await this.fetchSessions()
        this.props.alert.success(newState ? `Toggled session to valid` : `Toggled session to invalid`)
    }

    toggle = async (state) => {
        await this.setState({ isOpen: state });
    };

    goBack = async () => {
        try {
            var { from } = this.props.location.state
        } catch (e) {
            from = "/users"
        }

        await this.setState({ redirect: from })

    }

    anonymiseUser = async () => {
        const { data } = this.props
        this.setState({ deleting: true })
        try {
            var result = await UserFacade.anonymiseUser(data, this.state.user.user_id)
            var { status } = result
        } catch (e) {
            this.props.alert.info(`The anonymisation is taking a bit longer than expected. Please check the status ag`)
            this.goBack()
            return
        }

        this.setState({ deleting: false })
        this.toggle(false)

        if (status === 200 || status === 504) {
            this.props.alert.success(`Successfully deleted user`)
            this.goBack()
        } else {
            if (status) {
                const body = await result.json()
                var { message } = body
            }
            this.props.alert.error(`Something went wrong when anonymising! ${message ? `Server responded with status ${status} - ${message}` : ""}`)
        }


    }


    download = () => {
        const { highscore, user, extra } = this.state


        let profileOrder = {
            "user_id": 'BM ID',
            "mobile": 'Mobile',
            "email": 'Email',
            "firstname": 'First name',
            "lastname": 'Last name',
            "name": 'Name',
            "birthday": 'Birthday',
            "gender": 'Gender',
            "zipcode": 'Zipcode',
            "street": 'Street',
            "address": 'Address',
            "city": 'City',
            "country": 'Country',
            "extra_club_id": 'Extra Club ID',
            "ckid": 'CK ID',
            "created": 'Created',

        }


        let rows = [];


        let profileHeadline = [];
        let profileValue = [];


        for (let key in profileOrder) {
            if (user[key] == null) continue;
            profileHeadline.push(profileOrder[key]);

            let v = user[key];

            if (key == 'gender' && typeof v == 'number') {
                if (v == 0) v = 'Male';
                if (v == 1) v = 'Female';
                if (v == 2) v = 'Other';
            }

            profileValue.push(v);
        }

        rows.push(profileHeadline);
        rows.push(profileValue);


        if (this.state.permissions) {

            let permissionsHeadline = ['Name', 'Created'];

            rows.push([])
            rows.push([])
            rows.push([])
            rows.push(['Permissions:'])
            rows.push(permissionsHeadline);


            for (let i = 0; i < this.state.permissions.length; i++) {
                let permission = this.state.permissions[i];
                let permissionsValue = [];

                permissionsValue.push(permission.name);
                permissionsValue.push(permission.created);
                rows.push(permissionsValue);

            }

        }


        if (this.state.sessions) {
            let sessionsHeadline = ['Session ID', 'IP', 'Created', 'Last active', 'Referrer', 'Device'];

            rows.push([])
            rows.push([])
            rows.push([])
            rows.push(['Sessions:'])
            rows.push(sessionsHeadline);

            for (let i = 0; i < this.state.sessions.length; i++) {
                let session = this.state.sessions[i];
                let sessionValue = [];

                sessionValue.push(session.session_id);
                sessionValue.push(session.ip);
                sessionValue.push(session.created);
                sessionValue.push(session.lastactive);
                sessionValue.push(session.referrer);
                sessionValue.push(session.device);
                rows.push(sessionValue);
            }
        }


        if (this.state.vouchers) {
            let vouchersHeadline = ['ID', 'Prize', 'Created', 'Expire', 'Redeemed', 'Store'];

            rows.push([])
            rows.push([])
            rows.push([])
            rows.push(['Vouchers:'])
            rows.push(vouchersHeadline);

            for (let i = 0; i < this.state.vouchers.length; i++) {
                let voucher = this.state.vouchers[i];
                let voucherValue = [];

                voucherValue.push(voucher.reference_id);
                voucherValue.push(voucher.prize);
                voucherValue.push(voucher.created);
                voucherValue.push(voucher.expires);
                voucherValue.push(voucher.redeemed ? 'Yes' : 'No');
                voucherValue.push(voucher.store);
                rows.push(voucherValue);
            }
        }


        if (this.state.game_session) {
            let gamesHeadline = ['ID', 'Created', 'Completed','Prize'];

            rows.push([])
            rows.push([])
            rows.push([])
            rows.push(['Games:'])
            rows.push(gamesHeadline);

            for (let i = 0; i < this.state.game_session.length; i++) {
                let game = this.state.game_session[i];
                let gameValue = [];

                gameValue.push(game.game_id);
                gameValue.push(game.created);
                gameValue.push(game.completed);
                gameValue.push(game.prize);
                rows.push(gameValue);
            }
        }


        function exportToCsv(filename, rows, delimter = ',') {
            var processRow = function (row) {
                var finalVal = '';
                for (var j = 0; j < row.length; j++) {
                    let innerValue = '';
                    try{
                        innerValue = row[j] === null ? '' : row[j].toString();
                    }catch(e){}
                    if (row[j] instanceof Date) {
                        innerValue = row[j].toLocaleString();
                    };
                    var result = innerValue.replace(/"/g, '""');
                    if (result.search(/("|,|\n)/g) >= 0)
                        result = '"' + result + '"';
                    if (j > 0)
                        finalVal += delimter;
                    finalVal += result;
                }
                return finalVal + '\n';
            };
        
            var csvFile = '';
            for (var i = 0; i < rows.length; i++) {
                csvFile += processRow(rows[i]);
            }
        
            var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, filename);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", filename);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        }

        let delimter = ','
        if(navigator.platform&&navigator.platform.indexOf('Mac')>-1){
            delimter = ';'
        }

        exportToCsv('User - '+user.user_id+'.csv', rows, delimter);
    }

    render() {

        const tableDateFormatter = tzTableDateFormatter(this.props.data.projectManager.project.timezone)

        if (this.state.redirect) {
            return <Redirect push to={this.state.redirect} />
        }

        const { highscore, user, extra } = this.state
        let banColor = "info"
        if (highscore) {
            var hsBan = highscore.banned
            var rank = highscore.rank_index ? highscore.rank_index : undefined


            banColor = hsBan ? "danger" : "success"
        }


        let permissionActions = {}
        if (checkFeatures(this.props, ["user_permission_revoke"])) {
            permissionActions["Revoke Permission"] = this.revoke
        }

        let sessionActions = {}
        if (checkFeatures(this.props, ["user_session_toggle_valid"])) {
            sessionActions["Toggle Valid"] = this.toggleValid
        }

        let voucherActions = {}
        if (checkFeatures(this.props, ["user_voucher_resend"])) {
            voucherActions["Resend Voucher"] = this.resendVoucher
        }

        if (checkFeatures(this.props, ["user_voucher_reactivate"])) {
            voucherActions["Reactivate Voucher"] = this.reactivateVoucher
        }
        if (checkFeatures(this.props, ["user_voucher_rewallet"])) {
            voucherActions["Reset wallet"] = this.rewalletVoucher
        }
        if (checkFeatures(this.props, ["user_voucher_reshare"])) {
            voucherActions["Reset shared"] = this.reshareVoucher
        }

        if (this.props.projectManager.project.url) {
            voucherActions["Open voucher"] = this.openVoucher

        }


        let showWallet = !checkFeatures(this.props, ["user_voucher_rewallet"]);
        let showShared = !checkFeatures(this.props, ["user_voucher_reshare"]);

        return (
            <>
                <Row>
                    <Col xs={4}>
                        <Button outline color="primary" className="btn-rounded mb-4 mt-2" onClick={this.goBack}>
                            <i className="dripicons-arrow-thin-left mr-2"></i>
                            Back
                        </Button>
                    </Col>
                    <Col xs={4} className="text-center mt-2">
                        <h4>User</h4>
                    </Col>
                    <AccessCheck accesses={["userdata", "gdpr"]} {...this.props}>
                        <Col xs={4}>
                            <Button outline color="danger" className="btn-rounded mb-4 mt-2 float-right" onClick={() => this.toggle(true)}>
                                <i className="dripicons-cross mr-2"></i>
                                Delete
                            </Button>
                        </Col>
                    </AccessCheck>
                    <Col xs={4}>
                        <Button outline color="primary" className="btn-rounded mb-4 mt-2" onClick={this.download}>

                            Download
                        </Button>
                    </Col>
                </Row>



                {/* <br /> */}

                {this.state.loading ? <Loading /> :
                    <>
                        <FeatureCheck features={["user"]} {...this.props} >
                            <Row>
                                <Col lg={6} xl={7}>
                                    <Row>
                                        <Col lg={12} xl={7}>
                                            <Card className="text-white bg-primary text-center">
                                                <CardBody>
                                                    <a>ID</a>

                                                    <h4>{user["user_id"]}</h4>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={12} xl={5}>
                                            <Card className="text-white bg-dark text-center">
                                                <CardBody>
                                                    <a>Created</a>
                                                    <h4>{dateFormatter(this.props.data.projectManager.project.timezone)(user.created)}</h4>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                        <FeatureCheck features={["show_login_info_user"]} {...this.props} >
                                            {user.mobile &&
                                                <Col xs={6} xl={5}>
                                                    <Card className=" text-center">
                                                        <CardBody>
                                                            <a>Mobile</a>

                                                            <h4>{user.mobile}</h4>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            }

                                            {user.email &&
                                                <Col xs={6} xl={5}>
                                                    <Card className=" text-center">
                                                        <CardBody>
                                                            <a>Email</a>

                                                            <h4>{user.email}</h4>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            }
                                        </FeatureCheck>
                                        <FeatureCheck features={["ban_user"]} {...this.props} >
                                            <Col xs={12} sm={6} lg={6} xl={7}>
                                                <Card className={`text-white bg-${banColor} text-center`}>
                                                    <CardBody>
                                                        <Row className="text-center">

                                                            {highscore ? <>
                                                                <Col xs={6} sm={12} lg={12} xl={8} >
                                                                    <a>Highscore Status</a>
                                                                    <h4 className="mb-2">{hsBan ? "User is highscore banned" : rank ? `User is ranked #${rank}` : "User appears on highscore"}</h4>
                                                                </Col>
                                                                <Col xs={6} sm={12} lg={12} xl={4}>
                                                                    <Button outline color="light" style={{ height: "100%", width: "100%", minHeight: 45, maxWidth: 100, }} className="btn-rounded p-0" onClick={this.toggleBan}>
                                                                        {hsBan ? "Unban" : "Ban"}
                                                                    </Button>
                                                                </Col>
                                                            </> : <>
                                                                <Col xs={6} sm={12} lg={12} xl={8}>
                                                                    <a>Highscore Status</a>
                                                                    <h4>Loading Highscore Status</h4>

                                                                </Col >
                                                                <Col xs={6} sm={12} lg={12} xl={4}>
                                                                    <Spinner
                                                                        className="text-light m-2"
                                                                        size={"md"}
                                                                        style={{ margin: "auto" }} />
                                                                </Col>
                                                            </>}
                                                        </Row>


                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </FeatureCheck>

                                        <FeatureCheck features={["highscore_username"]} {...this.props} >
                                            <Col xs={12} sm={6} lg={6} xl={6}>
                                                <Card className={`text-white bg-${banColor} text-center`}>
                                                    <CardBody>
                                                        <Row className="text-center">
                                                            {highscore ? <>
                                                                <Col xs={6} sm={12} lg={12} xl={8} >
                                                                    <a>Reset username</a>
                                                                    {/* <h4 className="mb-2">{rank ? "User appears on highscore" : "User not on highscore"}</h4> */}
                                                                </Col>
                                                                <Col xs={6} sm={12} lg={12} xl={4}>
                                                                    <Button outline color="light" style={{ height: "100%", width: "100%", minHeight: 45, maxWidth: 100, }} className="btn-rounded p-0" onClick={this.resetUsername}>
                                                                        Reset
                                                                    </Button>
                                                                </Col>
                                                            </> : <>
                                                                <Col xs={6} sm={12} lg={12} xl={8}>
                                                                    <a>Highscore Status</a>
                                                                    <h4>Loading Highscore Status</h4>

                                                                </Col >
                                                                <Col xs={6} sm={12} lg={12} xl={4}>
                                                                    <Spinner
                                                                        className="text-light m-2"
                                                                        size={"md"}
                                                                        style={{ margin: "auto" }} />
                                                                </Col>
                                                            </>}
                                                        </Row>


                                                    </CardBody>
                                                </Card>
                                            </Col>

                                        </FeatureCheck>
                                        <FeatureCheck features={["extra"]} {...this.props} >
                                            {user.extra_member !== undefined && <>
                                                <Col xs={12} sm={6} lg={6} xl={6}>
                                                    <Card className={`text-white bg-${user.extra_member ? "warning" : "secondary"} text-center`}>
                                                        <CardBody>
                                                            <Row className="text-center">

                                                                <Col xs={6} sm={12} lg={12} xl={8} >
                                                                    <a>Extra Member Status</a>
                                                                    <h4 className="mb-2">{user.extra_member ? "User is extra member" : "User is not extra member"}</h4>
                                                                </Col>
                                                                <Col xs={6} sm={12} lg={12} xl={4}>
                                                                    <Button outline color="light" style={{ height: "100%", width: "100%", minHeight: 45, maxWidth: 100, }} className="btn-rounded p-0" onClick={this.toggleExtra}>
                                                                        Toggle
                                                                    </Button>
                                                                </Col>
                                                            </Row>


                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </>}

                                        </FeatureCheck>


                                    </Row>

                                </Col>
                                <FeatureCheck features={["user_permission"]} {...this.props} >

                                    <Col md={12} lg={6} xl={5}>
                                        <Card>
                                            <CardBody>
                                                <h4>Permissions</h4>
                                                <br />
                                                <GenericTable
                                                    {...this.props}
                                                    tableData={this.state.permissions}
                                                    actions={permissionActions}
                                                    columnOptions={{
                                                        id: {
                                                            hide: true
                                                        },
                                                        "created": {
                                                            text: "Time Given",
                                                            formatter: tableDateFormatter
                                                        },
                                                        "name": {
                                                            text: "Type"
                                                        }
                                                    }}
                                                    defaultSorted={[{
                                                        dataField: 'created',
                                                        order: 'desc'
                                                    }]}
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </FeatureCheck>
                            </Row>

                            <FeatureCheck features={["user_session"]} {...this.props} >
                                <Card>
                                    <CardBody>
                                        <h4>Sessions</h4>
                                        <br />
                                        <GenericTable
                                            {...this.props}
                                            pagination
                                            tableData={this.state.sessions}
                                            actions={sessionActions}
                                            columnOptions={{
                                                "session_id": {
                                                    text: "Session ID"
                                                },
                                                ip: {
                                                    text: "IP"
                                                },
                                                lastactive: {
                                                    text: "Last Active",
                                                    formatter: tableDateFormatter
                                                },
                                                valid: {
                                                    hide: false
                                                },
                                                os: {
                                                    text: "OS"
                                                },
                                                browserversion: {
                                                    text: "Browser Version"
                                                },
                                                created: {
                                                    formatter: tableDateFormatter,
                                                },


                                            }}
                                            defaultSorted={[{
                                                dataField: 'created',
                                                order: 'desc'
                                            }]}
                                        />
                                    </CardBody>
                                </Card>
                            </FeatureCheck>

                            <FeatureCheck features={["user_voucher"]} {...this.props} >
                                <Card className="mt-2">
                                    <CardBody>
                                        <h4 style={{ float: 'left' }}>Vouchers</h4>

                                        <FeatureCheck features={["issueprize"]} {...this.props} >

                                            <Button style={{ float: 'right' }} onClick={this.issuePrize}>Issue voucher</Button>
                                        </FeatureCheck>
                                        <div style={{ clear: 'both' }}></div>
                                        <br />
                                        <GenericTable
                                            {...this.props}
                                            tableData={this.state.vouchers}
                                            pagination
                                            actions={voucherActions}
                                            actionStates={{
                                                "Resend Voucher": this.resendVoucherIsDisabled,
                                                "Reactivate Voucher": this.reactivateVoucherIsDisabled,
                                                "Reset wallet": this.rewalletVoucherIsDisabled,
                                                "Reset shared": this.reshareVoucherIsDisabled,
                                                "Open voucher": this.openVoucherIsDisabled
                                            }}
                                            actionsStyle="button"
                                            columnOptions={{
                                                "reference_id": {
                                                    text: "ID",
                                                    formatter: (data) => {

                                                        if (this.props.projectManager.project.url) {
                                                            let url = this.props.projectManager.project.url + '?p=' + data;
                                                            return <a href={url} target="_blank">{data}</a>
                                                        } else {
                                                            return data;
                                                        }

                                                    }
                                                },
                                                redeemed_time: {
                                                    text: "Redeem Time",

                                                    formatter: tableDateFormatter,
                                                    // converter: (time) => time ? time : "NOT REDEEMED"
                                                },
                                                redeemed: {
                                                    formatter: function (data) {
                                                        if (data == true) {
                                                            return 'Yes'
                                                        } else {
                                                            return 'No'

                                                        }
                                                    }

                                                },
                                                map_id: {
                                                    text: "Store",

                                                },
                                                created: {
                                                    formatter: tableDateFormatter,
                                                },
                                                expires: {
                                                    formatter: tableDateFormatter,
                                                },
                                                "sms_ref": {
                                                    hide: true
                                                },
                                                "prizePool": {
                                                    hide: true
                                                },
                                                "changed_from": {
                                                    text: "Changed From"
                                                },
                                                "wallet": {
                                                    text: "Show in wallet",
                                                    hide: showWallet,
                                                    formatter: function (data) {

                                                        if (data == null) {
                                                            return null;
                                                        }

                                                        if (data == true) {
                                                            return 'Yes'
                                                        } else {
                                                            return 'No'

                                                        }
                                                    }
                                                },
                                                "share": {
                                                    text: "Shared",
                                                    hide: showShared,
                                                    formatter: function (data) {

                                                        if (data == null) {
                                                            return null;
                                                        }

                                                        if (data == true) {
                                                            return 'Yes'
                                                        } else {
                                                            return 'No'

                                                        }
                                                    }
                                                },
                                                "statusCode": {
                                                    text: "Delivery Status",
                                                    formatter: (cell, row) => {
                                                        if (cell == null) {
                                                            return ""
                                                        } else {
                                                            return <Badge pill color={cell === 1 ? "success" : "warning"}><a style={{ fontSize: 13 }}>{smsStatusCodes[cell]}</a></Badge>
                                                        }

                                                    }
                                                },
                                                "lastSent": {
                                                    text: "Last Delivery Update",
                                                    formatter: tableDateFormatter,
                                                },

                                            }}
                                            defaultSorted={[{
                                                dataField: 'created',
                                                order: 'desc'
                                            }]}
                                        />
                                    </CardBody>
                                </Card>
                            </FeatureCheck>



                            <FeatureCheck features={["user_voucher"]} {...this.props} >
                                <Card className="mt-2">
                                    <CardBody>
                                        <h4>Forms</h4>
                                        <br />
                                        <GenericTable
                                            {...this.props}
                                            tableData={this.state.forms}
                                            pagination
                                            actionsStyle="button"
                                            columnOptions={{
                                                "reference_id": {
                                                    text: "ID",
                                                    formatter: (data) => {

                                                        if (this.props.projectManager.project.url) {
                                                            let url = this.props.projectManager.project.url + '?f=' + data;
                                                            return <a href={url} target="_blank">{data}</a>
                                                        } else {
                                                            return data;
                                                        }

                                                    }
                                                },
                                                redeemed_time: {
                                                    text: "Redeem Time",

                                                    formatter: tableDateFormatter,
                                                    // converter: (time) => time ? time : "NOT REDEEMED"
                                                },
                                                redeemed: {
                                                    formatter: function (data) {
                                                        if (data == true) {
                                                            return 'Yes'
                                                        } else {
                                                            return 'No'

                                                        }
                                                    }

                                                },
                                                map_id: {
                                                    text: "Store",

                                                },
                                                created: {
                                                    formatter: tableDateFormatter,
                                                },
                                                expires: {
                                                    formatter: tableDateFormatter,
                                                },
                                                "sms_ref": {
                                                    hide: true
                                                },
                                                "prizePool": {
                                                    hide: true
                                                },
                                                "changed_from": {
                                                    text: "Changed From"
                                                },
                                                "statusCode": {
                                                    text: "Delivery Status",
                                                    formatter: (cell, row) => {
                                                        if (cell == null) {
                                                            return ""
                                                        } else {
                                                            return <Badge pill color={cell === 1 ? "success" : "warning"}><a style={{ fontSize: 13 }}>{smsStatusCodes[cell]}</a></Badge>
                                                        }

                                                    }
                                                },
                                                "lastSent": {
                                                    text: "Last Delivery Update",
                                                    formatter: tableDateFormatter,
                                                },

                                            }}
                                            defaultSorted={[{
                                                dataField: 'created',
                                                order: 'desc'
                                            }]}
                                        />
                                    </CardBody>
                                </Card>
                            </FeatureCheck>



                            <FeatureCheck features={["user_game_session"]} {...this.props} >
                                <Card className="mt-2">
                                    <CardBody>
                                        <h4>Games played (Primary game)</h4>
                                        <br />
                                        <GenericTable
                                            {...this.props}
                                            tableData={this.state.game_session}
                                            pagination
                                            actionsStyle="button"
                                            columnOptions={{
                                                "reference_id": {
                                                    text: "ID",
                                                    formatter: (data) => {

                                                        if (this.props.projectManager.project.url) {
                                                            let url = this.props.projectManager.project.url + '?f=' + data;
                                                            return <a href={url} target="_blank">{data}</a>
                                                        } else {
                                                            return data;
                                                        }

                                                    }
                                                },

                                                map_id: {
                                                    text: "Store",

                                                },
                                                game_id: {
                                                    text: "Game id",

                                                },
                                                created: {
                                                    formatter: tableDateFormatter,
                                                },
                                                completed: {
                                                    formatter: tableDateFormatter,
                                                },




                                            }}
                                            defaultSorted={[{
                                                dataField: 'created',
                                                order: 'desc'
                                            }]}
                                        />
                                    </CardBody>
                                </Card>
                            </FeatureCheck>
                        </FeatureCheck>
                        <Modal isOpen={this.state.isOpen} toggle={() => this.toggle(false)} scrollable className="modal-center" style={{ maxWidth: 600, width: "100%" }}>
                            <ModalHeader toggle={() => this.toggle(false)} >
                                <h3>DELETE USER</h3>
                            </ModalHeader>
                            <ModalBody className>
                                <p>Do you wish to anonymise all personal data related to user <Badge color="primary">{user.user_id}</Badge> with {user.mobile ? <a>mobile <Badge color="light">{user.mobile}</Badge></a> : <a>email <Badge color="light">{user.email}</Badge></a>}?</p>
                                <p>If so, press the DELETE button.</p>
                                <p><b>NOTE!</b> The anonymisation can take up to a couple of minutes, please wait patiently until then. If 15 minutes has passed and you are still able to locate the user please contact your super-user for support!</p>
                                <br />
                                <p className="text-danger">Once the button is pressed, the user's personal data will be unrecoverable and you will be directed to where you came from within 30 seconds!</p>

                            </ModalBody>
                            <ModalFooter className="text-center">
                                <Button color="danger" onClick={this.anonymiseUser} disabled={this.state.deleting}>{this.state.deleting ? <Spinner size="sm" /> : `DELETE`}</Button>
                                <Button color="secondary" onClick={() => this.toggle(false)}>{this.state.deleting ? `Close` : `Cancel`}</Button>
                            </ModalFooter>
                        </Modal>
                    </>
                }


            </>

        );
    }
}


export default withRouter(props => <User {...props} />);
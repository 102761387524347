import React, { Component } from "react";


export default class Account extends Component {
    constructor(props) {
        super(props)
    }

    render() {

        return (
            <div style={{ height: "100%", minHeight: window.innerHeight, padding:20 }}>
                <h3>Account</h3>
            </div>
        )
    }
}
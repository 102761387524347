import React, { Component } from 'react';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import GenericTable from '../components/GenericTable';
import checkFeatures from '../utils/checkFeatures';
import { tableThousandSeperator } from '../utils/Converter';

class EngagementTable extends Component {
    render() {

        let stats = [
            { name: "Games Started", stat: this.props.fullChartStats.gameCount, checks: ["game"] },
            { name: "Games Completed", stat: this.props.fullChartStats.gameCompletedCount, checks: ["game", "game_completed"] },
            { name: "Games Won", stat: this.props.fullChartStats.gameWinCount, checks: ["game", "game_win"] },
            { name: "New Users", stat: this.props.fullChartStats.userCount, checks: ["user"] },
            { name: "Users Active", stat: this.props.fullChartStats.userActiveCount, checks: ["user", "user_active"] },
            { name: "Vouchers Issued", stat: this.props.fullChartStats.vouchersIssued, checks: ["voucher"] },
            { name: "Vouchers Redeemed", stat: this.props.fullChartStats.vouchersRedeemed, checks: ["voucher", "voucher_redeemed"] },
            { name: "Twofactor Sent", stat: this.props.fullChartStats.twofactorSentCount, checks: ["twofactor", "twofactor_sent"] },
            { name: "Twofactor Completed", stat: this.props.fullChartStats.twofactorCompletedCount, checks: ["twofactor", "twofactor_completed"] },
            { name: "Twofactor Failed", stat: this.props.fullChartStats.twofactorFailedCount, checks: ["twofactor", "twofactor_failed"] },
            { name: "Twofactor Not Registered", stat: this.props.fullChartStats.twofactorNotRegisteredCount, checks: ["twofactor", "twofactor_not_registered"] },
            { name: "Twofactor Not Used", stat: this.props.fullChartStats.twofactorNotUsedCount, checks: ["twofactor", "twofactor_not_used"] },
        ]

        let statsToUse = stats.filter(({checks}) => checkFeatures(this.props, checks))

        const data = this.props.fullChartStatsStrings.map((date, i) => {
            let obj = {
                date
            }

            for (const stat of statsToUse) {
                obj[stat.name] = stat.stat[i]
            }
            
            return obj
        }).reverse()

        return (
            <div>
                <Card>
                    <CardBody>
                        <GenericTable
                            {...this.props}
                            pagination
                            tableData={data}
                            sizePerPage={10}
                            columnOptions={{
                                "date": {
                                    sort: false
                                },
                                "Games Started": {
                                    sort: false,
                                    formatter: tableThousandSeperator
                                },
                                "Games Completed": {
                                    sort: false,
                                    formatter: tableThousandSeperator
                                },
                                "Games Won": {
                                    sort: false,
                                    formatter: tableThousandSeperator
                                },
                                "Users Active": {
                                    sort: false,
                                    formatter: tableThousandSeperator
                                },
                                "New Users": {
                                    sort: false,
                                    formatter: tableThousandSeperator
                                },
                                "Vouchers Issued": {
                                    sort: false,
                                    formatter: tableThousandSeperator
                                },
                                "Vouchers Redeemed": {
                                    sort: false,
                                    formatter: tableThousandSeperator
                                },
                                "Twofactor Sent": {
                                    sort: false,
                                    formatter: tableThousandSeperator
                                },
                                "Twofactor Completed": {
                                    sort: false,
                                    formatter: tableThousandSeperator
                                },
                                "Twofactor Failed": {
                                    sort: false,
                                    formatter: tableThousandSeperator
                                },
                                "Twofactor Not Registered": {
                                    sort: false,
                                    formatter: tableThousandSeperator
                                },
                                "Twofactor Not Used": {
                                    sort: false,
                                    formatter: tableThousandSeperator
                                },
                            }}

                        />
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default EngagementTable;
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row, Button } from 'reactstrap';
import BarChartBrite from '../components/BarChartBrite';
import Loading from '../components/Loading';
import ProjectDataPage from './ProjectDataPage';
import _ from "lodash"
import ChannelTable from './ChannelTable';
import FeatureCheck from '../components/FeatureCheck';
import FormsResolver from '../resolvers/FormsResolver';
import GenericTable from '../components/GenericTable';
import { tableThousandSeperator, tzTableDateFormatter } from '../utils/Converter';
import TwoFactorFacade from '../facade/project/TwoFactorFacade'
import checkFeatures from '../utils/checkFeatures';

class TwoFactorForm extends Component {
    state = {};


    searchTwoFactor = (e) => {
        const { data } = this.props

        if (this.loading) {
            return false;
        }

        if(!this.state.number||this.state.number.toString().length < 5){
            return false;
        }


        
        this.loading = true;
        this.setState({ loading: true });

        TwoFactorFacade.getUser(data, this.state.number).then((json) => {
            this.loading = false;
        this.setState({ loading: false });

            this.setState({ code: json.code });
        }).catch(() => {
            this.loading = false;
        this.setState({ loading: false });

        })



    }
    getOneClickLogin = (e)=>{

        const { data } = this.props

        if (this.loading) {
            return false;
        }

        if(!this.state.number||this.state.number.toString().length < 5){
            return false;
        }


        
        this.loading = true;
        this.setState({ loading: true });

        TwoFactorFacade.getOneClickLogin(data, this.state.number).then((json) => {
            this.loading = false;
            this.setState({ loading: false });
            this.setState({ loginKey: json.key });
        }).catch(() => {
            this.loading = false;
            this.setState({ loading: false });

        })

    }    
    handleChange = (event) => {
            event.target.value = event.target.value.replace(/\D/g,'');
    this.state.number= event.target.value;
      }

    render() {


        let url = this.props.projectManager.project.url || '';

        return (
            <>
                {this.loading ? <Loading /> :
            <>

                <Card>
                    <CardBody>
                        <label>Enter mobile number to receive code</label>
                        <input type='text' class='form-control' placeholder='2015555555' onChange={this.handleChange}/>
<br/>
<br/>
                        <Button onClick={this.searchTwoFactor} >Get two factor</Button>
                        <FeatureCheck features={["oneclick_login"]} {...this.props} >
                        <br/>
                        <br/>
                         <Button onClick={this.getOneClickLogin} >Get one click login link</Button>
                        </FeatureCheck>
                    </CardBody>
                </Card>
                {this.state.code &&
                    <Card >
                        <CardBody>
                        Two factor code: <b>{this.state.code}</b>
                        </CardBody>
                    </Card>
                }
                
                {this.state.loginKey &&
                    <Card >
                        <CardBody>
                        OneClick link: <b>{url+'?oc='+this.state.loginKey}</b>
                        </CardBody>
                    </Card>
                }
</>


            }
            </>);
    }
}

export default withRouter(props => <TwoFactorForm {...props} />);
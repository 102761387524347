import React, { Component } from 'react';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import GenericTable from '../components/GenericTable';
import { tableThousandSeperator } from '../utils/Converter';

class AgeTable extends Component {
    render() {

        const data = this.props.states.userAgeCount.map(({count, age}) => {
            return { age, count }
        })
        return (
            <div>
                <Card style={{ margin: "auto" }}>
                    <CardBody>
                        <GenericTable
                            {...this.props}
                            pagination
                            tableData={data}
                            sizePerPage={10}
                            columnOptions={{
                                "age": {
                                    text: "Age",
                                    sort: false,
                                    // formatter: tableThousandSeperator

                                },
                                "count": {
                                    text: "Number of Users of Age",
                                    formatter: tableThousandSeperator,
                                    sort: false,
                                }
                            }}

                        />
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default AgeTable;
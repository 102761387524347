
import React from "react"
import LoginFacade from "../facade/LoginFacade";
import { encode, decode } from "../utils/Base64"

export default class AuthManager {

    async authenticate(email, password,pin) {
        const response = await LoginFacade.login(email, password,pin)
        if(response.status == 'twofactor'){

            return true;
        }



        if(response.status){
            throw new Error(response.message)
        }
        this.user = response
        this.sessionID = response.sessionID
        return false;
    }

    get user() {
        try {
            return decode(localStorage.getItem("user"))
        } catch (e) {
            console.error('Failed to retrieve user!')
            this.logout()
        }
    }

    set user(userDTO) {
        localStorage.setItem("user", encode(userDTO))
    }

    get sessionID() {
        try {
            return decode(localStorage.getItem("sid"))
        } catch (e) {
            console.error('Failed to retrieve sessionID!')
            this.logout()
            return null
        }
    }

    set sessionID(sessionID) {
        localStorage.setItem("sid", encode(sessionID))
    }

    logout() {
        localStorage.removeItem("sid")
        localStorage.removeItem("user")
    }

    isAuthenticated() {
        return this.sessionID !== null
    }


}

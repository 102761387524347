import React, { Component } from 'react';
// import logo from './logo.svg';
import './App.scss';
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom"
import Login from "./pages/Login"
import ContentContainer from "./pages/ContentContainer"
import ProtectedRoute from './components/ProtectedRoute';
import AuthManager from './managers/AuthManager';
import './assets/scss/DefaultTheme.scss';
import ProjectManager from './managers/ProjectManager';
import Logout from "./pages/Logout"
import DataManager from "./managers/DataManager"
import ParamState from "./utils/ParamState"
import AlertTemplate from "react-alert-template-basic";
import { transitions, positions, Provider as AlertProvider, withAlert } from 'react-alert'

const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_RIGHT,
    timeout: 5000,
    offset: '30px',
    // you can also just use 'scale'
    transition: transitions.FADE
}

class App extends Component {
    constructor(props) {
        super(props)
        const { auth, projectManager, data } = this.constructManagers()
        this.state = {
            auth,
            projectManager,
            data,
        }
    }

    constructManagers = () => {
        const auth = new AuthManager()
        const paramState = new ParamState(this.getProps, {
            start: null,
            end: null,
            storeID: null
        })
        const projectManager = new ProjectManager(paramState)

        const data = new DataManager(auth, projectManager, paramState)
        return { auth, projectManager, data }
    }

    getProps = () => {
        return this.props
    }

    reset = () => {
        const { auth, projectManager, data } = this.constructManagers()
        this.setState({ auth, projectManager, data })
    }




    render() {
        return (
            <Switch>
                <Route path="/login">
                    <Login auth={this.state.auth} />
                </Route>
                <Route path="/logout" >
                    <Logout auth={this.state.auth} reset={this.reset} />
                </Route>
                <ProtectedRoute auth={this.state.auth} projectManager={this.state.projectManager} path="/" >
                    <AlertProvider template={AlertTemplate} {...options}>
                        <ContentContainer auth={this.state.auth} projectManager={this.state.projectManager} data={this.state.data} alert={this.props.alert} />
                    </AlertProvider>
                </ProtectedRoute>
            </Switch>
        );
    }

}

export default withRouter(props => <App {...props} />)


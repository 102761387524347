import React from "react"
import { Route, Redirect, withRouter } from "react-router-dom"
import "url-search-params-polyfill";
import CompanyFacade from "../facade/CompanyFacade";
import ProjectFacade from "../facade/ProjectFacade";

function ProjectRoute({ children, auth, projectManager, location, direct, ...rest }) {
    const CAMPAIGN_PATH = "/campaigns"
    const searchParams = new URLSearchParams(location.search)
    const projectID = searchParams.get("p")
    const projectChosen = projectManager.isProjectChosen()

    return (
        <Route
            {...rest}
            render={({ location }) => {
                const { pathname } = location

                if (!projectChosen) {
                    return <Redirect
                        to={{
                            pathname: CAMPAIGN_PATH,
                            state: { from: location, autoload: projectID !== null }
                        }}
                    />
                }

                return children
            }}
        />
    )
}

export default withRouter(props => <ProjectRoute {...props} />)

let URI = process.env.REACT_APP_HYPER_URL

export default class FacadeConnection {
    _controller
    _signal

    constructor() {
        this._controller = new AbortController();
        this._signal = this._controller.signal;
    }

    async _send(path, method = "GET", params = null, sessionID = null, projectID = null, body = null) {
        // Creating url
        const urlObj = new URL(URI + path)

        // Setting params on url
        if (params !== null) {
            for (const key in params) {
                urlObj.searchParams.append(key, params[key])
            }
        }

        // Defining options object
        const options = {
            method,
            headers: {
                "Session-Id": sessionID,
                "projectID": projectID,
                "Content-Type": "application/json"
            },
            signal: this.signal
        }

        // Parsing body
        if (body !== null) {
            options.body = JSON.stringify(body)
        }

        return await fetch(urlObj, options)
    }

    async abort() {
        this._controller.abort()
    }

    async get(path, multi = null, projectID = null, params = []) {
        if (typeof multi === "string") {
            // if sessionID
            return await (await this._send(path, "GET", null, multi, projectID)).json()
        } else {
            // if datamanager
            const { authManager, projectManager, all, start, end, tempGranulize, tempTablewise, storeID } = multi

            const { sessionID } = authManager

            let params = {}

            if(projectManager.project){
                projectID = projectManager.project.id
                params = {
                    all,
                    start,
                    end
                }
                
            }
           
            if (storeID) {
                params.storeID = storeID
            }

            if (tempGranulize) {
                params["granulize"] = tempGranulize
            }

            if (tempTablewise) {
                params["tablewise"] = tempTablewise
            }

            return await (await this._send(path, "GET", params, sessionID, projectID)).json()
        }
    }

    async post(path, body, sessionID = null, projectID = null) {
        return await (await this._send(path, "POST", null, sessionID, projectID, body)).json()
    }

    async put(path, body = null, sessionID = null, projectID = null) {
        return await (await this._send(path, "PUT", null, sessionID, projectID, body)).json()
    }

    async delete(path, multi = null, projectID = null, params = []) {
        if (typeof multi === "string") {
            // if sessionID
            return await (await this._send(path, "DELETE", null, multi, projectID)).json()
        } else {
            // if datamanager
            const { authManager, projectManager, all, start, end } = multi
            projectID = projectManager.project.id
            const { sessionID } = authManager
            const params = {
                all,
                start,
                end
            }

            return await (await this._send(path, "DELETE", params, sessionID, projectID))
        }
    }
}


import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row, Button } from 'reactstrap';
import BarChartBrite from '../components/BarChartBrite';
import Loading from '../components/Loading';
import ProjectDataPage from './ProjectDataPage';
import _ from "lodash"
import ChannelTable from './ChannelTable';
import FeatureCheck from '../components/FeatureCheck';
import FormsResolver from '../resolvers/FormsResolver';
import GenericTable from '../components/GenericTable';
import { tableThousandSeperator, tzTableDateFormatter } from '../utils/Converter';
import SelectRandomFacade from '../facade/project/SelectRandomFacade'

class SelectRandomForm extends Component {
    state = {}

    sourceSelect = (e) => {
        let value = e.target.value
        this.setState({ source: value });
        if (value == "") {
            this.setState({ method: "" });

        }
    }
    methodSelect = (e) => {
        this.setState({ method: e.target.value });
    }
    amountSelect = (e) => {
        this.setState({ amount: e.target.value });
    }

    searchSelect = (e) => {
        const { data } = this.props

        if (!this.state.method || !this.state.amount || !this.state.source || this.loading) {
            return false;
        }

        this.loading = true;
        SelectRandomFacade.getUser(data, this.state.source, this.state.method, this.state.amount).then((json) => {
            this.loading = false;
            this.setState({ users: json });
        }).catch(() => {
            this.loading = false;

        })



    }

    render() {
        let supported = this.props.supported
        const types = {
            'point': 'Using points as weight',
            'highscore': 'Just random',
            'random': 'Just random'
        }

        const source = [];
        if (supported) {
            for (let k in supported) {
                let name;
                if (k.indexOf('_') == -1) {
                    name = _.capitalize(k)
                } else {
                    const nameSplit = k.split("_")
                    const names = nameSplit.slice(1, nameSplit.length - 1).map(name => _.capitalize(name))
                    name = names.join(" ")
                }

                source.push({ name, k });

            }
        }

        let type = [];
        let typeDisabled = true;
        if (this.state.source) {
            typeDisabled = false
            supported[this.state.source].map((method) => {
                type.push({ name: types[method], k: method });
            })
        } else {
            typeDisabled = true;
            type = [];

        }



        return (
            <>
                <Card>
                    <CardBody>
                        <label>Select source for select</label>
                        <select class="custom-select" onChange={this.sourceSelect}>
                            <option value="">Select source</option>

                            {source.map(({ name, k }) => {
                                return (
                                    <option value={k}>{name}</option>
                                );
                            })
                            }
                        </select>
                        <br />
                        <br />
                        <label>Select method</label>
                        <select class="custom-select" disabled={typeDisabled} onChange={this.methodSelect} value={this.state.method}>
                            <option value="">Select method</option>

                            {type.map(({ name, k }) => {
                                return (
                                    <option value={k}>{name}</option>
                                );
                            })
                            }
                        </select>
                        <br />
                        <br />

                        <label>Select amount</label>
                        <select class="custom-select" onChange={this.amountSelect}>
                            <option value="">Select amount</option>

                            <option value="1">1</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>
                        <br />
                        <br />

                        <Button onClick={this.searchSelect} >Search</Button>
                    </CardBody>
                </Card>
                {this.state.users&&this.state.users.length > 0 &&
                    <Card >
                        <CardBody>
                        <GenericTable
                            {...this.props}
                            pagination
                            tableData={this.state.users}
                            sizePerPage={10}
                            columnOptions={{
                                "game_id": {
                                    text: 'Game id'
                                },
                            }}

                        />
                        </CardBody>
                    </Card>
                }




            </>);
    }
}

export default withRouter(props => <SelectRandomForm {...props} />);
import React, { Component } from 'react';
import { Button, Card, CardBody, CardFooter, Col, DropdownItem, DropdownMenu, DropdownToggle, Progress, Row, UncontrolledButtonDropdown } from 'reactstrap';
import Loading from '../components/Loading';
import StatisticsChartWidget2 from '../components/StatisticsChartWidget2';
import Tab from '../components/Tab';
import PrizeTypeCountResolver from '../resolvers/PrizeTypeCountResolver';
import VouchersIssuedResolver from '../resolvers/VouchersIssuedResolver';
import { thousandSeperator } from '../utils/Converter';
import ProjectDataPage from './ProjectDataPage';
import Chart from 'react-apexcharts';
import _ from "lodash"
import '../assets/css/Hide-Dropdown-Arrow.css';
import VoucherTable from './VoucherTable';
import StorePicker from '../components/StorePicker';
import { withRouter ,Redirect} from 'react-router-dom';
import ParamState from '../utils/ParamState';
import Sorter from '../components/Sorter';
import StoresResolver from '../resolvers/StoresResolver';
import FeatureCheck from '../components/FeatureCheck';
import checkFeatures from '../utils/checkFeatures';
import GenericTable from '../components/GenericTable';
import MapFacade from "../facade/MapFacade"
import { makeTo } from '../utils/LinkUtils';


const REDEEMED_SORTINGS = {
    "Redeemed": {
        "asc": (a, b) => {
            if (a.count === b.count) {
                return 0
            } else if (a.count > b.count) {
                return 1
            } else {
                return -1
            }
        },
        "desc": (a, b) => {
            if (a.count === b.count) {
                return 0
            } else if (a.count < b.count) {
                return 1
            } else {
                return -1
            }
        }
    }
}

const SORTINGS = {
    "Redeemed": REDEEMED_SORTINGS.Redeemed,
}

class Stores extends ProjectDataPage {
    constructor(props) {
        super("Stores", [
            StoresResolver,
            StoresResolver.full,
        ])

        this.state = Object.assign({ sorter: null, storeID: null, view: "Table" }, this.state)
        const mapRef = props.projectManager.project.mapRef

        MapFacade.getLocations(mapRef).then((locations)=>{
            this.setState({ locations })
        })
    }

    setSorter = async (sorter) => {
        await this.setState({ sorter })
    }

    setView = (view) => {
        if (this.state.view !== view) {
            this.setState({ view })
        }
    }

    handleButton = async(row)=>{
        const { data, match } = this.props
        const { id } = match.params
        this.setState({ redirect: makeTo('/stats/voucher', {search: "?p="+data.projectManager.project.id+"&storeID="+row.id}) })


    }


    render() {

        if (this.state.redirect) {
            return <Redirect push to={this.state.redirect} />
        }


        let sortings = SORTINGS
        let redeemedSortings = REDEEMED_SORTINGS


        let tabs = [
            {
                title: "Table",
                icon: "dripicons-view-list"
            }
            ,
            {
                title: "Best",
                icon: "dripicons-star"
            }
        ]


        let list = [];

        const { stores } = this.props.states
        const { locations } = this.state

        const map = {};

        if(locations){
            locations.map(({id, city,address,lat,lng,name,ref,zipcode})=>{
                map[id] = {
                    name,
                    city,
                    address,
                    zipcode,
                    ref,
                    lat,
                    lng
                }
            })
        }





        if(stores&&locations){

            for(let k in stores){
                list.push({
                    id: k,
                    ref:map[k].ref,
                    city:map[k].city,
                    zipcode:map[k].zipcode,
                    address:map[k].address,
                    count: stores[k].redeemed,
                })
            }

            list = list.sort(sortings['Redeemed']['desc'])


        }


        var sorter = this.state.sorter
        //Sorting
        if (sorter) {
            const sorting = sortings[sorter.name]
            list = list.sort(sorting[sorter.type])
        }




        const listCopy = JSON.parse(JSON.stringify(list));

        let n = 0;

        //Top 5 count product
        const countTop5 = [];
        listCopy.sort((a,b)=>{ if (a.count === b.count) { return 0 } else if (a.count < b.count) { return 1 } else { return -1 } })
        n = 0;
        for(let k in listCopy){
            n++;
            let {city, address, count} = listCopy[k];
            countTop5.push({
                city, address, count
            })
            if(n > 4) break;
        }

        //Bottom 5 count product
        const countBottom5 = [];
        listCopy.sort((a,b)=>{ if (a.count === b.count) { return 0 } else if (a.count > b.count) { return 1 } else { return -1 } })
        n = 0;
        for(let k in listCopy){
            n++;
            let {city, address, count} = listCopy[k];
            countBottom5.push({
                city, address, count
            })
            if(n > 4) break;
        }
        countBottom5.sort((a,b)=>{ if (a.count === b.count) { return 0 } else if (a.count < b.count) { return 1 } else { return -1 } })
        



        return (
            <>
                <Row>
                    <Col xs={4} >
                    </Col>
                    <Col xs={4} className="text-center mb-3" style={{ padding: 0 }}>
                        <FeatureCheck features={["tab", "tab_voucher"]} {...this.props} >
                            <Tab
                                setView={this.setView}
                                view={this.state.view}
                                tabs={tabs}
                                {...this.props}
                            />
                        </FeatureCheck>
                    </Col>
                    <Col xs={4}>
                    
                        <FeatureCheck features={["sort", "sort_voucher"]} {...this.props} >
                            <Sorter sortings={this.state.storeID ? redeemedSortings : sortings} setSorter={this.setSorter} {...this.props} />
                        </FeatureCheck>
                    </Col>
                </Row>
                {this.props.loading || this.state.loading ? <Loading /> :
                    <>

                        {this.state.view === "Table" && <>
                            <FeatureCheck features={["prize", "prize_type"]} {...this.props} >
                            <div>
                <Card>
                    <CardBody>
                                 <GenericTable {...this.props}
                                 pagination
                                        tableData={list}
                                        sizePerPage={10}
                                        actions={{"Details": this.handleButton}}
                        actionsStyle="button"
                                        columnOptions={{
                                            "id": {
                                                hide: true
                                            },
                                            "ref": {
                                                text: "Reference"
                                            },
                                            "count": {
                                                text: "Redeemed"
                                            }
                                        }}></GenericTable>
                                        </CardBody>
                                        </Card>
                                        </div>
                            </FeatureCheck>
                        </>}
                        {this.state.view === "Best" && <>
                            <FeatureCheck features={["prize", "prize_type"]} {...this.props} >
         <Row>
                    

         <Col xs={12} sm={12} md={12} lg={6}>
                                        <Card >
                                            <CardBody>
                                                <div className="text-center">
                                                    <h4>Top 5 Stores</h4>
                                                    <br/>

                                                    <GenericTable {...this.props}
                                                    tableData={countTop5}
                                                    columnOptions={{
                                                        "city": {
                                                            text: "City",
                                                            sort: false,
                                                        }, "address": {
                                                            text: "Address",
                                                            sort: false,
                                                        },
                                                        "count": {
                                                            sort: false,
                                                            text: "Issued",
                                                        }
                                                    }}></GenericTable>
                                                    
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6}>
                                        <Card >
                                            <CardBody>
                                                <div className="text-center">
                                                    <h4>Bottom 5 Stores</h4>
                                                    <br/>
                                                    <GenericTable {...this.props}
                                                    tableData={countBottom5}
                                                    sort
                                                    columnOptions={{
                                                        "city": {
                                                            text: "City",
                                                            sort: false,
                                                        }, "address": {
                                                            text: "Address",
                                                            sort: false,
                                                        },
                                                        "count": {
                                                            sort: false,
                                                            text: "Issued",
                                                        }
                                                    }}></GenericTable>
                                                    
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>



                            </Row>
                            </FeatureCheck>
                        </>}
                    </>
                }
            </>);
    }
}

export default withRouter(props => <Stores {...props} />);
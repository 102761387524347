import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';
import BarChartBrite from '../components/BarChartBrite';
import Loading from '../components/Loading';
import ProjectDataPage from './ProjectDataPage';
import _ from "lodash"
import ChannelTable from './ChannelTable';
import FeatureCheck from '../components/FeatureCheck';
import FormsResolver from '../resolvers/FormsResolver';
import GenericTable from '../components/GenericTable';
import { tableThousandSeperator, tzTableDateFormatter } from '../utils/Converter';
import Form from './Form';


class Forms extends ProjectDataPage {
    constructor(props) {
        super("Forms", [
            FormsResolver,
        ])
    }

    render() {
        const forms = this.props.states.forms
    
        return (
            <>
                {this.props.loading || this.state.loading ? <Loading /> :
                    <>
                        <FeatureCheck features={["forms"]} {...this.props} >
                            {Object.keys(forms).map(formName => {
                                const nameSplit = formName.split("_")
                                let formSimpleName = _.capitalize(nameSplit[nameSplit.length - 1])

                                const formData = forms[formName]

                                return (
                                   <Form formSimpleName={formSimpleName} formData={formData} {...this.props}/>
                                )
                            })}

                        </FeatureCheck>
                        <br />


                    </>
                }
            </>);
    }
}

export default withRouter(props => <Forms {...props} />);
import FacadeConnection from "../FacadeConnection"


class VoucherFacade extends FacadeConnection {
    getUserVouchers = async (dataManager, id) => {
        return await this.get(`/project/voucher/user/${id}`, dataManager)
    }
    getUserForms = async (dataManager, id) => {
        return await this.get(`/project/form/user/${id}`, dataManager)
    }

    apiCall = async (dataManager, prizePoolName, uri) => {
        const { authManager, projectManager } = dataManager
        const projectID = projectManager.project.id
        const { sessionID } = authManager
        const params = {
            prizePoolName
        }
        return await this._send(uri, "POST", params, sessionID, projectID)
    }

    resend = async (dataManager, smsRef, prizePoolName) => {
        return this.apiCall(dataManager, prizePoolName, `/project/voucher/resend/${smsRef}`)
    }

    reactivate = async (dataManager, id, prizePoolName) => {
        return this.apiCall(dataManager, prizePoolName, `/project/voucher/reactivate/${id}`)
    }
    rewallet = async (dataManager, id, prizePoolName) => {
        return this.apiCall(dataManager, prizePoolName, `/project/voucher/rewallet/${id}`)
    }
    reshare = async (dataManager, id, prizePoolName) => {
        return this.apiCall(dataManager, prizePoolName, `/project/voucher/reshare/${id}`)
    }

}
export default new VoucherFacade()
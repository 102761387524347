import ProjectStatistics from "./ProjectStatistics"

export default class Project {
    id
    name
    country
    startDate
    endDate
    projectStats
    timezone
    mapRef
    features
    featureObj = {}

    constructor(pDTO) {
        this.id = pDTO.projectID
        this.name = pDTO.name
        this.country = pDTO.country
        this.startDate = new Date(Date.parse(pDTO.start))
        this.endDate = new Date(Date.parse(pDTO.end))
        // this.projectStats = new ProjectStatistics(pDTO.projectStatistics)
        this.timezone = pDTO.timezone
        this.mapRef = pDTO.mapRef
        this.features = pDTO.features
        this.icon = pDTO.icon
        this.url = pDTO.url

        if (pDTO.features !== undefined && pDTO.features !== null) {
            const { features } = pDTO.features
            if (features) {
                for (const feature of pDTO.features.features) {
                    this.featureObj[feature] = true
                }
            }

        }

    }


    isLive() {
        const dateNow = new Date().getTime()
        return dateNow >= this.startDate && dateNow < this.endDate
    }


}
import React, { Component } from 'react';
import ImageFacade from "../facade/ImageFacade"

export default class CampaignIcon extends Component {
    render() {
        const { projectManager } = this.props
        const { project, campaign } = projectManager
        const { country } = project

        return (
            <div style={{margin: "auto"}}>
                <div
                    className="shadow-lg mb-5 bg-white rounded"
                    style={{
                        height: 70,
                        width: 70,
                        backgroundColor: "#afb4d7",
                        margin: "20px auto 10px 13px",
                        overflow: "hidden",
                        borderRadius: "10px"
                    }}>

                    <img className="card-icon" style={{ width: "100%" }} src={ImageFacade.campaignImage(campaign, project.id)} />
                </div>
                <div
                    style={{
                        height: 30,
                        width: 30,
                        margin: "-152px auto 0px 62px",
                    }}>
                    <img className="rounded-circle shadow bg-white" style={{ width: "100%", border: "1.5px solid white" }} src={ImageFacade.countryImage(country)} />

                </div>
            </div>
        );
    }
}
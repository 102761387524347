import React, { Component } from "react"
import Overview from "./Overview"
import { Redirect, withRouter } from "react-router-dom"
import { makeTo } from "../utils/LinkUtils";
export default withRouter(props => <Home {...props} />)

class Home extends Component {

    render() {
        const { projectManager } = this.props
        const { accesses } = projectManager

        return (
            <>
                {
                    accesses.length === 0||(accesses.status&&accesses.status==500) ?
                        <>
                            <div className="text-center">
                                <h3>No access on project</h3>
                                <h6>Please request access from your super-user to access the project!</h6>
                            </div>
                        </>
                        :
                        <>
                            {
                                accesses.map(a => a.name).indexOf("analytics") !== -1 ?
                                    <Overview {...this.props} />
                                    :
                                    <Redirect to={makeTo(projectManager.getMenuItems()[0].path, this.props.location)} />
                            }
                        </>



                }
            </>
        )
    }
}
import React, { Component } from 'react';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import GenericTable from '../components/GenericTable';
import { tableThousandSeperator } from '../utils/Converter';

class ReturningTable extends Component {
    render() {

        const data = this.props.states.gameReturningCount.map(({count, day}) => {
            return { day, count }
        })
        return (
            <div>
                <Card style={{ maxWidth: 500, margin: "auto" }}>
                    <CardBody>
                        <GenericTable
                            {...this.props}
                            pagination
                            tableData={data}
                            sizePerPage={10}
                            columnOptions={{
                                "day": {
                                    text: "Days",
                                    sort: false,
                                    formatter: tableThousandSeperator

                                },
                                "count": {
                                    text: "Number of Users Returned to Game",
                                    formatter: tableThousandSeperator,
                                    sort: false,
                                }
                            }}

                        />
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default ReturningTable;


import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, Container, Nav, NavItem, ButtonGroup, Button } from 'reactstrap';
import Loading from '../components/Loading';
import MultiChart from "../components/MultiChart"
import GameCompletedCountResolver from '../resolvers/GameCompletedCountResolver';
import GameWinCountResolver from '../resolvers/GameWinCountResolver';
import GameCountResolver from '../resolvers/GameCountResolver';
import ProjectDataPage from './ProjectDataPage';
import UserCountResolver from '../resolvers/UserCountResolver';
import VouchersIssuedResolver from '../resolvers/VouchersIssuedResolver';
import VouchersRedeemedResolver from '../resolvers/VouchersRedeemedResolver';
import SplineAreaChart from '../components/SplineAreaChart';
import UserActiveCountResolver from '../resolvers/UserActiveCountResolver';
import Tab from '../components/Tab';
import EngagementTable from './EngagementTable';
import FeatureCheck from '../components/FeatureCheck';
import checkFeatures from '../utils/checkFeatures';
import TwofactorSentCountResolver from '../resolvers/TwofactorSentCountResolver';
import TwofactorCompletedCountResolver from '../resolvers/TwofactorCompletedCountResolver';
import TwofactorFailedCountResolver from '../resolvers/TwofactorFailedCountResolver';
import TwofactorNotRegisteredCountResolver from '../resolvers/TwofactorNotRegisteredCountResolver';
import TwofactorNotUsedCountResolver from '../resolvers/TwofactorNotUsedCountResolver';
import VouchersActiveResolver from '../resolvers/VouchersActiveResolver';

class Engagement extends ProjectDataPage {

    constructor() {
        super("Engagement", [
            GameCountResolver.full,
            GameCompletedCountResolver.full,
            GameWinCountResolver.full,
            UserCountResolver.full,
            UserActiveCountResolver.full,
            VouchersIssuedResolver.full,
            VouchersActiveResolver.full,
            VouchersRedeemedResolver.full,
            TwofactorSentCountResolver.full,
            TwofactorCompletedCountResolver.full,
            TwofactorFailedCountResolver.full,
            TwofactorNotRegisteredCountResolver.full,
            TwofactorNotUsedCountResolver.full
        ])

        this.state = Object.assign({ view: "Charts" }, this.state)
    }

    setView = (view) => {
        if (this.state.view !== view) {
            this.setState({ view })
        }
    }


    


    render() {
        const isLoading = this.props.loading || this.state.loading

        let gameFullStats = []
        let userFullStats = []
        let voucherFullStats = []
        let twofactorFullStats = []

        if (!isLoading) {
            if (checkFeatures(this.props, ["game", "game_win"])) {
                gameFullStats.push({
                    name: "Games Won",
                    data: this.props.fullChartStats.gameWinCount,
                })
            }

            if (checkFeatures(this.props, ["game", "game_completed"])) {
                gameFullStats.push({
                    name: "Games Completed",
                    data: this.props.fullChartStats.gameCompletedCount,
                })
            }

            if (checkFeatures(this.props, ["game"])) {
                gameFullStats.push({
                    name: "Games",
                    data: this.props.fullChartStats.gameCount,
                })
            }

            if (checkFeatures(this.props, ["user", "user_active"])) {
                userFullStats.push({
                    name: "Active",
                    data: this.props.fullChartStats.userActiveCount,
                })
            }

            if (checkFeatures(this.props, ["user"])) {
                userFullStats.push({
                    name: "New", 
                    data: this.props.fullChartStats.userCount,
                })
            }

            if (checkFeatures(this.props, ["voucher"])) {
                voucherFullStats.push({
                    name: "Issued",
                    data: this.props.fullChartStats.vouchersIssued,
                })
            }

            if (checkFeatures(this.props, ["voucher", "voucher_redeemed"])) {
                voucherFullStats.push({
                    name: "Redeemed",
                    data: this.props.fullChartStats.vouchersRedeemed,
                })
            }
            
            if (checkFeatures(this.props, ["voucher", "voucher_active"])) {
                voucherFullStats.push({
                    name: "Active",
                    data: this.props.fullChartStats.vouchersActive,
                })
            }

            if (checkFeatures(this.props, ["twofactor", "twofactor_sent"])) {
                twofactorFullStats.push({
                    name: "Sent",
                    data: this.props.fullChartStats.twofactorSentCount,
                })
            }

            if (checkFeatures(this.props, ["twofactor", "twofactor_completed"])) {
                twofactorFullStats.push({
                    name: "Completed",
                    data: this.props.fullChartStats.twofactorCompletedCount,
                })
            }

            if (checkFeatures(this.props, ["twofactor", "twofactor_failed"])) {
                twofactorFullStats.push({
                    name: "Failed",
                    data: this.props.fullChartStats.twofactorFailedCount,
                })
            }

            if (checkFeatures(this.props, ["twofactor", "twofactor_not_registered"])) {
                twofactorFullStats.push({
                    name: "Not Registered",
                    data: this.props.fullChartStats.twofactorNotRegisteredCount,
                })
            }

            if (checkFeatures(this.props, ["twofactor", "twofactor_not_used"])) {
                twofactorFullStats.push({
                    name: "Not Used",
                    data: this.props.fullChartStats.twofactorNotUsedCount,
                })
            }


        }

        return (
            <>
                <FeatureCheck features={["tab", "tab_engagement"]} {...this.props} >
                    <Row>
                        <Col xs={12} className="text-center mb-3">
                            <Tab
                                setView={this.setView}
                                view={this.state.view}
                                tabs={[
                                    {
                                        title: "Charts",
                                        icon: "dripicons-pulse"
                                    },
                                    {
                                        title: "Table",
                                        icon: "dripicons-view-list"
                                    }
                                ]}
                            />
                        </Col>
                    </Row>
                </FeatureCheck>
                {isLoading ? <Loading /> :
                    <>
                        {this.state.view === "Charts" && <>
                            <FeatureCheck features={["game"]} checkIfEmpty={gameFullStats} {...this.props} >
                                <Row>
                                    <Col xs={12} >
                                        <MultiChart
                                            title="Games"
                                            centeredTitle
                                            categories={this.props.fullChartStatsStrings}
                                            stats={gameFullStats}
                                            pickDate={this.props.pickDate}
                                            resetDate={this.props.resetDate}
                                        />
                                    </Col>

                                </Row>
                            </FeatureCheck>
                            <FeatureCheck features={["user"]} checkIfEmpty={userFullStats} {...this.props} >
                                <Row>
                                    <Col xs={12}>
                                        <MultiChart
                                            title="Users"
                                            centeredTitle
                                            categories={this.props.fullChartStatsStrings}
                                            stats={userFullStats}
                                            pickDate={this.props.pickDate}
                                            resetDate={this.props.resetDate}
                                        />
                                    </Col>
                                </Row>
                            </FeatureCheck>
                            <FeatureCheck features={["voucher"]} checkIfEmpty={voucherFullStats} {...this.props} >
                                <Row>
                                    <Col xs={12}>
                                        <MultiChart
                                            title="Vouchers"
                                            centeredTitle
                                            categories={this.props.fullChartStatsStrings}
                                            stats={voucherFullStats}
                                            pickDate={this.props.pickDate}
                                            resetDate={this.props.resetDate}
                                        />
                                    </Col>
                                </Row>
                            </FeatureCheck>
                            <FeatureCheck features={["twofactor"]} checkIfEmpty={twofactorFullStats} {...this.props} >
                                <Row>
                                    <Col xs={12}>
                                        <MultiChart
                                            title="Twofactor"
                                            centeredTitle
                                            categories={this.props.fullChartStatsStrings}
                                            stats={twofactorFullStats}
                                            pickDate={this.props.pickDate}
                                            resetDate={this.props.resetDate}
                                        />
                                    </Col>
                                </Row>
                            </FeatureCheck>
                        </>}
                        {this.state.view === "Table" && <EngagementTable
                            {...this.props}
                        />
                        }

                    </>

                }

            </>
        );
    }
}

export default Engagement;
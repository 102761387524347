import FacadeConnection from "../FacadeConnection";
import _ from "lodash";

class SelectRandom extends FacadeConnection {

    constructor() {
        super()
    }
    getUser = async (dataManager,  source, method, amount) => {
        const { authManager, projectManager,start, end } = dataManager
        const projectID = projectManager.project.id
        const { sessionID } = authManager
        const params = {
            source, method, amount,start,end
        }

        return await (await this._send("/project/selectRandom/user", "GET", params, sessionID, projectID)).json()
    }

}

export default new SelectRandom()
import React from 'react';
import Chart from 'react-apexcharts';
import { Card, CardBody } from 'reactstrap';

// SplineAreaChart
const SplineAreaChart = (props) => {
    const apexAreaChart1Opts = {
        chart: {
            height: "auto",
            type: 'area',
            toolbar: {
                show: true,
                // autoSelected: 'zoom',
                tools: {
                    customIcons: [{
                        icon: '<img src="/reset-view.png" width="16">',
                        index: 4,
                        title: 'tooltip of the icon',
                        class: 'reset-view',
                        click: function (chart, options, e) {
                            chart.resetSeries(true, true)
                        }
                    }],
                    download: true,
                    selection: false,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: false | '<img src="/reset-view.png" width="20">',

                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 3,
            curve: 'smooth',
        },
        colors: ['#8492fa', '#38469b', '#19191b'],
        legend: {
            offsetY: -10,
        },
        xaxis: {
            categories: props.categories,
        },
        tooltip: {
            fixed: {
                enabled: false,
                position: 'topRight',
            },
        },
        grid: {
            row: {
                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.2,
            },
            borderColor: '#f1f3fa',
        },
    };


    return (
        <Card>
            <CardBody style={{ padding: "14px" }}>
                <>
                    {props.centeredTitle ?
                        <h2 class="text-center">{props.title}</h2>
                        :
                        <h4 className="header-title mb-3">{props.title}</h4>
                    }
                    {/* <h4 className="header-title mb-3">{props.title}</h4> */}
                    <Chart
                        options={apexAreaChart1Opts}
                        series={props.stats}
                        type="area"
                        className="apex-charts  mt-3"
                        // height={388}
                        height={376}
                    />
                </>

            </CardBody>
        </Card>
    );
};

export default SplineAreaChart;

import { getUserLocale,getUserLocales } from 'get-user-locale';
import React from "react"
import moment from "moment-timezone"
import tzMoment from "./tzMoment"
import _ from "lodash"

// function replaceWithDots(string) {
//     return string.replace(/\//ig, ".")
// }

function getParam(name, str = window.location.hash) {
    var objURL = {};
    str.replace(
        new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
        function( $0, $1, $2, $3 ){
            objURL[ $1 ] = $3;
        }
    );
    
    if(typeof name === 'string'){
        return typeof objURL[name] === 'string' ? objURL[name] : false;
    } else {
        return objURL;
    }
};


export function epochToLocaleString(epoch) {
    return moment(epoch).format(`DD.MM.YYYY`)
}

export const epochToTimezoneString = (timezone) => (epoch) => _epochToTimezoneString(epoch, timezone)
export function _epochToTimezoneString(epoch, timezone) {
    return tzMoment(null, timezone)(epoch).format(`DD.MM.YYYY`)
}

export function thousandSeperator(number) {
    let type = 'en';
    
    if(moment.tz.guess().indexOf('Europe') != -1){
        type = 'da-dk';
    }
    let ov = getParam('format');
    if(ov) type = ov;

    // return Number(number).toLocaleString(getUserLocale())
    return Number(number).toLocaleString(type)
}

export function secondsToTime(seconds) {
    
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);


    let result = ""
    if (d > 0) result += `${_.floor(d)}d`
    if (h > 0) result += ` ${_.floor(h)}h`
    if (m > 0) result += ` ${_.floor(m)}m`
    if (s > 0) result += ` ${_.floor(s)}s`

    return result
}

export const dateFormatter = (timezone) => (value) => _dateFormatter(value, timezone)
const _dateFormatter = (value, timezone) => {
    const mm = tzMoment(null, timezone)(value)
    if (mm.isValid()) {

        let type = 'en';
    



        if(moment.tz.guess().indexOf('Europe') != -1){
            type = 'da-dk';
        }
        let ov = getParam('format');
        if(ov) type = ov;


        if(type == 'en'){
            return `${mm.format(`MM.DD.YYYY - h:mm:ss a`)}`

        } else {
            return `${mm.format(`DD.MM.YYYY - HH:mm:ss`)}`

        }



        // const date = mm.toDate()
        // return `${replaceWithDots(date.toLocaleDateString())} - ${date.toLocaleTimeString()}`
    } else {
        return null
    }

}

export const tzTableDateFormatter = (timezone = moment.tz.guess()) => (cell, row) => _tableDateFormatter(cell, row, timezone)
const _tableDateFormatter = (cell, row, timezone) => {
    const formatted = dateFormatter(timezone)(cell)
    if (formatted) {
        return (
            <span>
                {formatted}
            </span>
        )
    } else {
        return null
    }
}

export const tableThousandSeperator = (cell, row) => {


    let formatted = thousandSeperator(cell)
    if(typeof cell == 'string'){
        formatted = cell;
    }

    if (formatted) {
        return (
            <span>
                {formatted}
            </span>
        )
    } else {
        return null
    }
}


export default {
    epochToLocaleString,
    thousandSeperator,
    tzTableDateFormatter,
}
import React, { Component } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Table } from 'reactstrap';
import FeatureCheck from '../components/FeatureCheck';
import Loading from '../components/Loading';
import Sorter from '../components/Sorter';
import MapFacade from '../facade/MapFacade';
import PrizesChangedResolver from '../resolvers/PrizesChangedResolver';
import { thousandSeperator } from '../utils/Converter';
import ProjectDataPage from './ProjectDataPage';
import _ from "lodash"
import { Redirect, withRouter } from 'react-router-dom';
import { makeTo } from '../utils/LinkUtils';
import ParamState from '../utils/ParamState';
import checkFeatures from '../utils/checkFeatures';

const sortings = {
    "Prizes Changed": {
        "asc": (a, b) => {
            if (a.changed === b.changed) {
                return 0
            } else if (a.changed > b.changed) {
                return 1
            } else {
                return -1
            }
        },
        "desc": (a, b) => {
            if (a.changed === b.changed) {
                return 0
            } else if (a.changed < b.changed) {
                return 1
            } else {
                return -1
            }
        }
    },
    "Percent Changed": {
        "asc": (a, b) => {
            if (a.percent === b.percent) {
                return 0
            } else if (a.percent > b.percent) {
                return 1
            } else {
                return -1
            }
        },
        "desc": (a, b) => {
            if (a.percent === b.percent) {
                return 0
            } else if (a.percent < b.percent) {
                return 1
            } else {
                return -1
            }
        }
    },
    "Redeemed": {
        "asc": (a, b) => {
            if (a.redeemed === b.redeemed) {
                return 0
            } else if (a.redeemed > b.redeemed) {
                return 1
            } else {
                return -1
            }
        },
        "desc": (a, b) => {
            if (a.redeemed === b.redeemed) {
                return 0
            } else if (a.redeemed < b.redeemed) {
                return 1
            } else {
                return -1
            }
        }
    }
}

class PrizesChanged extends ProjectDataPage {
    constructor(props) {
        super("Prizes Changed", [
            PrizesChangedResolver
        ])

        this.state = Object.assign({
            locations: null,
            input: {
                search: null
            }
        }, this.state)

        this.ps = new ParamState(() => props, {
            storeID: null
        })

    }

    componentDidMountExtended = async () => {
        const mapRef = this.props.projectManager.project.mapRef
        const locationList = await MapFacade.getLocations(mapRef)
        const locations = {}

        for (const location of locationList) {
            locations[location.id] = location
        }

        this.setState({ locations })
    }

    handleKeyPressed = (e) => {
        let input = Object.assign({}, this.state.input)
        input[e.target.name] = e.target.value
        this.setState({ input })
    }

    setSorter = async (sorter) => {
        await this.setState({ sorter })
    }

    handleVoucherClick = async (mapID) => {
        //making sure to unmount table so the lifecycle method in it gets fired
        await this.ps.setStoreid(mapID)
        this.setState({ redirect: makeTo(`/stats/voucher`, this.props.location) })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to={{
                pathname: this.state.redirect,
                state: { from: this.props.location }
            }} />
        }

        var prizesChangedMaps = []
        
        const isLoading = this.props.loading || this.state.loading
        if (!isLoading && checkFeatures(this.props, ["change_prize"])) {
            prizesChangedMaps = Object.keys(this.props.states.prizesChangedMap).map(key => {
                const mapData = this.props.states.prizesChangedMap[key]
                const mapObject = this.state.locations[key]
                let map = Object.assign(mapData, mapObject)
                map["percent"] = map["changed"] / map["redeemed"]
                return map
            })

            let { search } = this.state.input
            if (search !== null) {
                prizesChangedMaps = prizesChangedMaps.filter(({ name, ref, address, city,zipcode, id }) => {
                    if (name) {
                        search = search.toLowerCase()
                        if (name.toLowerCase().search(search) !== -1) return true
                        if (address.toLowerCase().search(search) !== -1) return true
                        if (city.toLowerCase().search(search) !== -1) return true
                        if (zipcode.toLowerCase().search(search) !== -1) return true
                        if (ref.toLowerCase().search(search) !== -1) return true
                        if (id.toLowerCase().search(search) !== -1) return true
                    }
                    return false
                })
            }

            var sorter = this.state.sorter
            //Sorting
            if (sorter) {
                const sorting = sortings[sorter.name]
                prizesChangedMaps = prizesChangedMaps.sort(sorting[sorter.type])
            } else {
                prizesChangedMaps = prizesChangedMaps.sort(sortings['Percent Changed'].desc)
            }

        }



        return (
            <div>
                {isLoading ? <Loading /> :
                    <>
                        <Row>
                            <Col xs={10}>
                                <FeatureCheck features={["search", "search_change"]} {...this.props}>
                                    <div className="app-search" style={{ maxWidth: (window.innerWidth * 0.58) }}>
                                        <form onSubmit={e => e.preventDefault()}>
                                            <div className="input-group" style={{ height: "48px", marginTop: -19 }}>
                                                <input type="text" className="form-control" placeholder="Search..."
                                                    onChange={this.handleKeyPressed}
                                                    name="search"
                                                    id="search"
                                                    value={this.state.input.search}
                                                    style={{ height: "100%" }}
                                                />
                                                <span className="dripicons-search" style={{ marginTop: 3 }}></span>
                                            </div>
                                        </form>
                                    </div>
                                </FeatureCheck>
                            </Col>
                            <Col xs={2}>
                                <FeatureCheck features={["sort", "sort_change"]} {...this.props} >
                                    <Sorter sortings={sortings} setSorter={this.setSorter} {...this.props} />
                                </FeatureCheck>
                            </Col>
                        </Row>
                        <FeatureCheck features={["change_prize"]} {...this.props}>
                            {prizesChangedMaps.map(({ name, ref, tags, address,city,zipcode, lat,lng, id, redeemed, changed, percent }) => {
                                let redeem_percent = _.round(percent * 100, 1);
                                if(redeem_percent == 'Infinity') redeem_percent = 0;

                                return <>
                                    {/* <a style={{ cursor: "pointer" }}> */}
                                    <Card className="standard-shadow">
                                        <CardHeader>
                                            {name ?
                                                <>
                                                    <div style={{ float: "right" }} >
                                                        < Button style={{ marginRight: 10 }} size="lg" color="light" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}>
                                                            <i className="dripicons-location" style={{ fontSize: 18 }}></i>
                                                        </Button>

                                                        <FeatureCheck features={["voucher", "prize"]} {...this.props} >
                                                            <Button size="lg" color="info" onClick={() => this.handleVoucherClick(id)} >
                                                                <i className="dripicons-ticket text-light" style={{ fontSize: 18 }}></i>
                                                            </Button>
                                                        </FeatureCheck>
                                                    </div>

                                                    <h4>{name}</h4>
                                                </>
                                                :
                                                <h4>Unknown Store</h4>
                                            }
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col xs={12} md={6} lg={5}>
                                                    {address ?
                                                        <Table borderless>
                                                            <tbody>
                                                                <tr>
                                                                    <th>Address</th>
                                                                    <td>{address}, {city}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Zipcode</th>
                                                                    <td>{zipcode}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Reference</th>
                                                                    <td>{ref}</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                        :
                                                        <h5>Unknown Address</h5>
                                                    }
                                                </Col>
                                                <Col xs={12} md={6} lg={7} className="text-center">
                                                    <Row>
                                                        <Col xs={6} >
                                                            <i className="dripicons-swap widget-icon bg-primary-lighten text-primary"></i>
                                                            <h3 className="font-weight-normal mt-3">
                                                                <span>{thousandSeperator(changed)} of {thousandSeperator(redeemed)}</span>
                                                            </h3>
                                                            <p className="text-muted mb-0 mb-2">
                                                                <i className="mdi mdi-checkbox-blank-circle text-primary"></i> Prizes Changed
</p>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <span className="widget-icon bg-info-lighten text-info">%</span>
                                                            <h3 className="font-weight-normal mt-3">
                                                                <span>{redeem_percent}%</span>
                                                            </h3>
                                                            <p className="text-muted mb-0 mb-2">
                                                                <i className="mdi mdi-checkbox-blank-circle text-info"></i> Percent Changed
</p>
                                                        </Col>
                                                    </Row>

                                                </Col>
                                            </Row>

                                        </CardBody>
                                        {tags && tags.length > 0 && <CardFooter>

                                            <p>Tags: {tags.join(",")}</p>
                                        </CardFooter>}

                                    </Card>
                                    {/* </a> */}

                                </>
                            })}
                        </FeatureCheck>

                        {prizesChangedMaps.length === 0 && <h5 className="text-center mt-2">No result</h5>}

                    </>
                }
            </div>
        );
    }
}

export default withRouter(props => <PrizesChanged {...props} />)

import { capitaliseFirstLetter } from '@fullcalendar/core';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import MapFacade from "../facade/MapFacade"
import ParamState from '../utils/ParamState';
import Loading from './Loading';


class StorePicker extends Component {
    state = {
        isOpen: false,
        locations: null,
        input: {
            search: null
        }
    }

    constructor(props) {
        super(props)

        this.ps = new ParamState(() => this.props, {
            storeID: null
        })
    }



    toggle = async (state) => {
        await this.setState({ isOpen: state });

        if (this.state.locations === null) {
            const mapRef = this.props.projectManager.project.mapRef
            const locations = await MapFacade.getLocations(mapRef)
            await this.setState({ locations })
        }
    };

    handleKeyPressed = (e) => {
        let input = Object.assign({}, this.state.input)
        input[e.target.name] = e.target.value
        this.setState({ input })
    }

    setStoreID = async (storeID) => {
        await this.toggle(false)
        await this.ps.setStoreid(storeID)
        await this.props.setStoreID(storeID)
        await this.props.update()

    }

    clearStoreID = async () => {
        await this.toggle(false)
        await this.ps.setStoreid(null)
        await this.props.setStoreID(null)
        await this.props.update()
    }

    componentDidMount = async () => {
        const storeID = await this.ps.getStoreid()
        if (storeID) {
            await this.props.setStoreID(storeID)
        }
    }

    componentWillUnmount = async () => {
        await this.clearStoreID()
    }

    render() {
        let locations = this.state.locations
        let storeChosen = null

        let { search } = this.state.input
        if (this.state.locations) {
            const storeID = this.ps.getStoreid()

            if (storeID !== null) {
                const storeFoundIndex = this.state.locations.map(l => l.id).indexOf(storeID)
                if (storeFoundIndex !== -1) {
                    storeChosen = this.state.locations[storeFoundIndex]
                }
            }

            if (search !== null) {
                locations = this.state.locations.filter(({ name, ref, address,city,zipcode, id }) => {
                    search = search.toLowerCase()
                    if (name.toLowerCase().search(search) !== -1) return true
                    if (address.toLowerCase().search(search) !== -1) return true
                    if (city.toLowerCase().search(search) !== -1) return true
                    if (zipcode.toLowerCase().search(search) !== -1) return true
                    if (ref.toLowerCase().search(search) !== -1) return true
                    if (id.toLowerCase().search(search) !== -1) return true
                    return false
                })
            }

        }



        return (
            <>

                <Button style={this.props.style} disabled={this.props.loading} size="lg" color={this.ps.getStoreid() !== null ? "info text-white" : "light"} onClick={() => this.toggle(true)}>
                    <i className="dripicons-store" style={{ fontSize: 18 }}></i>
                </Button>


                <Modal isOpen={this.state.isOpen} toggle={() => this.toggle(false)} scrollable className="modal-right" style={{ maxWidth: 600, width: "100%" }}>
                    <ModalHeader toggle={() => this.toggle(false)} >
                        <h3>Choose Store</h3>

                        <Container fluid style={{ width: "100%" }}>
                            <Row>
                                <Col>
                                    <div className="app-search">
                                        <form onSubmit={e => e.preventDefault()}>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search..."
                                                    onChange={this.handleKeyPressed}
                                                    name="search"
                                                    id="search"
                                                    value={this.state.input.search}
                                                />
                                                <span className="dripicons-search"></span>
                                            </div>
                                        </form>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    {storeChosen && <>
                                        <Card style={{ margin: "auto", width: "100%" }} className="text-white bg-info">
                                            <CardBody>
                                                <i className="dripicons-cross" style={{ float: "right", cursor: "pointer" }} onClick={this.clearStoreID}></i>

                                                <h4>{storeChosen.name}</h4>
                                                <a className="mt-2 text-white ">{storeChosen.address}, {storeChosen.city}, {storeChosen.zipcode}</a>
                                            </CardBody>
                                        </Card>
                                    </>}

                                </Col>
                            </Row>
                        </Container>



                    </ModalHeader>
                    <ModalBody className={this.state.bodyClass} style={{ minHeight: window.innerHeight - 200 }}>
                        {locations ?
                            <>

                                {locations.map(({ name, ref, tags, address,zipcode,city, lat, lng, id }) => {

                                    return <>
                                        <a style={{ cursor: "pointer" }}>
                                            <Card className="standard-shadow" onClick={() => this.setStoreID(id)}>
                                                <CardHeader>
                                                    <Button size="lg" color="light" style={{ float: "right" }} target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}>
                                                        <i className="dripicons-location" style={{ fontSize: 18 }}></i>
                                                    </Button>
                                                    <h4>{name}</h4>
                                                </CardHeader>
                                                <CardBody>
                                                    <Table borderless>
                                                        <tbody>
                                                            <tr>
                                                                <th>Address</th>
                                                                <td>{address}, {city}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Zipcode</th>
                                                                <td>{zipcode}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Reference</th>
                                                                <td>{ref}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                                {tags.length > 0 && <CardFooter>
                                                    <p>Tags: {tags.join(",")}</p>
                                                </CardFooter>}

                                            </Card>
                                        </a>

                                    </>
                                })}
                                {locations.length === 0 && <h5 className="text-center mt-2">No result</h5>}
                            </>
                            :
                            <Loading />
                        }
                        <br />
                        <br />
                        <br />
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

export default withRouter(props => <StorePicker {...props} />);
import _ from "lodash"

class ProjectDataInfo {
    _name
    resolver
    type
    requiredFeatures
    _tablewise

    constructor(name, resolver, type = null, requiredFeatures = [], tablewise = false) {
        this._name = name
        this.resolver = resolver
        this.type = type
        this.requiredFeatures = requiredFeatures
        this._tablewise = tablewise
    }

    get name() {
        if (!this.type || this.type === "lastWeek" || this.type === "full") {
            return `${this._name}${this._tablewise === true ? "Tablewise" : ""}`
        } else {
            return `${this._name}${_.capitalize(this.type)}${this._tablewise ? "Tablewise" : ""}`
        }
    }

    get tablewise() {
        return this._tablewise
    }

}

export default class ProjectDataResolver extends ProjectDataInfo {

    constructor(name, resolver, type = null, requiredFeatures = [], tablewise = false) {
        super(name, resolver, type, requiredFeatures, tablewise)
    }

    get full() {
        return this.clone("full")
    }

    get lastWeek() {
        return this.clone("lastWeek")
    }

    get trend() {
        return this.clone("trend")
    }

    get today() {
        return this.clone("today")
    }

    get tablewiseResults() {
        return this.clone(this.type, true)
    }

    clone(type, tablewise = this._tablewise) {
        return new ProjectDataResolver(this._name, this.resolver, type, this.requiredFeatures, tablewise)
    }
}
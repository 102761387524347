
import React from 'react';

class FeatureCheck extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch = () => {
        this.setState({hasError: true})
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <p>Something went wrong.</p>;
        }

        const { props } = this

        const { projectManager: { project: { featureObj } } } = props

        let disabled = false

        if (props.features) {
            for (const feature of props.features) {
                if (!featureObj[feature]) {
                    disabled = true
                    break
                }
            }
        }

        if (!disabled && props.checkIfEmpty && props.checkIfEmpty.length === 0) {
            disabled = true
        }

        return (
            <>
                {disabled ? null : props.children}
            </>
        );
    }
}

export default FeatureCheck;
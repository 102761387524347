import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Spinner } from "reactstrap"
import notFoundImg from '../assets/images/sad-bm.png';
import timeout from "../utils/timeout"

const PATIENCE_MESSAGES = [
    "No more beans... Gotta go get 'em beans",
    "Feeling tired? Let's get some coffee while waiting",
    "Please sit back and wait patiently ☕️",
    "Please sit back and wait patiently ☕️",
    "Please sit back and wait patiently ☕️",
    "Please wait while we brew the coffee",
    "Please wait while we brew the coffee",
    "Please wait while we brew the coffee",
    "The coffee will not make itself",
    "Mmmhhh... Coffee",
    "Coffee is good",
    "Very nice coffee taste",
    "Double shot or single shot?",
    `☕️`,
    `☕️☕️`,
    `☕️☕️☕️`,
    "Patience is almost better than coffee... but only almost better",
    "Patience is almost better than coffee... but only almost better"
]

class Loading extends Component {
    state = {
        failed: false,
        patienceCount: 0
    }

    async componentDidMount() {
        for (let count = 0; count < 20; count++) {
            await timeout(5000)
            this.setState({ patienceCount: this.state.patienceCount + 1 })
        }
        await timeout(5000)
        this.setState({ failed: true })
    }

    pickRandomPatienceMessage() {
        const randomIndex = Math.floor(Math.random() * PATIENCE_MESSAGES.length)
        return PATIENCE_MESSAGES[randomIndex]
    }

    staticPatienceMessage(){
        return 'Loading. Please wait'
    }

    render() {
        return (

            <div className="text-center" style={{ marginTop: 50, height: "100%", minHeight: window.innerHeight, padding: 20 }}>
                {this.state.failed ? <>
                    <img src={notFoundImg} height="140" alt="" />
                    {/* <h1 className="text-error mt-4">404</h1> */}
                    <h4 className="text-uppercase text-primary mt-3">Something went wrong!</h4>
                    <p className="text-muted mt-3">
                        It seems like we didn't get a response from the server in time. Please try to reload the page or try again later.
                        </p>

                    <Button className="btn btn-rounded btn-secondary mt-3" style={{ backgroundColor: "#38469b", border: 0 }} onClick={() => window.location.reload()}>
                        <i className="mdi mdi-reload"></i> Reload page
                        </Button>
                </> :
                    <>
                        <Spinner
                            className="text-primary m-2 "
                            size={"lg"}
                            style={{ margin: "auto", height: 100, width: 100 }} />
                        <br />
                        {this.state.patienceCount > 0 && this.staticPatienceMessage()}
                    </>
                }
            </div>
        );
    }
}

export default Loading;


import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Row, Col, Container } from 'reactstrap';
import StatisticCard from '../components/StatisticCard';
import Loader from '../hyper_components/Loader';
import Loading from '../components/Loading';
import DataFacade from '../facade/project/DataFacade';
import DatePickerBar from '../components/DatePickerBar';
import MultiChart from "../components/MultiChart"
import PolarChart from '../components/PolarChart';
import SplineAreaChart from '../components/SplineAreaChart';
import DataManager from '../managers/DataManager';
import BarChart from '../components/BarChart';
import VouchersRedeemedResolver from '../resolvers/VouchersRedeemedResolver';
import VouchersActiveResolver from '../resolvers/VouchersActiveResolver';
import UserCountResolver from '../resolvers/UserCountResolver';
import CKExtraUserCountResolver from '../resolvers/CKExtraUserCountResolver';
import VouchersIssuedResolver from '../resolvers/VouchersIssuedResolver';
import GameCompletedCountResolver from '../resolvers/GameCompletedCountResolver';
import SessionCountResolver from '../resolvers/SessionCountResolver';
import SessionReferrerCountTopResolver from '../resolvers/SessionReferrerCountTopResolver';
import PrizeTypeCountResolver from '../resolvers/PrizeTypeCountResolver';
import PrizeCountResolver from '../resolvers/PrizeCountResolver';
import PrizeReshuffledCountResolver from '../resolvers/PrizeReshuffledCountResolver';
import GameWinCountResolver from '../resolvers/GameWinCountResolver';
import GameCountResolver from '../resolvers/GameCountResolver';
import ProjectDataPage from './ProjectDataPage';
import { Donut, Legend } from 'britecharts-react';
import BarChartBrite from '../components/BarChartBrite';
import _ from "lodash";
import FeatureCheck from '../components/FeatureCheck';
import checkFeatures from '../utils/checkFeatures';
import TwofactorSentCountResolver from '../resolvers/TwofactorSentCountResolver';
import TwofactorCompletedCountResolver from '../resolvers/TwofactorCompletedCountResolver';
import TwofactorFailedCountResolver from '../resolvers/TwofactorFailedCountResolver';
import TwofactorNotRegisteredCountResolver from '../resolvers/TwofactorNotRegisteredCountResolver';
import TwofactorNotUsedCountResolver from '../resolvers/TwofactorNotUsedCountResolver';
import RadialCard from './RadialCard';
import GameAvgResolver from '../resolvers/GameAvgResolver';
import { secondsToTime } from "../utils/Converter"
import GameUniqueWinCountResolver from '../resolvers/GameUniqueWinCountResolver';
import SessionDeviceOSCountTopResolver from '../resolvers/SessionDeviceOSCountTopResolver';
import SessionDeviceBrandCountTopResolver from '../resolvers/SessionDeviceBrandCountTopResolver';
import SessionDeviceModelCountTopResolver from '../resolvers/SessionDeviceModelCountTopResolver';
import SessionDeviceBrowserCountTopResolver from '../resolvers/SessionDeviceBrowserCountTopResolver';
import UserPermissionResolver from '../resolvers/UserPermissionResolver';
import UserExtraCountResolver from '../resolvers/UserExtraCountResolver';
import UserActiveCount from '../resolvers/UserActiveCountResolver';
import LiveUserCount from '../resolvers/LiveUserCountResolver';
import { thousandSeperator } from '../utils/Converter';

class Overview extends ProjectDataPage {
    constructor() {
        super("Overview", [
            VouchersRedeemedResolver,
            VouchersRedeemedResolver.trend,
            VouchersRedeemedResolver.lastWeek,
            VouchersActiveResolver,
            VouchersActiveResolver.today,
            VouchersActiveResolver.trend,
            VouchersIssuedResolver,
            VouchersIssuedResolver.trend,
            VouchersIssuedResolver.lastWeek,
            UserCountResolver,
            UserCountResolver.trend,
            UserCountResolver.lastWeek,
            UserPermissionResolver,
            UserPermissionResolver.trend,
            UserPermissionResolver.today,

            UserExtraCountResolver,
            UserExtraCountResolver.trend,
            UserExtraCountResolver.today,
            CKExtraUserCountResolver,

            UserActiveCount,
            UserActiveCount.today,
            LiveUserCount,

            GameCompletedCountResolver,
            GameCompletedCountResolver.tablewiseResults,
            GameCountResolver.tablewiseResults,
            GameWinCountResolver.tablewiseResults,
            GameCompletedCountResolver.trend,
            GameCompletedCountResolver.tablewiseResults.trend,
            GameCountResolver.tablewiseResults.trend,
            GameWinCountResolver.tablewiseResults.trend,
            GameCompletedCountResolver.lastWeek,
            GameCompletedCountResolver.tablewiseResults.lastWeek,
            GameCountResolver.tablewiseResults.lastWeek,
            GameWinCountResolver.tablewiseResults.lastWeek,
            GameUniqueWinCountResolver,
            GameUniqueWinCountResolver.trend,
            GameUniqueWinCountResolver.lastWeek,
            GameAvgResolver,
            GameAvgResolver.tablewiseResults,
            SessionCountResolver,
            SessionCountResolver.trend,
            SessionCountResolver.today,
            SessionReferrerCountTopResolver,
            SessionDeviceOSCountTopResolver,
            SessionDeviceBrandCountTopResolver,
            SessionDeviceModelCountTopResolver,
            SessionDeviceBrowserCountTopResolver,
            PrizeCountResolver,
            PrizeCountResolver.trend,
            PrizeCountResolver.today,
            PrizeReshuffledCountResolver,
            PrizeReshuffledCountResolver.trend,
            PrizeReshuffledCountResolver.today,
            GameWinCountResolver.lastWeek,
            GameCountResolver.lastWeek,
            TwofactorSentCountResolver,
            TwofactorCompletedCountResolver,
            TwofactorFailedCountResolver,
            TwofactorNotRegisteredCountResolver,
            TwofactorNotUsedCountResolver
        ])
    }

    render() {


        const isLoading = this.props.loading || this.state.loading
        const isBgLoading = this.props.bgLoading || this.state.bgLoading
        if (isBgLoading) {
            return this.cache;
        }


        // if (!isLoading) {
        //     const typeData = this.props.states.prizeTypeCount.map(ref => ref.count)
        //     var prizeTypeStat = { name: "count", data: typeData }
        // }
        const referrerCounts = this.props.states.sessionReferrerTopCount
        if (referrerCounts) {
            var totalReferrer = referrerCounts.reduce((prev, cur) => prev + cur.count, 0)
            var donutData = referrerCounts.map(({ referrer, count, color }, i) => ({ name: referrer, quantity: count, percentage: _.round((count / totalReferrer) * 100), color }))
        }

        const { sessionOSTopCount, sessionBrandTopCount, sessionModelTopCount, sessionBrowserTopCount, userPermissionCount, userPermissionCountTrend, userPermissionCountToday } = this.props.states

        if (sessionOSTopCount) {
            var totalOS = sessionOSTopCount.reduce((prev, cur) => prev + cur.count, 0)
            var osDonutData = sessionOSTopCount.map(({ os, count }) => ({ name: os, quantity: count, percentage: _.round((count / totalOS) * 100) }))
        }

        if (sessionBrandTopCount) {
            var totalBrand = sessionBrandTopCount.reduce((prev, cur) => prev + cur.count, 0)
            var brandDonutData = sessionBrandTopCount.map(({ brand, count }) => ({ name: brand, quantity: count, percentage: _.round((count / totalBrand) * 100) }))
        }

        if (sessionModelTopCount) {
            var totalModel = sessionModelTopCount.reduce((prev, cur) => prev + cur.count, 0)
            var modelDonutData = sessionModelTopCount.map(({ model, count }) => ({ name: model, quantity: count, percentage: _.round((count / totalModel) * 100) }))
        }

        if (sessionBrowserTopCount) {
            var totalBrowser = sessionBrowserTopCount.reduce((prev, cur) => prev + cur.count, 0)
            var browserDonutData = sessionBrowserTopCount.map(({ browser, count }) => ({ name: browser, quantity: count, percentage: _.round((count / totalBrowser) * 100) }))
        }

        let permissionObject = {}
        if (userPermissionCount && userPermissionCountTrend && userPermissionCountToday) {
            for (const { name, count } of userPermissionCount) {
                permissionObject[name] = {}
                permissionObject[name].total = count
            }

            for (const { name, count } of userPermissionCountTrend) {
                permissionObject[name].trend = count
            }

            for (const { name, count } of userPermissionCountToday) {
                permissionObject[name].today = count
            }
        }


        let lastSevenStats = []
        let gameLastSeven = []

        if (!isLoading) {
            if (checkFeatures(this.props, ["user"])) {
                lastSevenStats.push({
                    name: "New Users",
                    data: this.props.weekChartStats.userCount,
                })
            }

            if (checkFeatures(this.props, ["voucher"])) {
                lastSevenStats.push({
                    name: "Vouchers issued",
                    data: this.props.weekChartStats.vouchersIssued,
                })
            }

            if (checkFeatures(this.props, ["voucher", "voucher_redeemed"])) {
                lastSevenStats.push({
                    name: "Vouchers redeemed",
                    data: this.props.weekChartStats.vouchersRedeemed,
                })
            }

            if (checkFeatures(this.props, ["game", "game_win"])) {
                gameLastSeven.push({
                    name: "Games Won",
                    data: this.props.weekChartStats.gameWinCount,
                })
            }

            if (checkFeatures(this.props, ["game", "game_completed"])) {
                gameLastSeven.push({
                    name: "Games Completed",
                    data: this.props.weekChartStats.gameCompletedCount,
                })
            }

            if (checkFeatures(this.props, ["game"])) {
                gameLastSeven.push({
                    name: "Games",
                    data: this.props.weekChartStats.gameCount,
                })
            }

        }

        if (!isLoading) {

            if(this.timeout) clearTimeout(this.timeout);

            // this.timeout = setTimeout(() => {
            //     if (window.location.hash.indexOf('/stats/?')||window.location.hash.indexOf('/stats?')) {
            //         if (!isLoading) {
            //             this.props.update(true)
            //         }
            //     }
            // }, 30000)
        }







        this.cache = (
            <>
                {isLoading && !isBgLoading ? <Loading /> :
                    <>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Row>
                                    {/* Small accumulated */}
                                    <Col>
                                        <FeatureCheck features={["user"]} {...this.props} >

                                            <h3>User</h3>
                                        </FeatureCheck>

                                        <Row>
                                            <FeatureCheck features={["user"]} {...this.props} >
                                                <Col sm={6} md={6} lg={6} xl={3}>
                                                    <StatisticCard
                                                        {...this.props}
                                                        stat={this.props.states.userCount}
                                                        trendStat={this.props.states.userCountTrend}
                                                        // todayStat={this.props.states.userCountToday}
                                                        todayStat={this.props.getTodayOfWeekStat("userCount")}
                                                        showTrend={this.props.showTrend}

                                                        title="Users"
                                                        description="Registred users"
                                                        icon="dripicons-user text-primary"
                                                        textClass="text-dark"
                                                    />
                                                </Col>
                                            </FeatureCheck>

                                            <FeatureCheck features={["live"]} {...this.props} >
                                                <Col sm={6} md={6} lg={6} xl={3}>
                                                    <StatisticCard
                                                        {...this.props}
                                                        stat={this.props.states.liveUserCount}
                                                        todayStat={this.props.states.userActiveCountToday}

                                                        title="Active last 30 min"
                                                        description="Active last 30 min"
                                                        icon="dripicons-user text-primary"
                                                        textClass="text-dark"
                                                    />
                                                </Col>
                                            </FeatureCheck>
                                            <FeatureCheck features={["extrasignups"]} {...this.props} >
                                                <Col sm={6} md={6} lg={6} xl={3}>
                                                    <StatisticCard
                                                        {...this.props}
                                                        stat={this.props.states.CKExtraUserCount ? this.props.states.CKExtraUserCount.count : 0}
                                                        todayStat={this.props.states.CKExtraUserCount ? this.props.states.CKExtraUserCount.marketing : 0}
                                                        todayString="Marking consent"
                                                        title="New Extra Signups"
                                                        description="New Extra Signups"
                                                        icon="dripicons-user text-primary"
                                                        textClass="text-dark"
                                                    />
                                                </Col>
                                            </FeatureCheck>


                                            <FeatureCheck features={["user", "user_extra"]} {...this.props} >
                                                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                                                    <StatisticCard
                                                        {...this.props}
                                                        stat={this.props.states.userExtraCount}
                                                        trendStat={this.props.states.userExtraCountTrend}
                                                        // todayStat={this.props.states.gameCompletedCountToday}
                                                        todayStat={this.props.states.userExtraCountToday}
                                                        showTrend={this.props.showTrend}

                                                        title="Extra Users"
                                                        description="Number of Extra Users"
                                                        icon="dripicons-plus text-primary"
                                                        textClass="text-dark"
                                                    />
                                                </Col>
                                            </FeatureCheck>

                                            <FeatureCheck features={["session"]} {...this.props} >
                                                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                                                    <StatisticCard
                                                        {...this.props}
                                                        stat={this.props.states.sessionCount}
                                                        trendStat={this.props.states.sessionCountTrend}
                                                        todayStat={this.props.states.sessionCountToday}
                                                        showTrend={this.props.showTrend}

                                                        title="Sessions"
                                                        description="Number of sessions"
                                                        icon="dripicons-clock text-primary"
                                                        textClass="text-dark"
                                                    />
                                                </Col>
                                            </FeatureCheck>
                                        </Row>

                                        <FeatureCheck features={["voucher"]} {...this.props} >

                                            <h3>Voucher</h3>
                                        </FeatureCheck>

                                        <Row>


                                            <FeatureCheck features={["voucher"]} {...this.props} >
                                                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                                                    <StatisticCard
                                                        {...this.props}
                                                        stat={this.props.states.vouchersIssued}
                                                        trendStat={this.props.states.vouchersIssuedTrend}
                                                        // todayStat={this.props.states.vouchersIssuedToday}
                                                        todayStat={this.props.getTodayOfWeekStat("vouchersIssued")}
                                                        showTrend={this.props.showTrend}

                                                        title="Vouchers issued"
                                                        description="Vouchers issued"
                                                        icon="dripicons-ticket text-primary"
                                                        textClass="text-dark"
                                                    />
                                                </Col>
                                            </FeatureCheck>



                                            <FeatureCheck features={["voucher", "voucher_redeemed"]} {...this.props} >
                                                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                                                    <StatisticCard
                                                        {...this.props}
                                                        stat={this.props.states.vouchersRedeemed}
                                                        trendStat={this.props.states.vouchersRedeemedTrend}
                                                        // todayStat={this.props.states.vouchersRedeemedToday}
                                                        todayStat={this.props.getTodayOfWeekStat("vouchersRedeemed")}
                                                        showTrend={this.props.showTrend}

                                                        title="Vouchers redeemed"
                                                        description="Vouchers redeemed"
                                                        icon="dripicons-checkmark text-primary"
                                                        textClass="text-dark"
                                                    />
                                                </Col>
                                            </FeatureCheck>


                                            <FeatureCheck features={["voucher", "voucher_active"]} {...this.props} >
                                                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                                                    <StatisticCard
                                                        {...this.props}
                                                        stat={this.props.states.vouchersActive}
                                                        trendStat={this.props.states.vouchersActiveTrend}
                                                        todayStat={this.props.states.vouchersActiveToday}
                                                        //todayStat={this.props.getTodayOfWeekStat("vouchersIssued")}
                                                        showTrend={this.props.showTrend}

                                                        title="Vouchers active"
                                                        description="Vouchers active"
                                                        icon="dripicons-hourglass text-primary"
                                                        textClass="text-dark"
                                                    />
                                                </Col>
                                            </FeatureCheck>







                                        </Row>

                                        <FeatureCheck features={["game"]} {...this.props} >
                                            <h3>Games</h3>

                                        </FeatureCheck>
                                        <Row>


                                            <FeatureCheck features={["game", "game_completed"]} {...this.props} >
                                                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                                                    <StatisticCard
                                                        {...this.props}
                                                        stat={this.props.states.gameCompletedCount}
                                                        trendStat={this.props.states.gameCompletedCountTrend}
                                                        // todayStat={this.props.states.gameCompletedCountToday}
                                                        todayStat={this.props.getTodayOfWeekStat("gameCompletedCount")}
                                                        showTrend={this.props.showTrend}

                                                        title="Games completed"
                                                        description="Number of games completed"
                                                        icon="dripicons-gaming text-primary"
                                                        textClass="text-dark"
                                                    />
                                                </Col>
                                            </FeatureCheck>

                                            <FeatureCheck features={["prize"]} {...this.props} >
                                                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                                                    <StatisticCard
                                                        {...this.props}
                                                        stat={this.props.states.prizeCount}
                                                        trendStat={this.props.states.prizeCountTrend}
                                                        todayStat={this.props.states.prizeCountToday}
                                                        showTrend={this.props.showTrend}

                                                        title="Prizes"
                                                        description="Number of prizes"
                                                        icon="dripicons-trophy text-primary"
                                                        textClass="text-dark"
                                                    />
                                                </Col>
                                            </FeatureCheck>


                                            <FeatureCheck features={["game", "game_unique_win"]} {...this.props} >
                                                <Col xs={12} sm={6} md={6} lg={6} xl={3}>
                                                    <StatisticCard
                                                        {...this.props}
                                                        stat={this.props.states.gameUniqueWinCount}
                                                        trendStat={this.props.states.gameUniqueWinCountTrend}
                                                        // todayStat={this.props.states.gameCompletedCountToday}
                                                        todayStat={this.props.getTodayOfWeekStat("gameUniqueWinCount")}
                                                        showTrend={this.props.showTrend}

                                                        title="Game unique wins"
                                                        description="Number of games won by unique players"
                                                        icon="dripicons-dot text-primary"
                                                        textClass="text-dark"
                                                    />
                                                </Col>
                                            </FeatureCheck>

                                            <FeatureCheck features={["prize", "prize_expired"]} {...this.props} >
                                                <Col xs={12} sm={12} md={12} lg={6} xl={3}>
                                                    <StatisticCard
                                                        {...this.props}
                                                        stat={this.props.states.prizeReshuffledCount}
                                                        trendStat={this.props.states.prizeReshuffledCountTrend}
                                                        todayStat={this.props.states.prizeReshuffledCountToday}
                                                        showTrend={this.props.showTrend}

                                                        title="Prizes reshuffled"
                                                        description="Number of prizes reshuffled"
                                                        icon="dripicons-retweet text-primary"
                                                        textClass="text-dark"
                                                    />
                                                </Col>
                                            </FeatureCheck>






                                        </Row>

                                        <FeatureCheck features={["game", "tablewise", "tablewise_game"]} {...this.props} >
                                            <Row >
                                                <Col>
                                                    {Object.keys(this.props.states.gameCompletedCountTablewise || {}).sort().map(gameKey => {
                                                        const nameSplit = gameKey.split("_")
                                                        const names = nameSplit.slice(1, nameSplit.length - 1).map(name => _.capitalize(name))
                                                        const name = names.join(" ")

                                                        return <>
                                                            <Row>
                                                                {this.props.states.gameCountTablewise[gameKey] != 0 &&

                                                                    <Col xs={12} sm={6} xl={3}>
                                                                        <StatisticCard
                                                                            {...this.props}
                                                                            stat={this.props.states.gameCountTablewise[gameKey]}
                                                                            trendStat={this.props.states.gameCountTrendTablewise[gameKey]}
                                                                            todayStat={this.props.getTodayOfWeekStat("gameCountTablewise")[gameKey]}
                                                                            showTrend={this.props.showTrend}

                                                                            title={`Game ${name} started`}
                                                                            description={`Number of games of type ${name} started`}
                                                                            icon="dripicons-media-play text-info"
                                                                            textClass="text-dark"
                                                                            color="info"
                                                                        />
                                                                    </Col>
                                                                }
                                                                <FeatureCheck features={["game_completed"]} {...this.props} >
                                                                    {this.props.states.gameCompletedCountTablewise[gameKey] != 0 &&

                                                                        <Col xs={12} sm={6} xl={3}>

                                                                            <StatisticCard
                                                                                {...this.props}
                                                                                stat={this.props.states.gameCompletedCountTablewise[gameKey]}
                                                                                trendStat={this.props.states.gameCompletedCountTrendTablewise[gameKey]}
                                                                                todayStat={this.props.getTodayOfWeekStat("gameCompletedCountTablewise")[gameKey]}
                                                                                showTrend={this.props.showTrend}

                                                                                title={`Game ${name} completed`}
                                                                                description={`Number of games of type ${name} completed`}
                                                                                icon="dripicons-gaming text-info"
                                                                                textClass="text-dark"
                                                                                color="info"
                                                                            />
                                                                        </Col>
                                                                    }
                                                                </FeatureCheck>
                                                                <FeatureCheck features={["game_win"]} {...this.props} >
                                                                    {this.props.states.gameWinCountTablewise[gameKey] != 0 &&
                                                                        <Col xs={12} sm={6} xl={3}>

                                                                            <StatisticCard
                                                                                {...this.props}
                                                                                stat={this.props.states.gameWinCountTablewise[gameKey]}
                                                                                trendStat={this.props.states.gameWinCountTrendTablewise[gameKey]}
                                                                                todayStat={this.props.getTodayOfWeekStat("gameWinCountTablewise")[gameKey]}
                                                                                showTrend={this.props.showTrend}

                                                                                title={`Game ${name} wins`}
                                                                                description={`Number of games of type ${name} won`}
                                                                                icon="dripicons-trophy text-info"
                                                                                textClass="text-dark"
                                                                                color="info"
                                                                            />
                                                                        </Col>
                                                                    }
                                                                </FeatureCheck>
                                                                <FeatureCheck features={["game_avg_playtime"]} {...this.props} >
                                                                    {this.props.states.gameAvgPlaytimeTablewise != null && this.props.states.gameAvgPlaytimeTablewise[gameKey] != 0 &&

                                                                        <Col xs={12} sm={6} xl={3}>

                                                                            <StatisticCard
                                                                                {...this.props}
                                                                                stat={secondsToTime(this.props.states.gameAvgPlaytimeTablewise[gameKey] * this.props.states.gameCompletedCountTablewise[gameKey])}
                                                                                stat2={secondsToTime(this.props.states.gameAvgPlaytimeTablewise[gameKey])}
                                                                                // trendStat={this.props.states.gameAvgPlaytimeTablewise[gameKey]}
                                                                                // todayStat={this.props.getTodayOfWeekStat("gameCompletedCountTablewise")[gameKey]}
                                                                                showTrend={false}
                                                                                statDescription="Total time spent"
                                                                                statDescription2="Average playtime"
                                                                                title={`Game ${name} playtime`}
                                                                                description={`Playtime of Game ${name}`}
                                                                                icon="dripicons-clock text-info"
                                                                                textClass="text-dark"
                                                                                color="info"
                                                                            />
                                                                        </Col>
                                                                    }

                                                                </FeatureCheck>
                                                            </Row>
                                                        </>

                                                    })}

                                                </Col>
                                            </Row>


                                        </FeatureCheck>
                                    </Col>
                                </Row>
                            </Col>



                            <Col sm={12} md={12} lg={12} xl={12}>


                                <FeatureCheck features={["game"]} {...this.props} >
                                    <h3>Past 7 days</h3>

                                </FeatureCheck>
                                <FeatureCheck checkIfEmpty={lastSevenStats} {...this.props} >
                                    {/* 7 days */}

                                    <MultiChart
                                        title="Last 7 days"
                                        categories={this.props.weekChartStatsStrings}
                                        stats={lastSevenStats}
                                    />
                                </FeatureCheck>
                                <FeatureCheck features={["game"]} {...this.props} >
                                    <SplineAreaChart
                                        title="Games - Last 7 days"
                                        categories={this.props.weekChartStatsStrings}
                                        stats={gameLastSeven}
                                    />
                                </FeatureCheck>
                            </Col>


                            <Col sm={12} md={12} lg={12} xl={12}>


                                <FeatureCheck features={["session", "session_referrer"]} {...this.props} >
                                    <h3>Users source</h3>

                                </FeatureCheck>
                                <FeatureCheck features={["session", "session_referrer"]} {...this.props} >
                                    <Card style={{ maxWidth: 745 }}>
                                        <CardBody>
                                            <BarChartBrite
                                                title="Top Channels"
                                                data={donutData}
                                            />
                                        </CardBody>
                                    </Card>

                                </FeatureCheck>

                                <FeatureCheck features={["session", "session_device"]} {...this.props} >
                                    <Row>
                                        <FeatureCheck features={["session_device_os"]} {...this.props} >
                                            <Col xs={12} lg={6} className="p-2">
                                                <Card>
                                                    <CardBody>
                                                        <BarChartBrite
                                                            title="Top Device - OS"
                                                            data={osDonutData}
                                                        />
                                                    </CardBody>
                                                </Card>

                                            </Col>
                                        </FeatureCheck>
                                        <FeatureCheck features={["session_device_brand"]} {...this.props} >
                                            <Col xs={12} lg={6} className="p-2">
                                                <Card>
                                                    <CardBody>
                                                        <BarChartBrite
                                                            title="Top Device - Brand"
                                                            data={brandDonutData}
                                                        />
                                                    </CardBody>
                                                </Card>

                                            </Col>
                                        </FeatureCheck>
                                        <FeatureCheck features={["session_device_model"]} {...this.props} >
                                            <Col xs={12} lg={6} className="p-2">
                                                <Card>
                                                    <CardBody>
                                                        <BarChartBrite
                                                            title="Top Device - Model"
                                                            data={modelDonutData}
                                                        />
                                                    </CardBody>
                                                </Card>

                                            </Col>
                                        </FeatureCheck>
                                        <FeatureCheck features={["session_device_browser"]} {...this.props} >
                                            <Col xs={12} lg={6} className="p-2">
                                                <Card>
                                                    <CardBody>
                                                        <BarChartBrite
                                                            title="Top Device - Browser"
                                                            data={browserDonutData}
                                                        />
                                                    </CardBody>
                                                </Card>

                                            </Col>
                                        </FeatureCheck>
                                    </Row>

                                </FeatureCheck>
                            </Col>

                            <Col xs={12} md={12} lg={12} xl={6}>
                                <Row>
                                    <FeatureCheck features={["twofactor"]} {...this.props} >
                                        <Col xs={12}>
                                            <RadialCard
                                                title="Twofactor"
                                                total={{ name: "Sent", stat: this.props.states.twofactorSentCount, checks: ["twofactor", "twofactor_sent"], color: "primary", icon: "dripicons-direction" }}
                                                stats={[
                                                    { name: "Completed", stat: this.props.states.twofactorCompletedCount, checks: ["twofactor", "twofactor_completed"], color: "success", icon: "dripicons-checkmark" },
                                                    { name: "Not Used", stat: this.props.states.twofactorNotUsedCount, checks: ["twofactor", "twofactor_not_used"], color: "warning", icon: "dripicons-trash" },
                                                    { name: "Failed", stat: this.props.states.twofactorFailedCount, checks: ["twofactor", "twofactor_failed"], color: "danger", icon: "dripicons-wrong" },
                                                    { name: "Not Registered", stat: this.props.states.twofactorNotRegisteredCount, checks: ["twofactor", "twofactor_not_registered"], color: "secondary", icon: "dripicons-link-broken" },
                                                ]}
                                                {...this.props}
                                            />
                                        </Col>
                                    </FeatureCheck>
                                </Row>
                            </Col>
                            <Col className="p-0" lg={12} xl={12} >
                                <Col xs={12}>
                                    <Row>


                                        {/* <FeatureCheck features={["game", "tablewise", "tablewise_game", "game_avg_playtime"]} {...this.props} >
                                            {this.props.states.gameAvgPlaytime &&
                                                <Col xs={12} sm={12} md={12} lg={6}>
                                                    <StatisticCard
                                                        {...this.props}
                                                        stat={secondsToTime(this.props.states.gameAvgPlaytime * this.props.states.gameCompletedCount)}
                                                        stat2={`${this.props.states.gameAvgPlaytime} seconds`}
                                                        showTrend={false}
                                                        statDescription="Total time spent"
                                                        statDescription2="Average playtime"
                                                        title={`Game playtime`}
                                                        description={`Playtime of all Games`}
                                                        icon="dripicons-clock text-info"
                                                        textClass="text-dark"
                                                        color="info"
                                                    />
                                                </Col>
                                            }
                                        </FeatureCheck> */}

                                    </Row>
                                </Col>



                            </Col>
                        </Row>


                        <FeatureCheck features={["user", "user_permission"]} {...this.props} >
                            <Row>
                                <Col>
                                    <Row >
                                        {Object.keys(permissionObject).map(name => {
                                            const { total, trend, today } = permissionObject[name]
                                            const percent = ((total / this.props.states.userCount) * 100).toFixed(1);

                                            const str = thousandSeperator(total) + ' - ' + percent + '%';

                                            return <>
                                                <Col xs={12} sm={6}>
                                                    <StatisticCard
                                                        {...this.props}
                                                        stat={str}
                                                        trendStat={trend}
                                                        todayStat={today}
                                                        showTrend={this.props.showTrend}

                                                        title={`Permission - ${name}`}
                                                        description={`Number of permission by type '${name}' given by users`}
                                                        icon="dripicons-thumbs-up text-info"
                                                        textClass="text-dark"
                                                        color="info"
                                                    />
                                                </Col>
                                            </>
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        </FeatureCheck>
                    </>

                }

            </>
        );
        return this.cache
    }
}

export default Overview;
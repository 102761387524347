import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardBody, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col } from "reactstrap"
import classnames from "classnames"
import printJS from 'print-js'

class MultiChart extends Component {

    render() {
        const { stats, categories, pickDate ,resetDate} = this.props

        let apexLineChartWithLables = {
            chart: {
                events: {
                    dataPointSelection: function (event, chartContext, config) {
                        // alert(`chartContext: ${JSON.stringify(chartContext, getCircularReplacer())}`)
                        // alert(`config: ${JSON.stringify(config, getCircularReplacer())}`)
                        const i = config["dataPointIndex"]
                        pickDate(categories[i])
                        
                    }
                },
                height: "auto",
                type: 'line',
                dropShadow: {
                    enabled: true,
                    opacity: 0.1,
                    blur: 7,
                    left: -7,
                    top: 7,
                },
                toolbar: {
                    show: true,
                    // autoSelected: 'zoom',
                    tools: {
                        customIcons: [{
                            icon: '<img src="/reset-view.png" width="16">',
                            index: 4,
                            title: 'tooltip of the icon',
                            class: 'reset-view',
                            click: function (chart, options, e) {
                                if(pickDate){
                                    resetDate()
                                }
                                try{
                                    chart.resetSeries(true, true)
                                } catch(e){}
                            }
                        }],
                        download: true,
                        selection: false,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: false | '<img src="/reset-view.png" width="20">',

                    },
                },
                zoom: {
                    enabled: true
                },
                parentHeightOffset: 0,
            },
            grid: {
                padding: {
                    left: 0,
                    right: 0,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            markers:{},
            zoom: {
                enabled: true,
            },
            legend: {
                show: true,
            },
            colors: ['#727cf5', '#0acf97', '#fa5c7c', '#ffbc00'],
            xaxis: {
                type: 'string',
                categories: this.props.categories,
                tooltip: {
                    enabled: false,
                },
                axisBorder: {
                    show: false,
                },
            },
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return val + " ";
                    },
                },
            },
            tooltip: {
                fixed: {
                    enabled: false,
                    position: 'topRight',
                },
            },

        };

        if (pickDate !== undefined) {
            apexLineChartWithLables.markers.size = 5
            apexLineChartWithLables.tooltip.shared = false
            apexLineChartWithLables.tooltip.intersect = true
        }

        return (

            <Card >
                <CardBody style={{ padding: "14px" }}>


                    {/* <UncontrolledButtonDropdown className="float-right">
                        <DropdownToggle tag="button" className="btn btn-link arrow-none card-drop p-0">
                            <i className="mdi mdi-dots-vertical"></i>
                        </DropdownToggle>

                        <DropdownMenu right>
                            <DropdownItem onClick={() => printJS({ printable: "multi-chart", type: "html", maxWidth: 2000 })}>Print</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown> */}

                    {/* <div className="chart-content-bg">
                        <Row className="text-center">
                            {stats.map(({ name, data, iconClass, colorClass }) => {
                                return (
                                    <Col className="col-md-6" xs={6} sm={6} md={3} lg={3}>
                                        <p className="text-muted mb-0 mt-3 text-truncate">{name}</p>
                                        <h3 className="font-weight-normal mb-3">
                                            <small className={classnames(iconClass, colorClass, " align-middle mr-1 pb-2")}></small>
                                            <span>{data.reduce((acc, cur) => {
                                                return acc + cur
                                            }, 0)}</span>
                                        </h3>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div> */}
                    {this.props.centeredTitle ?
                        <h2 class="text-center">{this.props.title}</h2>
                        :
                        <div className="dash-item-overlay">
                            <h4 className="header-title" style={{ marginLeft: -40 }}>{this.props.title}</h4>
                        </div>
                    }
                    <Chart
                        id="multi-chart"
                        options={apexLineChartWithLables}
                        series={stats}
                        type="line"
                        className="apex-charts mt-3"
                        height={388}
                    // height={376}

                    />
                </CardBody>




            </Card>

        );
    }
}

export default MultiChart;
import FacadeConnection from "./FacadeConnection";

class ProjectFacade extends FacadeConnection {
    constructor(){
        super()
    }
    async getAccess(sessionID, projectID){
        return await this.get("/projectAccess", sessionID, projectID)
    }
}

export default new ProjectFacade()

import AvFeedback from 'availity-reactstrap-validation/lib/AvFeedback';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvGroup from 'availity-reactstrap-validation/lib/AvGroup';
import AvInput from 'availity-reactstrap-validation/lib/AvInput';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import GenericTable from '../components/GenericTable';
import Loading from '../components/Loading';
import IPBlacklistFacade from '../facade/IPBlacklistFacade';
import { dateFormatter, tzTableDateFormatter } from '../utils/Converter';

class IPBlacklist extends Component {
    state = {
        ips: [],
        loading: true,
        input: {
            search: null,
            ip: null,
            expires: null
        },
        isOpen: false
    }

    toggle = async (state) => {
        await this.setState({ isOpen: state });
    };

    componentDidMount = async () => {
        await this.fetchData()
    }

    fetchData = async () => {
        let ips = await IPBlacklistFacade.getIps(this.props.data)
        ips = ips.map(ip => {
            let { source, expires, project, banned, created } = ip
            if (expires) {
                expires = moment(expires).format("DD.MM.YYYY - HH:mm:ss")
            }

            return {
                ip: ip.ip,
                source: source || null,
                project: project || null,
                expires: expires || null,
                banned: banned,
                created
            }
        })
        this.setState({ loading: false, ips })
    }

    handleKeyPressed = (e) => {
        let input = Object.assign({}, this.state.input)
        input[e.target.name] = e.target.value
        this.setState({ input })
    }

    clear = () => {
        this.toggle(false)
        const copy = Object.assign({}, this.state.input)
        copy["ip"] = null
        copy["expires"] = null
        this.setState({ input: copy })
    }

    submit = async () => {
        const { ip, expires } = this.state.input
        let time = new Date(expires).getTime()
        try {
            await IPBlacklistFacade.addBan(this.props.data, ip, time > 0 ? time : null)
            this.props.alert.success(`IP ${ip} ban was added`)

        } catch (e) {
            this.props.alert.error(`Failed to blacklist ip ${ip} - ${e}`)
        }
        await this.toggle(false)
        await this.setState({ loading: true })
        await this.fetchData()
    }

    toggleBanned = async (row) => {
        let { ip, banned } = row
        if (banned === undefined) banned = true

        banned = !banned

        try {
            await IPBlacklistFacade.toggleBanned(this.props.data, ip, banned)
            this.props.alert.success(`IP ${ip} was ${ banned ? `banned`: `unbanned` }`)
            await this.fetchData()
        } catch (e) {
            this.props.alert.error(`Failed to toggle banned for blacklisted ip ${ip} - ${e}`)
        }
    }

    render() {

        let { ips } = this.state
        let { search } = this.state.input

        if (search !== null) {
            ips = ips.filter(({ ip, project, source }) => {
                search = search.toLowerCase()
                if (ip.toLowerCase().search(search) !== -1) return true
                if (project && project.toLowerCase().search(search) !== -1) return true
                if (source && source.toLowerCase().search(search) !== -1) return true
                return false
            })
        }

        const actions = {
            "Toggle Banned": this.toggleBanned
        }
        return (
            <>
                <div style={{ height: "100%", minHeight: window.innerHeight, padding: 20 }}>
                    <h3>IP Blacklist</h3>
                    <br />
                    <Row>
                        <Col xs={10}>
                            <div className="app-search" style={{ maxWidth: (window.innerWidth * 0.58) }}>
                                <form onSubmit={e => e.preventDefault()} >
                                    <div className="input-group" style={{ height: "48px", marginTop: -19 }}>
                                        <input type="text" className="form-control" placeholder="Search..."
                                            onChange={this.handleKeyPressed}
                                            name="search"
                                            id="search"
                                            value={this.state.input.search}
                                            style={{ height: "100%" }}
                                        />
                                        <span className="dripicons-search" style={{ marginTop: 3 }}></span>
                                    </div>
                                </form>
                            </div>
                        </Col>
                        <Col xs={2}>
                            <Button className="float-right" disabled={this.state.loading} size="lg" color="success" onClick={() => this.toggle(true)}>
                                <i className="dripicons-plus" style={{ fontSize: 18 }}></i>
                            </Button>
                        </Col>
                    </Row>


                    {this.props.loading || this.state.loading ? <Loading /> : <>
                        <Card>
                            <CardBody>
                                <GenericTable
                                    {...this.props}
                                    pagination
                                    tableData={ips}
                                    sizePerPage={10}
                                    actions={actions}
                                    columnOptions={{
                                        "ip": {
                                            text: "IP"
                                        },
                                        "project": {
                                            text: "Project ID"
                                        },
                                        "expires": {
                                            sort: false
                                        },
                                        "created": {
                                            formatter: tzTableDateFormatter(),
                                        }
                                    }}

                                />
                            </CardBody>
                        </Card>
                    </>}

                    <Modal isOpen={this.state.isOpen} toggle={() => this.toggle(false)} scrollable className="modal-center" style={{ maxWidth: 600, width: "100%" }}>
                        <ModalHeader toggle={() => this.toggle(false)} >
                            <h3>Blacklist IP</h3>
                        </ModalHeader>
                        <ModalBody className={this.state.bodyClass}>
                            <AvForm onValidSubmit={this.submit}>

                                <AvGroup>
                                    <Label for="ip">IP Address</Label>
                                    <AvInput
                                        name="ip"
                                        type="text"
                                        label="IP"
                                        placeholder="Enter a valid ip address"
                                        pattern="(^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$)|(^([a-f0-9:]+:+)+[a-f0-9]+$)"
                                        value={this.state.input.ip}
                                        onChange={this.handleKeyPressed}
                                        required
                                    />
                                    <AvFeedback>Please enter a valid IPV4 or IPV6 Address</AvFeedback>
                                </AvGroup>

                                <AvGroup>
                                    <Label for="expires">Expires (Optional)</Label>
                                    <AvInput
                                        name="expires"
                                        type="datetime-local"
                                        label="Expires"
                                        placeholder=""
                                        value={this.state.input.expires}
                                        onChange={this.handleKeyPressed}

                                    />
                                    <AvFeedback>Please input a valid date</AvFeedback>
                                </AvGroup>

                                <br />
                                <FormGroup className="text-center">
                                    <Button color="success">Blacklist IP</Button>
                                    <Button color="light" type="button" onClick={this.clear} style={{ marginLeft: 10 }}>Cancel</Button>
                                </FormGroup>

                            </AvForm>
                        </ModalBody>
                    </Modal>

                </div>
            </>

        );
    }
}

export default IPBlacklist;
import React, { Component } from 'react';
import { Button, Card, CardBody, CardFooter, Col, DropdownItem, DropdownMenu, DropdownToggle, Progress, Row, UncontrolledButtonDropdown } from 'reactstrap';
import Loading from '../components/Loading';
import StatisticsChartWidget2 from '../components/StatisticsChartWidget2';
import Tab from '../components/Tab';
import PrizeTypeCountResolver from '../resolvers/PrizeTypeCountResolver';
import VouchersIssuedResolver from '../resolvers/VouchersIssuedResolver';
import { thousandSeperator } from '../utils/Converter';
import ProjectDataPage from './ProjectDataPage';
import Chart from 'react-apexcharts';
import _ from "lodash"
import '../assets/css/Hide-Dropdown-Arrow.css';
import VoucherTable from './VoucherTable';
import StorePicker from '../components/StorePicker';
import { withRouter } from 'react-router-dom';
import ParamState from '../utils/ParamState';
import Sorter from '../components/Sorter';
import VouchersRedeemedResolver from '../resolvers/VouchersRedeemedResolver';
import FeatureCheck from '../components/FeatureCheck';
import checkFeatures from '../utils/checkFeatures';
import GenericTable from '../components/GenericTable';

const REDEEMED_SORTINGS = {
    "Redeemed": {
        "asc": (a, b) => {
            if (a.redeemedCount === b.redeemedCount) {
                return 0
            } else if (a.redeemedCount > b.redeemedCount) {
                return 1
            } else {
                return -1
            }
        },
        "desc": (a, b) => {
            if (a.redeemedCount === b.redeemedCount) {
                return 0
            } else if (a.redeemedCount < b.redeemedCount) {
                return 1
            } else {
                return -1
            }
        }
    }
}

const SORTINGS = {
    // "Issued": {
    //     "asc": (a, b) => {
            // if (a.count === b.count) {
            //     return 0
            // } else if (a.count > b.count) {
            //     return 1
            // } else {
            //     return -1
            // }
    //     },
    //     "desc": (a, b) => {
            // if (a.count === b.count) {
            //     return 0
            // } else if (a.count < b.count) {
            //     return 1
            // } else {
            //     return -1
            // }
    //     }
    // },
    // "Redeemed": REDEEMED_SORTINGS.Redeemed,
    
    "Redeemed Max": {
        "asc": (a, b) => {
            if (a.redeemPercent === b.redeemPercent) {
                return 0
            } else if (a.redeemPercent > b.redeemPercent) {
                return 1
            } else {
                return -1
            }
        },
        "desc": (a, b) => {
            if (a.redeemPercent === b.redeemPercent) {
                return 0
            } else if (a.redeemPercent < b.redeemPercent) {
                return 1
            } else {
                return -1
            }
        }
    },
    "Redeemed Issued": {
        "asc": (a, b) => {
            if (a.redeemRate === b.redeemRate) {
                return 0
            } else if (a.redeemRate > b.redeemRate) {
                return 1
            } else {
                return -1
            }
        },
        "desc": (a, b) => {
            if (a.redeemRate === b.redeemRate) {
                return 0
            } else if (a.redeemRate < b.redeemRate) {
                return 1
            } else {
                return -1
            }
        }
    },
    "Active": {
        "asc": (a, b) => {
            if (a.activeCount === b.activeCount) {
                return 0
            } else if (a.activeCount > b.activeCount) {
                return 1
            } else {
                return -1
            }
        },
        "desc": (a, b) => {
            if (a.activeCount === b.activeCount) {
                return 0
            } else if (a.activeCount < b.activeCount) {
                return 1
            } else {
                return -1
            }
        }
    },
}

const CHANGED_SORTINGS = {
    "Changed": {
        "asc": (a, b) => {
            if (a.changeCount === b.changeCount) {
                return 0
            } else if (a.changeCount > b.changeCount) {
                return 1
            } else {
                return -1
            }
        },
        "desc": (a, b) => {
            if (a.changeCount === b.changeCount) {
                return 0
            } else if (a.changeCount < b.changeCount) {
                return 1
            } else {
                return -1
            }
        }
    },
    // "Percent Changed": {
    //     "asc": (a, b) => {
    //         if (a.changePercent === b.changePercent) {
    //             return 0
    //         } else if (a.changePercent > b.changePercent) {
    //             return 1
    //         } else {
    //             return -1
    //         }
    //     },
    //     "desc": (a, b) => {
    //         if (a.changePercent === b.changePercent) {
    //             return 0
    //         } else if (a.changePercent < b.changePercent) {
    //             return 1
    //         } else {
    //             return -1
    //         }
    //     }
    // }
}

class Voucher extends ProjectDataPage {
    constructor(props) {
        super("Voucher", [
            VouchersRedeemedResolver,
            VouchersRedeemedResolver.full,
            PrizeTypeCountResolver,
        ])

        this.state = Object.assign({ sorter: null, storeID: null, view: "Charts" }, this.state)

    }

    setSorter = async (sorter) => {
        await this.setState({ sorter })
    }

    setView = (view) => {
        if (this.state.view !== view) {
            this.setState({ view })
        }
    }

    render() {
        const usesChangePrize = checkFeatures(this.props, ["change_prize"])

        let sortings = SORTINGS
        let redeemedSortings = REDEEMED_SORTINGS
        if (usesChangePrize) {
            sortings = Object.assign(SORTINGS, CHANGED_SORTINGS)
            redeemedSortings = Object.assign(REDEEMED_SORTINGS, CHANGED_SORTINGS)
        }

        const { prizeTypeCount } = this.props.states
        var prizeTypes = []

        if (prizeTypeCount) {
            prizeTypes = Object.assign([], prizeTypeCount)

            var sorter = this.state.sorter
            //Sorting
            if (sorter) {
                const sorting = sortings[sorter.name]
                prizeTypes = prizeTypes.sort(sorting[sorter.type])
            }




            prizeTypes = prizeTypes.map(p => {
                const { max, count, redeemedCount, activeCount, changeCount } = p
                const overloadMax = max * 3
                const overloadCount = Math.max(count - max, 0)
                const overloadPercent = _.round(((count / max) * 100), 2).toFixed(2)
                const overloadMaxPercent = (overloadCount / overloadMax) * 100


                let redeemPercent = (redeemedCount / max) * 100
                redeemPercent = _.round(redeemPercent, 1)


                let redeemRate = (redeemedCount / count) * 100
                redeemRate = _.round(redeemRate, 1)

                if(isNaN(redeemPercent)) redeemPercent = 0;
                if(!isFinite(redeemPercent)) redeemPercent = 0;


                if(isNaN(redeemRate)) redeemRate = 0;
                if(!isFinite(redeemRate)) redeemRate = 0;

                let activePercent = (activeCount / count) * 100
                activePercent = _.round(activePercent, 1)

                p.overloadCount = overloadCount
                p.overloadPercent = overloadPercent
                p.overloadMaxPercent = overloadMaxPercent
                p.redeemRate = redeemRate
                p.redeemPercent = redeemPercent
                p.activePercent = activePercent

                if (usesChangePrize) {
                    p.changeCount = changeCount
                    if (redeemedCount === 0) {
                        p.changePercent = 0
                    } else {
                        p.changePercent = _.round((changeCount / (redeemedCount + changeCount)) * 100, 1)
                    }
                }

                return p
            })
        }

        let tabs = [
            {
                title: "Charts",
                icon: "dripicons-pulse"
            },
            {
                title: "Table",
                icon: "dripicons-view-list"
            }
            ,
            {
                title: "Best",
                icon: "dripicons-star"
            }
        ]

        // if (checkFeatures(this.props, ["change_prize"])) {
        //     tabs.push({
        //         title: "Changed",
        //         icon: "dripicons-swap"

        //     })
        // }


        const prizeTypeCopy = JSON.parse(JSON.stringify(prizeTypes));

        for(let k in prizeTypeCopy){
            let tmp = document.createElement("DIV");
            tmp.innerHTML =  prizeTypeCopy[k].title;
            let title = tmp.textContent || tmp.innerText || "";
            prizeTypeCopy[k].title = title;
        }


        let n = 0;

        //Top 5 count product
        const countTop5 = [];
        prizeTypeCopy.sort((a,b)=>{ if (a.count === b.count) { return 0 } else if (a.count < b.count) { return 1 } else { return -1 } })
        n = 0;
        for(let k in prizeTypeCopy){
            n++;
            let {title, count} = prizeTypeCopy[k];
            countTop5.push({
                title, count
            })
            if(n > 4) break;
        }

        //Bottom 5 count product
        const countBottom5 = [];
        prizeTypeCopy.sort((a,b)=>{ if (a.count === b.count) { return 0 } else if (a.count > b.count) { return 1 } else { return -1 } })
        n = 0;
        for(let k in prizeTypeCopy){
            n++;
            let {title, count} = prizeTypeCopy[k];
            countBottom5.push({
                title, count
            })
            if(n > 4) break;
        }
        countBottom5.sort((a,b)=>{ if (a.count === b.count) { return 0 } else if (a.count < b.count) { return 1 } else { return -1 } })
        

        //Top 5 redeemPercent product
        const redeemPercentTop5 = [];
        prizeTypeCopy.sort((a,b)=>{ if (a.redeemPercent === b.redeemPercent) { return 0 } else if (a.redeemPercent < b.redeemPercent) { return 1 } else { return -1 } })
        n = 0;
        for(let k in prizeTypeCopy){
            n++;
            let {title, redeemPercent} = prizeTypeCopy[k];
            redeemPercentTop5.push({
                title, redeemPercent
            })
            if(n > 4) break;
        }

        //Bottom 5 redeemPercent product
        const redeemPercentBottom5 = [];
        prizeTypeCopy.sort((a,b)=>{ if (a.redeemPercent === b.redeemPercent) { return 0 } else if (a.redeemPercent > b.redeemPercent) { return 1 } else { return -1 } })
        n = 0;
        for(let k in prizeTypeCopy){
            n++;
            let {title, redeemPercent} = prizeTypeCopy[k];
            redeemPercentBottom5.push({
                title, redeemPercent
            })
            if(n > 4) break;
        }
        redeemPercentBottom5.sort((a,b)=>{ if (a.redeemPercent === b.redeemPercent) { return 0 } else if (a.redeemPercent < b.redeemPercent) { return 1 } else { return -1 } })
                

        //Top 5 redeemedCount product
        const redeemedCountTop5 = [];
        prizeTypeCopy.sort((a,b)=>{ if (a.redeemedCount === b.redeemedCount) { return 0 } else if (a.redeemedCount < b.redeemedCount) { return 1 } else { return -1 } })
        n = 0;
        for(let k in prizeTypeCopy){
            n++;
            let {title, redeemedCount} = prizeTypeCopy[k];
            redeemedCountTop5.push({
                title, redeemedCount
            })
            if(n > 4) break;
        }

        //Bottom 5 redeemedCount product
        const redeemedCountBottom5 = [];
        prizeTypeCopy.sort((a,b)=>{ if (a.redeemedCount === b.redeemedCount) { return 0 } else if (a.redeemedCount > b.redeemedCount) { return 1 } else { return -1 } })
        n = 0;
        for(let k in prizeTypeCopy){
            n++;
            let {title, redeemedCount} = prizeTypeCopy[k];
            redeemedCountBottom5.push({
                title, redeemedCount
            })
            if(n > 4) break;
        }
        redeemedCountBottom5.sort((a,b)=>{ if (a.redeemedCount === b.redeemedCount) { return 0 } else if (a.redeemedCount < b.redeemedCount) { return 1 } else { return -1 } })

        //Top 5 redeemRate product
        const redeemRateTop5 = [];
        prizeTypeCopy.sort((a,b)=>{ if (a.redeemRate === b.redeemRate) { return 0 } else if (a.redeemRate < b.redeemRate) { return 1 } else { return -1 } })
        n = 0;
        for(let k in prizeTypeCopy){
            n++;
            let {title, redeemRate} = prizeTypeCopy[k];
            redeemRateTop5.push({
                title, redeemRate
            })
            if(n > 4) break;
        }

        //Bottom 5 redeemRate product
        const redeemRateBottom5 = [];
        prizeTypeCopy.sort((a,b)=>{ if (a.redeemRate === b.redeemRate) { return 0 } else if (a.redeemRate > b.redeemRate) { return 1 } else { return -1 } })
        n = 0;
        for(let k in prizeTypeCopy){
            n++;
            let {title, redeemRate} = prizeTypeCopy[k];
            redeemRateBottom5.push({
                title, redeemRate
            })
            if(n > 4) break;
        }
        redeemRateBottom5.sort((a,b)=>{ if (a.redeemRate === b.redeemRate) { return 0 } else if (a.redeemRate < b.redeemRate) { return 1 } else { return -1 } })



        return (
            <>
                <Row>
                    <Col xs={4} >
                    </Col>
                    <Col xs={4} className="text-center mb-3" style={{ padding: 0 }}>
                        <FeatureCheck features={["tab", "tab_voucher"]} {...this.props} >
                            <Tab
                                setView={this.setView}
                                view={this.state.view}
                                tabs={tabs}
                                {...this.props}
                            />
                        </FeatureCheck>
                    </Col>
                    <Col xs={4}>
                    
                        <FeatureCheck features={["sort", "sort_voucher"]} {...this.props} >
                            <Sorter sortings={this.state.storeID ? redeemedSortings : sortings} setSorter={this.setSorter} {...this.props} />
                        </FeatureCheck>
                        <FeatureCheck features={["store_picker", "store_picker_voucher"]} {...this.props} >
                            <StorePicker
                                style={{float:'right', height: 45, marginRight: 10}}
                                loading={this.state.loading}
                                setStoreID={async (storeID) => await this.setState({ storeID })}
                                {...this.props}
                            />
                        </FeatureCheck>
                    </Col>
                </Row>
                {this.props.loading || this.state.loading ? <Loading /> :
                    <>

                        {this.state.view === "Charts" && <>
                            <FeatureCheck features={["voucher", "voucher_redeemed"]} {...this.props} >
                                <Row>
                                    <Col>
                                        <StatisticsChartWidget2
                                            name="Vouchers Redeemed"
                                            type="area"
                                            title={thousandSeperator(this.props.states.vouchersRedeemed)}
                                            subtitle="Vouchers Redeemed"
                                            categories={this.props.fullChartStatsStrings}

                                            data={this.props.fullChartStats.vouchersRedeemed} />
                                    </Col>
                                </Row>
                            </FeatureCheck>
                            <FeatureCheck features={["prize", "prize_type"]} {...this.props} >
                                <Row>
                                    {prizeTypes.map(({ type, count, redeemedCount, title, prizePool, max, activeCount, redeemPercent, activePercent, overloadCount, overloadPercent, overloadMaxPercent, avgRedeemTime, changeCount, changePercent,redeemRate,sprite }) => {
                                        const labels = ['Redeemed Of Max']
                                        const colors = ['#38469b']

                                        if(redeemPercent == 'Infinity'||isNaN(redeemPercent)) redeemPercent = 0;
                                        if(redeemRate == 'Infinity'||isNaN(redeemRate)) redeemRate = 0;
                                        if(isNaN(activePercent)) activePercent = 0;

                                        if (!this.state.storeID) {
                                            labels.push('Active')
                                            colors.push('#8492fa')
                                        }

                                        if (usesChangePrize) {
                                            labels.push("Change Percent")
                                            colors.push("#6c757d")
                                        }

                                        const apexBarChartOpts = {
                                            labels,
                                            grid: {
                                                padding: {
                                                    left: 0,
                                                    right: 0,
                                                },
                                            },
                                            chart: {
                                                animations: {
                                                    enabled: true,
                                                    easing: 'easeinout',
                                                    speed: 1500,
                                                },
                                                height: 320,
                                                type: 'radialBar',
                                            },
                                            colors,
                                            plotOptions: {
                                                radialBar: {
                                                    track: {
                                                        margin: 8,
                                                    },
                                                    dataLabels: {
                                                        show: false
                                                    }
        
                                                },
                                            },
                                            tooltip: {
                                                enabled: true,
                                                shared: true,
                                                custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                                                    return `<div><span>${labels[seriesIndex]}: ${series[seriesIndex]}%</span></div>`
                                                }
                                            },
                                         

                                        };

                                        let overloadColor = "success"
                                        if (redeemPercent >= 70 && redeemPercent < 90) {
                                            overloadColor = "warning"
                                        } else if (redeemPercent > 90) {
                                            overloadColor = "danger"
                                        }

                                        const hours = avgRedeemTime / (60 * 60)
                                        const minutes = (avgRedeemTime % (60 * 60)) / 60

                                        const avgRedeemTimeString = `${_.pad(_.floor(hours), 2, "0")}h ${_.pad(_.floor(minutes), 2, "0")}m`

                                        const apexBarChartData = [redeemPercent];

                                        if (!this.state.storeID) {
                                            apexBarChartData.push(activePercent)
                                        }

                                        if (usesChangePrize) {
                                            apexBarChartData.push(changePercent)
                                        }


                                        let tmp = document.createElement("DIV");
                                        tmp.innerHTML = title;
                                        title = tmp.textContent || tmp.innerText || "";


                                        return <>
                                            <Col xs={12} sm={12} md={12} lg={6} xl={4}>
                                                <Card >
                                                    <CardBody>
                                                        <div className="text-center">
                                                            <h4>{title}</h4>
                                                            <p>Max: {isNaN(max) ? 'No limit' : thousandSeperator(max)}</p>
                                                        </div>
                                                        <div>
                                                            <Chart
                                                                // style={{ color: "white" }}
                                                                options={apexBarChartOpts}
                                                                series={apexBarChartData}
                                                                type="radialBar"
                                                                className="apex-charts"
                                                                height={230}
                                                            />
                                                            {sprite&&<img src={sprite} style={{height:'85px',margin:'-160px auto 70px',display:'block'}}></img>}
                                                        </div>
                                                        <div>
                                                            <Row className="row text-center mt-2">
                                                                {!this.state.storeID && <Col xs={3}>
                                                                    {/* <i className={`dripicons-ticket widget-icon bg-${overloadColor}-lighten text-${overloadColor}`}></i> */}
                                                                    <h5 className="font-weight-normal mt-3">
                                                                        <span>{thousandSeperator(count)}</span>
                                                                    </h5>
                                                                    <h6 className="text-muted mb-0 mb-2">
                                                                        <i className={`mdi mdi-checkbox-blank-circle text-${overloadColor}`}></i> Issued
                        </h6>
                                                                </Col>}

                                                                {!this.state.storeID && <Col xs={3}>
                                                                    {/* <i className="dripicons-hourglass widget-icon bg-info-lighten text-info"></i> */}
                                                                    <h5 className="font-weight-normal mt-3">
                                                                        <span>{thousandSeperator(activeCount)}</span>
                                                                    </h5>
                                                                    <h6 className="text-muted mb-0 mb-2">
                                                                        <i className="mdi mdi-checkbox-blank-circle text-info"></i> Active
                        </h6>
                                                                </Col>}
                                                                <Col xs={3}>
                                                                    {/* <i className="dripicons-checkmark widget-icon bg-primary-lighten text-primary"></i> */}
                                                                    <h5 className="font-weight-normal mt-3">
                                                                        <span>{thousandSeperator(redeemedCount)}</span>
                                                                    </h5>
                                                                    <h6 className="text-muted mb-0 mb-2">
                                                                        <i className="mdi mdi-checkbox-blank-circle text-primary"></i> Redeemed
                        </h6>
                                                                </Col>
                                                                <Col xs={3}>
                                                                    {/* <i className="dripicons-clock widget-icon bg-warning-lighten text-warning"></i> */}
                                                                    <h5 className="font-weight-normal mt-3">
                                                                        <span>{avgRedeemTimeString}</span>
                                                                    </h5>
                                                                    <h6 className="text-muted mb-0 mb-2">
                                                                        <i className="mdi mdi-checkbox-blank-circle text-warning"></i> Avg Redeem Time
                        </h6>
                                                                </Col>


                                                                <Col xs={6}>
                                                                    {/* <i className="dripicons-checkmark widget-icon bg-primary-lighten text-primary"></i> */}
                                                                    <h3 className="font-weight-normal mt-3">
                                                                        <span>{redeemPercent}%</span>
                                                                    </h3>
                                                                    <h4 className="text-muted mb-0 mb-2">
                                                                        <i className="mdi mdi-checkbox-blank-circle text-primary"></i> Redeemed Max
                        </h4>
                                                                </Col>
                                                                {!this.state.storeID &&<Col xs={6}>
                                                                    {/* <i className="dripicons-clock widget-icon bg-warning-lighten text-warning"></i> */}
                                                                    <h3 className="font-weight-normal mt-3">
                                                                        <span>{redeemRate}%</span>
                                                                    </h3>
                                                                    <h4 className="text-muted mb-0 mb-2">
                                                                        <i className="mdi mdi-checkbox-blank-circle text-warning"></i> Redeemed Issued
                        </h4>
                                                                </Col>}

                                                                {/* <FeatureCheck features={["change_prize"]} {...this.props}>
                                                                    <Col xs={6} >
                                                                        <i className="dripicons-swap widget-icon bg-dark-lighten text-dark"></i>
                                                                        <h5 className="font-weight-normal mt-3">
                                                                            <span>{thousandSeperator(changeCount)}</span>
                                                                        </h5>
                                                                        <h6 className="text-muted mb-0 mb-2">
                                                                            <i className="mdi mdi-checkbox-blank-circle text-dark"></i> Prizes Changed
</h6>
                                                                    </Col>
                                                                    <Col xs={6}>
                                                                        <span className="widget-icon bg-secondary-lighten text-secondary">%</span>
                                                                        <h5 className="font-weight-normal mt-3">
                                                                            <span>{changePercent}%</span>
                                                                        </h5>
                                                                        <h6 className="text-muted mb-0 mb-2">
                                                                            <i className="mdi mdi-checkbox-blank-circle text-secondary"></i> Percent Changed
</h6>
                                                                    </Col>
                                                                </FeatureCheck> */}

                                                            </Row>
                                                        </div>

                                                    </CardBody>
                                                    <FeatureCheck features={["overload", "overload_voucher"]} {...this.props} >
                                                        {overloadCount > 0 && max != 0 && <CardFooter>
                                                            <div className="text-center">
                                                                <h5>Overload</h5>
                                                                <Progress
                                                                    style={{ marginTop: 15 }}
                                                                    animated={true}
                                                                    color={overloadColor}
                                                                    value={overloadMaxPercent}
                                                                ></Progress>
                                                                <div style={{marginTop:'-18px'}} >{overloadPercent}%</div>
                                                                <br />
                                                            </div>
                                                        </CardFooter>}
                                                    </FeatureCheck>
                                                </Card>
                                            </Col>
                                        </>
                                    }


                                    )}
                                </Row>
                            </FeatureCheck>
                        </>}
                        {this.state.view === "Table" && <>
                            <FeatureCheck features={["prize", "prize_type"]} {...this.props} >
                                <VoucherTable
                                    prizeTypes={prizeTypes}
                                    storeChosen={this.state.storeID !== null}
                                    {...this.props}
                                />
                            </FeatureCheck>
                        </>}
                        {this.state.view === "Best" && <>
                            <FeatureCheck features={["prize", "prize_type"]} {...this.props} >
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                        <Card >
                                            <CardBody>
                                                <div className="text-center">
                                                    <h4>Top 5 issued</h4>
                                                    <br/>

                                                    <GenericTable {...this.props}
                                                    tableData={countTop5}
                                                    columnOptions={{
                                                        "title": {
                                                            text: "Title",
                                                            sort: false,
                                                        },
                                                        "count": {
                                                            sort: false,
                                                            text: "Issued",
                                                        }
                                                    }}></GenericTable>
                                                    
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6}>
                                        <Card >
                                            <CardBody>
                                                <div className="text-center">
                                                    <h4>Bottom 5 issued</h4>
                                                    <br/>
                                                    <GenericTable {...this.props}
                                                    tableData={countBottom5}
                                                    sort
                                                    columnOptions={{
                                                        "title": {
                                                            text: "Title",
                                                            sort: false,
                                                        },
                                                        "count": {
                                                            sort: false,
                                                            text: "Issued",
                                                        }
                                                    }}></GenericTable>
                                                    
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>



                                    <Col xs={12} sm={12} md={12} lg={6}>
                                        <Card >
                                            <CardBody>
                                                <div className="text-center">
                                                    <h4>Top 5 Redeemed</h4>
                                                    <br/>

                                                    <GenericTable {...this.props}
                                                    tableData={redeemedCountTop5}
                                                    columnOptions={{
                                                        "title": {
                                                            text: "Title",
                                                            sort: false,
                                                        },
                                                        "redeemedCount": {
                                                            sort: false,
                                                            text: "Redeemed",
                                                        }
                                                    }}></GenericTable>
                                                    
                                                </div>
                                            </CardBody>
                                        </Card>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={6}>
                                        <Card >
                                            <CardBody>
                                                <div className="text-center">
                                                    <h4>Bottom 5 Redeemed</h4>
                                                    <br/>
                                                    <GenericTable {...this.props}
                                                    tableData={redeemedCountBottom5}
                                                    sort
                                                    columnOptions={{
                                                        "title": {
                                                            text: "Title",
                                                            sort: false,
                                                        },
                                                        "redeemedCount": {
                                                            sort: false,
                                                            text: "Redeemed",
                                                        }
                                                    }}></GenericTable>
                                                    
                                                </div>
                                            </CardBody>
                                        </Card>
                                        </Col>



                                        <Col xs={12} sm={12} md={12} lg={6}>
                                        <Card >
                                            <CardBody>
                                                <div className="text-center">
                                                    <h4>Top 5 Percent Redeemed</h4>
                                                    <br/>

                                                    <GenericTable {...this.props}
                                                    tableData={redeemPercentTop5}
                                                    columnOptions={{
                                                        "title": {
                                                            text: "Title",
                                                            sort: false,
                                                        },
                                                        "redeemPercent": {
                                                            sort: false,
                                                            text: "Percent Redeemed",formatter: (cell) => `${cell}%`,
                                                        }
                                                    }}></GenericTable>
                                                    
                                                </div>
                                            </CardBody>
                                        </Card>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={6}>
                                        <Card >
                                            <CardBody>
                                                <div className="text-center">
                                                    <h4>Bottom 5 Percent Redeemed</h4>
                                                    <br/>
                                                    <GenericTable {...this.props}
                                                    tableData={redeemPercentBottom5}
                                                    sort
                                                    columnOptions={{
                                                        "title": {
                                                            text: "Title",
                                                            sort: false,
                                                        },
                                                        "redeemPercent": {
                                                            sort: false,
                                                            text: "Percent Redeemed",formatter: (cell) => `${cell}%`,
                                                        }
                                                    }}></GenericTable>
                                                    
                                                </div>
                                            </CardBody>
                                        </Card>
                                        </Col>


                                        <Col xs={12} sm={12} md={12} lg={6}>
                                        <Card >
                                            <CardBody>
                                                <div className="text-center">
                                                    <h4>Top 5 Redeem Rate</h4>
                                                    <br/>

                                                    <GenericTable {...this.props}
                                                    tableData={redeemRateTop5}
                                                    columnOptions={{
                                                        "title": {
                                                            text: "Title",
                                                            sort: false,
                                                        },
                                                        "redeemRate": {
                                                            sort: false,
                                                            text: "Redeem Rate",formatter: (cell) => `${cell}%`,
                                                        }
                                                    }}></GenericTable>
                                                    
                                                </div>
                                            </CardBody>
                                        </Card>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={6}>
                                        <Card >
                                            <CardBody>
                                                <div className="text-center">
                                                    <h4>Bottom 5 Redeem Rate</h4>
                                                    <br/>
                                                    <GenericTable {...this.props}
                                                    tableData={redeemRateBottom5}
                                                    sort
                                                    columnOptions={{
                                                        "title": {
                                                            text: "Title",
                                                            sort: false,
                                                        },
                                                        "redeemRate": {
                                                            sort: false,
                                                            text: "Redeem Rate",formatter: (cell) => `${cell}%`,
                                                        }
                                                    }}></GenericTable>
                                                    
                                                </div>
                                            </CardBody>
                                        </Card>
                                        </Col>





                            </Row>
                            </FeatureCheck>
                        </>}
                    </>
                }
            </>);
    }
}

export default withRouter(props => <Voucher {...props} />);
export default {
    "999": "Unknown error",
    "2": "Delivery failed",
    "1": "Delivered",
    "0": "Sent",

    // "0": "Unknown error",
    // "1": "Temporary routing error",
    // "2": "Permanent routing error",
    // "3": "Maximum throttling exceeded",
    // "4": "Timeout",
    // "5": "Operator unknown error",
    // "6": "Operator error",
    // "104": "Configuration error",
    // "105": "Internal error",
    // "1000": "Sent",
    // "1001": "Delivered",
    // "1002": "Expired",
    // "1004": "Mobile full",
    // "1005": "Queued",
    // "1006": "Not delivered",
    // "1007": "Delivered, Billed delayed",
    // "1008": "Billed OK",
    // "1009": "Billed OK and NOT Delivered",
    // "1010": "Expired, absence of operator delivery report",
    // "1011": "Billed OK and sent",
    // "1012": "Delayed",
    // "1013": "Message sent to operator, Bill delayed",
    // "2016": "Number error",
    // "2103": "Service rejected by subscriber",
    // "2104": "Unknown subscriber",
    // "2105": "Destination blocked",
    // "2107": "Destination temporarily blocked",
    // "2200": "Charging error",
    // "2201": "Subscriber has low balance",
    // "2202": "Subscriber barred for overcharged messages",
    // "2203": "Subscriber too young",
    // "2204": "Prepaid subscriber not allowed",
    // "2205": "Service rejected by subscriber",
    // "2206": "Subscriber not registered in payment system",
    // "2207": "Subscriber has reached max balance",
    // "3000": "GSM encoding is not supported",
    // "3001": "UCS2 encoding is not supported",
    // "3002": "Binary encoding is not supported",
    // "4000": "Delivery report is not supported",
    // "4001": "Invalid message content",
    // "4002": "Invalid tariff",
    // "4003": "Invalid user data",
    // "4004": "Invalid user data header"
}
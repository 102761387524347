import React, { Component } from "react"
import Campaign from "../class/Campaign"
import Project from "../class/Project"
import Company from "../class/Company"
import { CardBody, CardImg, Card, Container, Row, Col, Badge, CardFooter, CardTitle, Progress, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import ImageFacade from "../facade/ImageFacade"
import { epochToLocaleString, thousandSeperator } from "../utils/Converter"
import ProjectCard from "../components/ProjectCard"
import { withRouter } from "react-router-dom"
import './CampaignCard.scss';

function IndicationCard({ iconName, indication, subject }) {
    return (
        <>
            <Col className="d-none d-sm-block" xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
                <div className="mb-3" style={{ padding: 20, paddingLeft: 5, paddingRight: 5 }}>
                    <i className={`text-secondary ${iconName}`} style={{ margin: "auto", fontSize: 25 }}></i>
                    <h4 style={{ color: "#333333" }}>{indication}</h4>
                    <a style={{ fontWeight: "lighter" }}>{subject}</a>
                </div>
            </Col>
            <Col className="d-block d-sm-none" sm={12} style={{ color: "#333333", textAlign: "center" }}>

                <a><i className={`text-secondary ${iconName}`} /> <b>{indication}</b> {subject}</a>
            </Col>

        </>


    )
}

class CampaignCard extends Component {
    constructor(props) {
        super(props)
        const { campaign } = this.props
        const campaignChosen = this.props.projectManager.campaign

        let collapsed = true
        // console.log(campaignChosen, campaign)

        // if (campaignChosen) {
        //     collapsed = campaignChosen.id !== campaign.id
        // }

        this.state = {
            collapsed
        }
    }

    componentDidMount = () => {
        const { history } = this.props
        const { project } = this.props.projectManager
        if (project) {
            try {
                document.getElementById(project.id).scrollIntoView()
                window.scrollBy(0, -100);
            } catch (e) {
                console.error('Could not scroll to view!')
            }

        }
    }

    render() {
        const { campaign } = this.props
        let { name, projects } = campaign
        projects = projects.sort((a,b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0));

        const campaignStartDate = epochToLocaleString(campaign.getStart())
        const campaignEndDate = epochToLocaleString(campaign.getEnd())

        const period = `${campaignStartDate} - ${campaignEndDate}`

        let action = () => {
            if (projects.length == 0) {
                return false;
            }
            if (projects.length == 1) {
                window.scrollTo(0,0)
                this.props.projectSelected(projects[0], this.props.campaign, this.props.brand)
                return false;
            }
            this.setState({ collapsed: !this.state.collapsed });
        };

        return (
            <Col xs={12} xl={6}>
                <Card id={campaign.id} className="campaign-card with-icon clickable p-2 bg-white rounded" >
                    <CardBody style={{ padding: 0, height: "100%" }} onClick={action}>
                        <div className="card-icon mb-3 bg-white rounded">
                            <img src={ImageFacade.campaignImage(campaign)} />
                        </div>
                        <Container fluid style={{ position: "relative", height: "100%" }}>
                            <Row style={{ marginTop: 0, marginBottom: 20 }}>

                                <Col>
                                    <div style={{ textAlign: "center" }}>
                                        <h2 >
                                            {name}
                                        </h2>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ maxWidth: 900, margin: "auto", width: "100%" }}>
                                <IndicationCard iconName="dripicons-web" indication={`${campaign.countProjectsLive()}/${projects.length}`} subject="Projects Live" />
                                {/* <IndicationCard iconName="dripicons-user-group" indication={thousandSeperator(campaign.countStat("users"))} subject="Users" />
                                <IndicationCard iconName="dripicons-ticket" indication={thousandSeperator(campaign.countStat("vouchers"))} subject="Vouchers" />
                                <IndicationCard iconName="dripicons-checkmark" indication={thousandSeperator(campaign.countStat("redeemed"))} subject="Redeemed" /> */}
                            </Row>
                            <div className="card-id d-none d-sm-block">
                                <Row style={{ width: "100%", marginTop: 20 }}>
                                    <Col xs={12} sm={6}>
                                        <a style={{ color: "#cbcbcb" }}>
                                            {period}
                                        </a>
                                    </Col>

                                    <Col xs={12} sm={6}>
                                        <a style={{ left: 0, color: "#cbcbcb", float: "right" }}>ID: {campaign.id}</a>
                                    </Col>
                                </Row>
                            </div>
                            <div className="card-id d-block d-sm-none">
                                <br />
                                <a style={{ color: "#cbcbcb" }}>{period}</a>
                                <br />
                                <a style={{ left: 0, color: "#cbcbcb" }}>ID: {campaign.id}</a>
                            </div>
                            {/* <Row>
                            <Col style={{ textAlign: "center" }}>
                                <i className={`dripicons-chevron-${this.state.collapsed ? "down" : "up"}`} style={{ fontSize: 20, color: "#cbcbcb", textAlign: "center" }}></i>
                            </Col>
                        </Row> */}


                        </Container>


                    </CardBody>

                    <Modal isOpen={!this.state.collapsed} centered="true" size="lg" onClick={() => this.setState({ collapsed: !this.state.collapsed })} toggle={() => this.setState({ collapsed: !this.state.collapsed })}>
                        <ModalHeader >Projects of {name}</ModalHeader>
                        <ModalBody className="p-3 pt-4">
                            <Row>
                                {
                                    projects.map((project) => <ProjectCard
                                        project={project}
                                        {...this.props}

                                    />)
                                }
                            </Row>
                        </ModalBody>
                    </Modal>


                    {/* {
                    !this.state.collapsed && (
                        <>
                            <br />
                            <CardFooter>
                                <br />
                                <h3 style={{ textAlign: "center" }}>Projects of {name}</h3>
                                <br /><br />
                                <Row>

                                    {
                                        projects.map((project) => <ProjectCard
                                            project={project}
                                            {...this.props}

                                        />)
                                    }
                                </Row>
                            </CardFooter>
                        </>
                    )
                } */}
                </Card>
            </Col>
        )
    }
}

export default withRouter(props => <CampaignCard {...props} />)
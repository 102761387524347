

import React, { Component } from 'react';
import UserFacade from '../facade/project/UserFacade';
import GenericTable from '../components/GenericTable';
import { CardBody, Card } from 'reactstrap';
import { Redirect, withRouter, Route, Switch } from 'react-router-dom';

import { makeTo } from '../utils/LinkUtils';
import { tzTableDateFormatter } from '../utils/Converter';
import checkFeatures from '../utils/checkFeatures';

export default withRouter(props => <UserList {...props} />)

class UserList extends Component {

    state = {
        redirect: null,
    }

    handleClick = async (e, column, columnIndex, row, rowIndex) => {
        //making sure to unmount table so the lifecycle method in it gets fired
        // console.log('location', this.props.location)
        this.setState({ redirect: makeTo(`/users/${row["user_id"]}`, this.props.location) })
    }
    handleButton = async(row)=>{

        this.setState({ redirect: makeTo(`/users/${row["user_id"]}`, this.props.location) })

    }

    render() {
        const timezone = this.props.data.projectManager.project.timezone
        const tableDateFormatter = tzTableDateFormatter(timezone)
        if (this.state.redirect) {
            return <Redirect push to={{
                pathname: this.state.redirect,
                state: { from: this.props.location }
            }} />
        }

        let tableAction = {
            "Details": this.handleButton
        }


        return (
            <Card>
                <CardBody>

                    {/* <Route /> */}
                    <GenericTable
                        {...this.props}
                        hover
                        dataCb={UserFacade.getUsers}
                        countCb={UserFacade.getUserCount}
                        rowEvents={{
                            onClick: this.handleClick
                        }}
                        columnOptions={{
                            id: {
                                hide: true
                            },
                            created: {
                                formatter: tableDateFormatter,
                            },
                            "user_id": {
                                text: "ID",
                                sort: true,
                            },
                            "mobile":{
                                hide: !checkFeatures(this.props, ["show_login_info_user"])
                            },
                            "email":{
                                hide: !checkFeatures(this.props, ["show_login_info_user"])
                            }
                            
                        }}
                        actions={tableAction}
                        actionsStyle="button"
                        searchType = "string"

                        defaultSorted={[{
                            dataField: 'created',
                            order: 'desc'
                        }]}
                    />
                    

                </CardBody>
            </Card>
        );
    }
}
import FacadeConnection from "../FacadeConnection";
import _ from "lodash";

class issuePrize extends FacadeConnection {

    constructor() {
        super()
    }
    issue = async (dataManager,  prize, message, numbers) => {
        const { authManager, projectManager,start, end } = dataManager
        const projectID = projectManager.project.id
        const { sessionID } = authManager
        const params = {
            prize, message, numbers,start,end
        }

        return await (await this._send("/project/issuePrize/issue", "GET", params, sessionID, projectID)).json()
    }

}

export default new issuePrize()
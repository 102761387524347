import React, { Component } from "react";
import { Card, CardHeader, CardBody, Container, Row, Col, Dropdown, DropdownMenu, DropdownToggle, Collapse } from "reactstrap"
import { Link, Route, Router, Switch, Redirect, withRouter } from 'react-router-dom';

import logoSm from '../assets/images/logo_sm.png';
import logo from '../assets/images/logo.png';
import ProfileDropDown from './ProfileDropDown'
import ImageFacade from "../facade/ImageFacade";
import { makeTo } from "../utils/LinkUtils";

export default withRouter(props => <Bar {...props} />)

class Bar extends Component {

    render() {
        const profilePic = ImageFacade.userImage(this.props.auth.user.email)
        return (
            <Col style={{ padding: 0 }}>
                <div className="topnav-logo navbar-custom topnav-navbar topnav-navbar-dark">
                    <Container fluid>
                        <Link to={makeTo("/stats",this.props.location)} className="topnav-logo" onClick={() => this.props.toggleMenu(false)}>
                            <span className="topnav-logo-lg">
                                <img src={logo} alt="logo" height="50" />
                            </span>
                            <span className="topnav-logo-sm">
                                <img src={logoSm} alt="logo" height="40" />
                            </span>
                        </Link>

                        <ul className="list-unstyled topbar-right-menu float-right mb-0">

                            <li className="notification-list">
                                <ProfileDropDown
                                    profilePic={profilePic}
                                    {...this.props}
                                />
                            </li>

                        </ul>
                        <button
                            className="button-menu-mobile open-left float-left disable-btn"
                            onClick={() => this.props.toggleMenu()}>
                            <i className="mdi mdi-menu" style={{ color: "white" }}></i>
                        </button>
                    </Container>

                </div>
            </Col>
        )
    }
}
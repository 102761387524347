import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';
import BarChartBrite from '../components/BarChartBrite';
import Loading from '../components/Loading';
import ProjectDataPage from './ProjectDataPage';
import _ from "lodash"
import ChannelTable from './ChannelTable';
import FeatureCheck from '../components/FeatureCheck';
import IssuePrizeResolver from '../resolvers/IssuePrizeResolver';
import { tableThousandSeperator, tzTableDateFormatter } from '../utils/Converter';
import IssuePrizeForm from './IssuePrizeForm';

class IssuePrize extends ProjectDataPage {
    constructor(props) {
        super("Issue prize", [
            IssuePrizeResolver,
        ])

    }
    render() {
        const supported = this.props.states.supported
        return (
            <>
                {this.props.loading || this.state.loading ? <Loading /> :
                    <>
                        <FeatureCheck features={["user", "issueprize"]} {...this.props} >
                        <IssuePrizeForm supported={supported} {...this.props}/>
                        </FeatureCheck>
                        <br />


                    </>
                }
            </>);
    }
}

export default withRouter(props => <IssuePrize {...props} />);
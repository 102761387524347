
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import ParamState from '../utils/ParamState';

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
class Tab extends Component {

    constructor(props) {
        super(props)

        this.ps = new ParamState(() => this.props, {
            view: null
        })
    }

    componentDidMount = async () => {
        let view = this.ps.getView()
        if (!view) {
            view = this.props.tabs[0].title
        } else {
            this.setView(view)
        }
    }

    setView = async (view) => {
        await this.ps.setView(view)
        await this.props.setView(view)
    }

    render() {

        const view = this.ps.getView()

        return (
            <div>
                <ButtonGroup size="lg" style={{ maxWidth: 120, width: "100%" }}>
                    {this.props.tabs.map((tab, i) => {
                        let color = "light"

                        if (view !== null) {
                            if (view === tab.title) {
                                color = "primary"
                            }
                        } else if (i === 0) {
                            color = "primary"
                        }

                        return <Button
                            onClick={() => this.setView(tab.title)}
                            color={color}
                        >
                            <i className={tab.icon} style={{ fontSize: 18 }}></i>
                            {/* <a> {tab.title}</a> */}
                        </Button>
                    }
                    )}
                </ButtonGroup>
            </div>
        );
    }
}

export default withRouter(props => <Tab {...props} />);
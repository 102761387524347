import moment from "moment-timezone"

export default function (data, tz) {
    let timezone = moment.tz.guess();
    try {
        let dataTimezone = tz
        if (dataTimezone == null) {
            dataTimezone = data.projectManager.project.timezone
        }
        if (dataTimezone) {
            timezone = dataTimezone
        }
    } catch {}

    return moment.tz.setDefault(timezone)
}
import React, { Component } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import ParamState from '../utils/ParamState';

class Sorter extends Component {

    setSorter = async (name, type) => {
        await this.ps.setSorter(`${name}-${type}`)
        await this.props.setSorter({ name, type })
    }

    getSorter = () => {
        const sorterString = this.ps.getSorter()
        if (!sorterString) return null

        const sorterSplit = sorterString.split("-")
        return {
            name: sorterSplit[0],
            type: sorterSplit[1]
        }
    }

    componentDidMount = async () => {
        this.ps = new ParamState(() => this.props, {
            sorter: null,
        })
        const sorter = this.getSorter()
        await this.props.setSorter(sorter)
    }

    clearSorter = async () => {
        await this.ps.setSorter(null)
        await this.props.setSorter(null)
    }

    render() {
        const sortings = this.props.sortings
        if (this.ps) {
            var sorter = this.getSorter()
        }

        return (
            <UncontrolledButtonDropdown className="mb-3" style={{ float: "right" }} size="lg">
                <DropdownToggle caret color={sorter ? "info text-white" : "light"} style={{ fontSize: 18 }}>
                    <i className="dripicons-list" style={{ fontSize: 18 }} />
                </DropdownToggle>
                <DropdownMenu right>
                    {Object.keys(sortings).map(sortingKey => {
                        const sorting = sortings[sortingKey]
                        return <div>
                            <DropdownItem header>{sortingKey}</DropdownItem>
                            {sorting.hasOwnProperty("asc") && <DropdownItem
                                className={sorter && sorter.name === sortingKey && sorter.type === "asc" ? "text-info" : null}
                                onClick={() => this.setSorter(sortingKey, "asc")}
                            >
                                {sortingKey}
                                <i className="dripicons-chevron-up" style={{ fontSize: 18 }} />
                            </DropdownItem>
                            }
                            {sorting.hasOwnProperty("desc") && <DropdownItem
                                className={sorter && sorter.name === sortingKey && sorter.type === "desc" ? "text-info" : null}
                                onClick={() => this.setSorter(sortingKey, "desc")}
                            >
                                {sortingKey}
                                <i className="dripicons-chevron-down" style={{ fontSize: 18 }} />
                            </DropdownItem>
                            }
                        </div>

                    })}
                    <DropdownItem divider />

                    <DropdownItem
                        onClick={() => this.clearSorter()}
                    >
                        Clear Sorting
                    </DropdownItem>

                </DropdownMenu>
            </UncontrolledButtonDropdown>
        );
    }
}

export default Sorter;
import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Row, Col, Container, CardFooter } from "reactstrap"
import classnames from "classnames"
import { thousandSeperator, epochToTimezoneString } from "../utils/Converter"

class StatisticCard extends Component {
    state = {
        stat: NaN,
        toLastWeekStat: null,
    }

    render() {
        const epochToString = epochToTimezoneString(this.props.projectManager.project.timezone)

        const textClass = this.props.textClass || 'text-muted';
        let { showTrend, data } = this.props

        if (showTrend) {
            var increase = parseFloat(((this.props.stat / this.props.trendStat) - 1) * 100).toFixed(2)
            if (increase === "Infinity" || increase === "NaN") {
                showTrend = false
            } else {
                var arrow = increase < 0 ? "down" : "up"
                var trendTextClass = increase < 0 ? 'text-danger' : 'text-success'

                var icon = `mdi mdi-arrow-${arrow}-bold`

                var trendValue = `${increase}%`
                var trendText = 'Last 7 days'
            }
        }

        if (data.periodChanged()) {
            const dateString = epochToString(data.end.getTime() - 1)
            var todayText = `(${dateString})`
            var todayTextClass = "text-warning"
        }

        const {stat, trendStat, todayStat, statDescription, stat2, statDescription2} = this.props


        return (
            <div>
                <Card className={classnames('widget-flat', this.props.bgclassName)} style={{height: 205}}>
                    <CardBody>
                        {this.props.icon && (
                            <div className="float-right">
                                <i className={classnames(this.props.icon, `bg-${this.props.color || "primary"}-lighten`, 'widget-icon')}></i>
                            </div>
                        )}
                        <h5 className={classnames('font-weight-normal text-truncate', 'mt-0', textClass)} title={this.props.description}>
                            {this.props.title}
                        </h5>
                        <h3 className={classnames('mt-3', 'mb-0', textClass)}>{typeof stat === "number" ?thousandSeperator(stat): stat}</h3>
                        {statDescription && <p className={'mb-0 mt-1'}>{statDescription}</p>}
                        {trendStat !== undefined && <>{
                            showTrend ? (
                                <p className={classnames('mb-0', 'mt-3', textClass)
                                } >
                                    <span className={classnames(trendTextClass, 'mr-2')}>
                                        <i className={classnames(icon)}></i> {trendValue}
                                    </span>
                                    <span className="text-nowrap text-truncate">{trendText}</span>
                                </p>
                            ) : <p className={'mb-0 mt-3'}>
                                No trend data
                            </p>}
                        </>}

                        <h3 className={classnames('mt-3', 'mb-0', textClass)}>{typeof stat2 === "number" ?thousandSeperator(stat2): stat2}</h3>
                        {statDescription2 !== undefined && <p className={'mb-0 mt-1'}>{statDescription2}</p>}


                    </CardBody>
                    {todayStat !== undefined && <CardFooter>
                        <p className={classnames('mb-0', textClass)}>
                            {thousandSeperator(todayStat)} {this.props.todayString ? this.props.todayString : 'Today'}
                            <span className={classnames('mr-2', "ml-1", todayTextClass)}>
                                {todayText}
                            </span>
                        </p>
                    </CardFooter>}
                </Card>
            </div >
        );
    }
}

export default StatisticCard;
import React, { Component } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import classnames from "classnames"
import Chart from 'react-apexcharts';
import { thousandSeperator } from '../utils/Converter';
import checkFeatures from '../utils/checkFeatures';
import _ from "lodash"

const COLOR_WHEEL = {
    "primary": "#38469b",
    "secondary": "#6c757d",
    "info": "#8492fa",
    "success": "#0acf97",
    "warning": "#ffbc00",
    "danger": "#fa5c7c",
    "light": "#e3eaef",
    "dark": "#19191b"
}


class RadialCard extends Component {
    render() {
        let data = []
        let labels = []
        let colors = []

        let total = this.props.total.stat

        const filteredStats = this.props.stats.filter(({ checks }) => checkFeatures(this.props, checks))

        const allStats = [this.props.total, ...filteredStats]

        for (const { name, stat, color } of filteredStats) {
            data.push(_.round(stat / total * 100, 1))
            labels.push(name)
            let colorUsed = COLOR_WHEEL[color]
            colors.push(colorUsed)
        }

        const apexBarChartOpts = {
            labels,
            grid: {
                padding: {
                    left: 0,
                    right: 0,
                },
            },
            chart: {
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 1500,
                },
                height: 320,
                type: 'radialBar',
            },
            colors,
            plotOptions: {
                radialBar: {
                    track: {
                        margin: 8,
                    },
                },
            },
            tooltip: {
                enabled: true,
                shared: true,
                custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                    return `<div><span>${labels[seriesIndex]}: ${series[seriesIndex]}%</span></div>`
                }
            },


        };

        return (
            <>
                {/* {Object.keys(COLOR_WHEEL).map(color => <i className={`dripicons-swap widget-icon bg-${color}-lighten text-${color}`}></i>)} */}
                <Card>
                    <CardBody>
                        <h5 className={classnames('font-weight-normal text-truncate', 'mt-0')} title={this.props.description}>
                            {this.props.title}
                        </h5>

                        <div>
                            <Chart
                                // style={{ color: "white" }}
                                options={apexBarChartOpts}
                                series={data}
                                type="radialBar"
                                className="apex-charts"
                                height={340}
                            />
                        </div>
                        <Row className="text-center">
                            {allStats.map(({ stat, color, name, icon }, i) => {
                                return (
                                    <Col xs={12} md={(i === allStats.length - 1) && (i % 2 === 0) ? 12 : 6} >
                                        <i className={`${icon} widget-icon bg-${color}-lighten text-${color}`}></i>
                                        <h3 className="font-weight-normal mt-3">
                                            <span>{thousandSeperator(stat)}</span>
                                        </h3>
                                        <p className="text-muted mb-0 mb-2">
                                            <i className={`mdi mdi-checkbox-blank-circle text-${color}`}></i> {name}
                                        </p>
                                    </Col>
                                )
                            })}
                        </Row>

                    </CardBody>
                </Card>
            </>
        );
    }
}

export default RadialCard;
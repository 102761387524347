import FacadeConnection from "../FacadeConnection";
import _ from "lodash";

class UserFacade extends FacadeConnection {

    constructor() {
        super()
    }

    getUser = async (dataManager, userID) => {
        return this.get(`/project/user/${userID}`, dataManager)
    }

    getUsers = async (dataManager, from, to, orderBy = null, order = null, search = null) => {
        const { authManager, projectManager } = dataManager
        const projectID = projectManager.project.id
        const { sessionID } = authManager

        const params = {
            from,
            to
        }

        if (orderBy !== null) params.orderBy = orderBy
        if (order !== null) params.order = _.capitalize(order)
        if (search !== null) params.search = search


        return await (await this._send("/project/user", "GET", params, sessionID, projectID)).json()
    }

    getUserCount = async (dataManager, search = null) => {
        const { authManager, projectManager } = dataManager
        const projectID = projectManager.project.id
        const { sessionID } = authManager

        const params = {}
        if (search !== null) params.search = search

        return await (await this._send("/project/user/count", "GET", params, sessionID, projectID)).json()

    }

    // changeUserBanState = async (dataManager, userID, banState) => {
    //     const { authManager, projectManager } = dataManager
    //     const projectID = projectManager.project.id
    //     const { sessionID } = authManager

    //     return await this.put(`/project/user/${userID}/ban/${banState}`, null, sessionID, projectID)

    // }

    changeExtraStatusOnUserID = async (dataManager, userID, banState) => {
        const { authManager, projectManager } = dataManager
        const projectID = projectManager.project.id
        const { sessionID } = authManager
        return await this.put(`/project/user/${userID}/extra/${banState}`, null, sessionID, projectID)
    }
    resetUsername = async (dataManager, userID) => {
        const { authManager, projectManager } = dataManager
        const projectID = projectManager.project.id
        const { sessionID } = authManager
        return await this.put(`/project/user/${userID}/username/reset`, null, sessionID, projectID)
    }

    getGameSession(dataManager, userID){
        return this.get(`/project/user/${userID}/gamesession`, dataManager)
    }

    anonymiseUser = async (dataManager, userID) => {

        return await this.delete(`/project/user/${userID}`,dataManager)

    }
}

export default new UserFacade()